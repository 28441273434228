import React from "react";
import { MdClose } from "react-icons/md";
import style from "./css/style.module.css";

const Modal = ({ open, setOpen, onClose, children }) => {
	window.scroll({
		top: 0,
		left: 0,
		behavior: "smooth",
	});

	return (
		<div style={{ display: open ? "none" : "" }} className={style.container}>
			<div className={style.form}>
				<MdClose
					fontSize={"2em"}
					onClick={() => {
						if (typeof onClose === "function") onClose();
						setOpen(!open);
					}}
					style={{
						cursor: "pointer",
						backgroundColor: "red",
						color: "white",
						borderRadius: "50%",
						transform: "translateY(-5px)",
					}}
					className={style.closeButton}
				/>
				{children}
			</div>
		</div>
	);
};

Modal.defaultProps = {
	className: "",
};
export default Modal;
