import styled from "styled-components";

export const PrimaryButton = styled.button`
  background-color: #000;
  padding: 1em 1em;
  border-radius: 5px;
  width: ${(props) => props.width || "100%"};
  color: #ffff;
  border: none;
  transition: all 0.3s;
  cursor: pointer;
  margin-top:10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    transform: translateY(-5px);
  }
  &:active {
    transform: translateY(5px);
  }
`;
