const types = {
	GET_COMMENT_START: "GET_COMMENT_START",
	GET_COMMENT_SUCCESS: "GET_COMMENT_SUCCESS",
	GET_COMMENT_ERROR: "GET_COMMENT_ERROR",

	//create comment

	CREATE_COMMENT_START: "CREATE_COMMENT_START",
	CREATE_COMMENT_SUCCESS: "CREATE_COMMENT_SUCCESS",
	CREATE_COMMENT_ERROR: "CREATE_COMMENT_ERROR",
};

export default types;
