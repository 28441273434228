import types from "./types";

export const getApartmentListStart = (payload) => ({
  type: types.GET_APARTMENT_LIST_START,
  payload,
});
export const setApartmentUnderEdit = (payload) => ({
  type: types.SET_APARTMENT_FOR_EDIT,
  payload,
});

export const setApartmentForDelete = (payload) => ({
  type: types.SET_APARTMENT_FOR_DELETE,
  payload,
});
export const resetDeleteApartmentState = () => ({
  type: types.RESET_APARTMENT_DELETE_STATE,
});
export const createApartmentStart = (payload) => ({
  type: types.CREATE_APARTMENT_START,
  payload,
});
export const resetCreateApartmentState = () => ({
  type: types.RESET_CREATE_APARTMENT_STATE,
});
export const deleteApartmentStart = (id) => ({
  type: types.DELETE_APARTMENT_START,
  payload: id,
});
//bulk
export const setApartmentForBulkDelete = (payload) => ({
  type: types.SET_APARTMENT_FOR_BULK_DELETE,
  payload,
});
export const bulkDeleteApartmentStart = (id) => ({
  type: types.BULK_DELETE_APARTMENT_START,
  payload:id,
});
export const resetBulkDeleteApartmentState = () => ({
  type: types.RESET_APARTMENT_BULK_DELETE_STATE,
});
export const updateApartmentStart = (payload) => ({
  type: types.UPDATE_APARTMENT_START,
  payload,
});
export const updateApartmentReset = () => ({
  type: types.RESET_UPDATE_APARTMENT_STATE,
});
export const apartmentAssignStart = (payload) => ({
  type: types.APARTMENT_ASSIGN_START,
  payload,
});
export const apartmentAssignReset = () => ({
  type: types.APARTMENT_ASSIGN_RESET,
});
export const apartmentUnAssignStart = (payload) => ({
  type: types.APARTMENT_UN_ASSIGN_START,
  payload,
});
export const apartmentUnAssignReset = () => ({
  type: types.APARTMENT_UN_ASSIGN_RESET,
});
