import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";

function* generateGuestJWTHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(apiClient.post, endpoints.generateJWT);
    yield put({
      type: types.GENERATE_GUEST_JWT_SUCCESS,
      payload: data.response,
    });
  } catch (e) {
    yield put({
      type: types.GENERATE_GUEST_JWT_ERROR,
      payload: e,
    });
  }
}

export function* generateGuestJWTWatcherSaga() {
  yield takeEvery(types.GENERATE_GUEST_JWT_START, generateGuestJWTHandlerSaga);
}

function* staffLoginStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const {
      data: { response },
    } = yield call(apiClient.post, endpoints.staffLogin, action.payload);
    yield put({
      type: types.STAFF_LOGIN_SUCCESS,
      payload: response,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.STAFF_LOGIN_ERROR,
      payload,
    });
  }
}

export function* staffLoginStartWatcherSaga() {
  yield takeEvery(types.STAFF_LOGIN_START, staffLoginStartHandlerSaga);
}
