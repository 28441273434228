import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import Sagas from "./Sagas";

import rootReducer from "./root_reducer";

const persistConfig = {
  key: "baitti-admin",
  storage,
  whitelist: ["Auth","project"],
};

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware, logger],
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleware.run(Sagas);

export default store;
