import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-height: 70vh;
  overflow: auto;
`;
export const Heading = styled.h3`
  text-align: center;
`;
export const InvalidMode = styled.h4`
  font-color: red;
  text-align: center;
`;
export const FormBody = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;
