import { combineReducers } from "redux";
import UserReducer from "./User/reducer";
import AuthReducer from "./Auth/reducers";
import housingComplexReducer from "./Dashboard/reducer";
import buildingReducer from "./Building/reducers";
import villaReducer from "./Villa/reducer";
import offerReducer from "./Offer/reducer";
import visitorPermitReducer from "./VisitorPermit/reducer";
import maintenanceRequestReducer from "./MaintenanceRequest/reducer";
import buildingsDetailReducer from "./BuildingDetail/reducers";
import apartmentReducer from "./Apartment/reducer";
import FamilyMemberReducer from "./FamilyMember/reducer";
import commentReducer from "./Comments/reducers";
import couponReducer from "./coupon/reducer";
import adminprofileReducer from "./AdminProfile/reducer";
import SearchReducer from "./SearchText/reducer";
import imageUploadReducer from "./ImageUpload/reducer";
import projectReducer from "./Project/reducer";
import notificationReducer from "./Notification/reducer";
import categoryReducer from "./Category/reducer"; // Import the new reducer

export default combineReducers({
  project: projectReducer,
  user: UserReducer,
  Auth: AuthReducer,
  housingComplex: housingComplexReducer,
  buildings: buildingReducer,
  villa: villaReducer,
  offer: offerReducer,
  visitorPermit: visitorPermitReducer,
  maintenanceRequest: maintenanceRequestReducer,
  buildingDetail: buildingsDetailReducer,
  apartment: apartmentReducer,
  familyMember: FamilyMemberReducer,
  comment: commentReducer,
  coupon: couponReducer,
  AdminProfile: adminprofileReducer,
  searchText: SearchReducer,
  imageUpload: imageUploadReducer,
  notification: notificationReducer,
  category: categoryReducer // Add the new reducer here
});
