import types from './types';

export const getProjectListStart = payload => ({
    type: types.GET_PROJECT_LIST_START,
    payload,
});
export const createNewProjectStart = payload => ({
    type: types.CREATE_PROJECT_START,
    payload,
});
export const selectProject = payload => ({
    type: types.SELECT_PROJECT,
    payload,
});
export const selectProjectUnderEdit = payload =>({
    type: types.SELECT_PROJECT_UNDER_EDIT,
    payload,
});
export const editProjectStart = payload => ({
    type: types.EDIT_PROJECT_START,
    payload,
});
export const createProjectStateReset = () => ({
    type: types.CREATE_PROJECT_STATE_RESET,
});
export const editProjectStateReset = () => ({
    type: types.EDIT_PROJECT_STATE_RESET,
})

export const deleteProjectStart = payload => ({
    type: types.DELETE_PROJECT_START,
    payload,
});
export const deleteProjectReset = () => ({
    type: types.DELETE_PROJECT_RESET,
})