import types from "./types";

const INITIAL_STATE = {
  buildings: null,
  buildingsError: null,
  loadingBuildings: false,

  newBuilding: null,
  creatingNewBuilding: false,
  buildingCreationError: null,

  buildingDetails: null,
  gettingBuildingDetails: false,
  buildingDetailsError: null,

  buildingForUpdate: null,

  updatedBuilding: null,
  updatingBuilding: false,
  buildingUpdateError: null,

  buildingToDelete: null,
  buildingDeleted: null,
  deletingBuilding: false,
  deleteBuildingError: null,

  BulklBuildingToDelete: null,
  BulklBuildingDeleted: null,
  BulklDeletingBuilding: false,
  BulkDeleteBuildingError: null,

  search: null,
  searchError: null,
  searchLoading: false,
};

const buildingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // GET BUILDING DETAILS
    case types.GET_BUILDING_DETAILS_START: {
      return {
        ...state,
        buildingDetails: null,
        gettingBuildingDetails: true,
        buildingDetailsError: null,
      };
    }
    case types.GET_BUILDING_DETAILS_SUCCESS: {
      return {
        ...state,
        buildingDetails: action.payload,
        gettingBuildingDetails: false,
      };
    }
    case types.GET_BUILDING_DETAILS_ERROR: {
      return {
        ...state,
        gettingBuildingDetails: false,
        buildingDetailsError: action.payload,
      };
    }

    case types.SET_BUILDING_FOR_UPDATE: {
      return {
        ...state,
        buildingForUpdate: action.payload,
      };
    }

    // UPDATE BUILDING
    case types.UPDATE_BUILDING_START: {
      return {
        ...state,
        updatedBuilding: null,
        updatingBuilding: true,
        buildingUpdateError: null,
      };
    }
    case types.UPDATE_BUILDING_SUCCESS: {
      return {
        ...state,
        updatedBuilding: action.payload,
        updatingBuilding: false,
      };
    }
    case types.UPDATE_BUILDING_ERROR: {
      return {
        ...state,
        updatingBuilding: false,
        buildingUpdateError: action.payload,
      };
    }
    case types.BUILDING_UPDATE_RESET: {
      return {
        ...state,
        buildingForUpdate: null,
        updatedBuilding: null,
        updatingBuilding: false,
        buildingUpdateError: null,
      };
    }

    // DELETE BUILDING
    case types.SET_BUILDING_FOR_DELETE: {
      return {
        ...state,
        buildingToDelete: action.payload,
      };
    }
    case types.DELETE_BUILDING_START: {
      return {
        ...state,
        buildingDeleted: null,
        deletingBuilding: true,
        deleteBuildingError: null,
      };
    }
    case types.DELETE_BUILDING_SUCCESS: {
      return {
        ...state,
        buildingDeleted: action.payload,
        deletingBuilding: false,
      };
    }
    case types.DELETE_BUILDING_ERROR: {
      return {
        ...state,
        deletingBuilding: false,
        deleteBuildingError: action.payload,
      };
    }
    case types.BUILDING_DELETE_STATE_RESET: {
      return {
        ...state,
        buildingDeleted: null,
        deletingBuilding: false,
        deleteBuildingError: null,
      };
    }

    //Delete multiple building
    case types.SET_BUILDING_FOR_BULK_DELETE: {
      return {
        ...state,
        BulklBuildingToDelete: action.payload
      };
    }
    case types.BULK_DELETE_BUILDING_START: {
      return {
        ...state,
        BulklBuildingDeleted: null,
        BulklDeletingBuilding: true,
        BulkDeleteBuildingError: null,
      };
    }
    case types.BULK_DELETE_BUILDING_SUCCESS: {
      return {
        ...state,
        BulklBuildingDeleted:action.payload,
        BulkDeleteBuildingError:false
      };
    }
    case types.BULK_DELETE_BUILDING_ERROR: {
      return {
        ...state,
        BulklDeletingBuilding:false,
        BulkDeleteBuildingError:action.payload
      };
    }
    case types.BUILDING_BULK_DELETE_STATE_RESET: {
      return {
        ...state,
        BulklBuildingToDelete: null,
        BulklBuildingDeleted: null,
        BulklDeletingBuilding: false,
        BulkDeleteBuildingError: null,
      }
    }
    // CREATE NEW BUILDING
    case types.CREATE_BUILDING_START: {
      return {
        ...state,
        newBuilding: null,
        creatingNewBuilding: true,
        buildingCreationError: null,
      };
    }
    case types.CREATE_BUILDING_SUCCESS: {
      return {
        ...state,
        newBuilding: action.payload,
        creatingNewBuilding: false,
        buildingCreationError: null,
      };
    }
    case types.CREATE_BUILDING_ERROR: {
      return {
        ...state,
        creatingNewBuilding: false,
        buildingCreationError: action.payload,
      };
    }
    case types.CREATE_BUILDING_STATE_RESET: {
      return {
        ...state,
        newBuilding: null,
        creatingNewBuilding: false,
        buildingCreationError: null,
      };
    }

    // GET BUILDING LIST
    case types.GET_BUILDING_LIST_START: {
      return {
        ...state,
        buildings: null,
        buildingsError: null,
        loadingBuildings: true,
      };
    }
    case types.GET_BUILDING_LIST_SUCCESS: {
      return {
        ...state,
        buildings: action.payload,
        loadingBuildings: false,
      };
    }
    case types.GET_BUILDING_LIST_ERROR: {
      return {
        ...state,
        buildingsError: action.payload,
        loadingBuildings: false,
      };
    }

    //search

    case types.SEARCH_BUILDING_START: {
      return {
        ...state,
        search: null,
        searchError: null,
        searchLoading: true,
      };
    }
    case types.SEARCH_BUILDING_ERROR: {
      return {
        ...state,
        searchError: action.payload,
        searchLoading: false,
      };
    }
    case types.SEARCH_BUILDING_SUCCESS: {
      return {
        ...state,
        search: action.payload,
        searchLoading: false,
      };
    }
    case types.BUILDING_SEARCH_RESET: {
      return {
        ...state,
        search: null,
        searchError: null,
        searchLoading: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default buildingsReducer;
