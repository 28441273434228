import { LineWave } from "react-loader-spinner";
import React from "react";

const Loading = () => {
  return (
    <div
      style={{
        width: "100%",
        height: window.innerHeight + "px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        zIndex: 10,
      }}
    >
      <LineWave
        color="blue"
        ariaLabel="Welcome to ADAR Admin"
        height={200}
        width={200}
        style={{}}
      />
    </div>
  );
};

export default Loading;
