import { Circles } from "react-loader-spinner";
import React from "react";
import { Button } from "../";

const LoadingButton = ({ className, style }) => {
	return (
		<Button disable={true} className={className} style={style}>
			<Circles width={30} height={30} color={"white"} />
		</Button>
	);
};

export default LoadingButton;
