const types = {
    GET_PROJECT_LIST_START: "GET_PROJECT_LIST_START",
    GET_PROJECT_LIST_SUCCESS: "GET_PROJECT_LIST_SUCCESS",
    GET_PROJECT_LIST_ERROR: "GET_PROJECT_LIST_ERROR",

    CREATE_PROJECT_START: "CREATE_PROJECT_START",
    CREATE_PROJECT_SUCCESS: "CREATE_PROJECT_SUCCESS",
    CREATE_PROJECT_ERROR: "CREATE_PROJECT_ERROR",
    CREATE_PROJECT_STATE_RESET : "CREATE_PROJECT_STATE_RESET",

    GET_PROJECT_DETAILS_START: "GET_PROJECT_DETAILS_START",
    GET_PROJECT_DETAILS_SUCCESS: "GET_PROJECT_DETAILS_SUCCESS",
    GET_PROJECT_DETAILS_ERROR: "GET_PROJECT_DETAILS_ERROR",

    SELECT_PROJECT: "SELECT_PROJECT",

    SELECT_PROJECT_UNDER_EDIT: "SELECT_PROJECT_UNDER_EDIT",
    EDIT_PROJECT_START:"EDIT_PROJECT_START",
    EDIT_PROJECT_SUCCESS:"EDIT_PROJECT_SUCCESS",
    EDIT_PROJECT_ERROR: "EDIT_PROJECT_ERROR",
    EDIT_PROJECT_STATE_RESET : "EDIT_PROJECT_STATE_RESET",

    DELETE_PROJECT_START: "DELETE_PROJECT_START",
    DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
    DELETE_PROJECT_ERROR: "DELETE_PROJECT_ERROR",
    DELETE_PROJECT_RESET: "DELETE_PROJECT_RESET",
}

export default types;