// sagas.js

import { call, put, takeLatest } from 'redux-saga/effects';
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";
import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE
} from './types';

import { fetchCategoriesSuccess, fetchCategoriesFailure } from './actions';
function* fetchCategoriesSaga() {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
			apiClient.get,
			endpoints.categoryList,
		);
    //console.log(data,`<<<<<<< line 20`)
    yield put({ type: FETCH_CATEGORIES_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: FETCH_CATEGORIES_FAILURE, payload: error });
  }
}

export function* categorySaga() {
  yield takeLatest(FETCH_CATEGORIES_REQUEST, fetchCategoriesSaga);
}
