import types from "./types";

const INITIAL_STATE = {
  offers: null,
  offersError: null,
  loadingOffers: false,

  //create offer

  createOffers: null,
  createOffersError: null,
  createOffersloading: false,

  //update offer
  updateOffer: false,
  updateOfferError: null,
  updateOfferloading: false,
  //delete
  deleteOffer: false,
  deleteOfferError: null,
  deleteingOffer: false,

  //bulk
  bulkDeleteOffer: false,
  bulkDeleteOfferError: null,
  bulkDeleteingOffer: false,
  BulkOfferToDelete:null,

  //select single offer

  singleOffer: null,

  //search
  search: null,
  searchError: null,
  searchLoading: false,
};

const offerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_OFFER_LIST_START: {
      return {
        ...state,
        offers: null,
        offersError: null,
        loadingOffers: true,
      };
    }
    case types.GET_OFFER_LIST_SUCCESS: {
      return {
        ...state,
        offers: action.payload,
        loadingOffers: false,
      };
    }
    case types.GET_OFFER_LIST_ERROR: {
      return {
        ...state,
        offersError: action.payload,
        loadingOffers: false,
      };
    }

    //create offer

    case types.CREATE_OFFER_START: {
      return {
        ...state,
        createOffers: null,
        createOffersError: null,
        createOffersloading: true,
      };
    }
    case types.CREATE_OFFER_SUCCESS: {
      return {
        ...state,
        createOffers: action.payload,
        createOffersloading: false,
      };
    }
    case types.CREATE_OFFER_ERROR: {
      return {
        ...state,
        createOffersError: action.payload,
        createOffersloading: false,
      };
    }
    case types.RESET_CREATE_OFFER: {
      return {
        ...state,
        createOffersError: null,
        createOffers: null,
      };
    }

    //update offer

    case types.UPDATE_OFFER_START: {
      return {
        ...state,
        updateOfferError: null,
        updateOfferloading: true,
      };
    }
    case types.UPDATE_OFFER_SUCCESS: {
      const response = action.payload.response;
      const list = state.offers.data;
      const newlist = list.map((data) =>
        data.id !== response.id ? data : response
      );

      return {
        ...state,
        offers: { ...state.offers, data: newlist },
        updateOffer: action.payload.success,
        updateOfferloading: false,
      };
    }
    case types.UPDATE_OFFER_ERROR: {
      return {
        ...state,
        updateOfferError: action.payload,
        updateOfferloading: false,
      };
    }
    case types.RESET_UPDATE_OFFER: {
      return {
        ...state,
        updateOffer: false,
        updateOfferError: null,
        updateOfferloading: false,
      };
    }

    //Delete offer

    case types.DELETE_OFFER_START: {
      return {
        ...state,
        deleteOffer: false,
        deleteOfferError: null,
        deleteingOffer: true,
      };
    }
    case types.DELETE_OFFER_SUCCESS: {
      return {
        ...state,
        deleteOffer: action.payload,
        deleteingOffer: false,
      };
    }
    case types.DELETE_OFFER_ERROR: {
      return {
        ...state,
        deleteOfferError: action.payload,
        deletingOffer: false,
      };
    }
    case types.RESET_DELETE_OFFER: {
      return {
        ...state,
        deleteOffer: false,
        deleteOfferError: null,
        deleteingOffer: false,
      };
    }

    //bulk delete
    case types.BULK_DELETE_OFFER_START: {
      return {
        ...state,
        BulkOfferToDelete:null,
        bulkDeleteOffer: false,
        bulkDeleteOfferError: null,
        bulkDeleteingOffer: true,
      };
    }
    case types.BULK_DELETE_OFFER_SUCCESS: {
      return {
        ...state,
        // BulkOfferToDelete:action.payload,
        bulkDeleteOffer:action.payload,
        bulkDeleteingOffer :false,
      };
    }
    case types.BULK_DELETE_OFFER_ERROR: {
      return {
        ...state,
        bulkDeleteOfferError: action.payload,
        bulkDeleteingOffer: false,
      };
    }
    case types.BULK_RESET_DELETE_OFFER: {
      return {
        ...state,
        BulkOfferToDelete:null,
        bulkDeleteOffer: false,
        bulkDeleteOfferError: null,
        bulkDeleteingOffer: false,
      };
    }

    //select single offer
    case types.GET_SINGLE_OFFER: {
      console.log(action);
      return {
        ...state,
        singleOffer: action.payload,
      };
    }

    case types.SEARCH_OFFER_START: {
      return {
        ...state,
        search: null,
        searchError: null,
        searchLoading: true,
      };
    }
    case types.SEARCH_OFFER_ERROR: {
      return {
        ...state,
        searchError: action.payload,
        searchLoading: false,
      };
    }
    case types.SEARCH_OFFER_SUCCESS: {
      return {
        ...state,
        search: action.payload,
        searchLoading: false,
      };
    }
    case types.OFFER_SEARCH_RESET: {
      return {
        ...state,
        search: null,
        searchError: null,
        searchLoading: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default offerReducer;
