import React, { useState, useEffect } from "react";
import { Footer, MenuList, Navbar } from "../Components";
import { Outlet, useNavigate } from "react-router-dom";
import styles from "./css/mainlayout.module.css";
import useScreenSize from "../util/useScreenSize";
import { MdArrowBackIosNew } from "react-icons/md";
import { useSelector } from "react-redux";

const MainLayout = () => {
  const [fullScreen, setFullScreen] = useState(false);
  const screen = useScreenSize();
  const auth = useSelector((state) => state.Auth);
  const selectedProject = useSelector(state => state.project.selected_project);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!auth.staffUser) {
  //     navigate("/", { replace: true });
  //   }
  // }, []);
  // useEffect(() => {
  //   if (!auth.staffUser) {
  //     navigate("/", { replace: true });
  //   }
  // }, [auth]);
  useEffect(()=>{
    if(!selectedProject && window.location.pathname !== '/projects'){
      navigate("/projects",{return:false})
    }
  },[])
  return (
    <>
      <Navbar fullScreen={fullScreen} setScreen={setFullScreen} />

      <div className={styles.container}>
        <div
          className={`${fullScreen ? styles.lgmenu : styles.smmenu} ${
            styles.menu
          }`}
        >
          {screen > 500 ? (
            <MdArrowBackIosNew
              className={`${styles.collapseBtn} ${
                fullScreen ? styles.rotate_0 : styles.rotate_180
              } `}
              onClick={() => {
                setFullScreen(!fullScreen);
              }}
              style={{ cursor: "pointer" }}
            />
          ) : (
            ""
          )}
          <div className={styles.menuContainer}>
            <MenuList />
          </div>
        </div>
        <div
          className={` ${fullScreen ? styles.smmain : styles.lgmain} ${
            styles.main
          }`}
        >
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MainLayout;
