import React, { useState } from "react";
import styles from "./css/style.module.css";

const Accordion = (props) => {
	const [expand, setExpand] = useState(props.expand);

	return (
		<div className={`${styles.container} ${props.className}`}>
			<div
				className={styles.header}
				onClick={() => {
					console.log("click");
					setExpand(!expand);
				}}>
				{props.header}
			</div>
			<div className={expand ? styles.body_expand : styles.body}>
				{props.body}
			</div>
		</div>
	);
};

Accordion.defaultsProps = {
	expand: false,
};

export default Accordion;
