import types from "./types";

const INITIAL_STATE = {
  familyMember: null,
  familyMemberLoading: true,
  familyMemberError: null,

  newFamilyMember: null,
  createFamilyMemberError: null,
  createingNewFamilyMember: false,

  deleteFamilyMember: null,
  deleteFamilyMemberError: null,
  deleteingNewFamilyMember: false,
};

const FamilyMemberReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_FAMILY_MEMBER_START: {
      return {
        ...state,
        familyMember: null,
        familyMemberLoading: true,
        familyMemberError: null,
      };
    }
    case types.GET_FAMILY_MEMBER_SUCCESS: {
      return {
        ...state,
        familyMember: action.payload.data,
        familyMemberLoading: false,
      };
    }
    case types.GET_FAMILY_MEMBER_ERROR: {
      return {
        ...state,
        familyMemberLoading: false,
        familyMemberError: action.payload,
      };
    }
    //create Family  member
    case types.CREATE_FAMILY_MEMBER_START: {
      return {
        ...state,
        newFamilyMember: null,
        createFamilyMemberError: null,
        createingNewFamilyMember: true,
      };
    }

    case types.CREATE_FAMILY_MEMBER_ERROR: {
      return {
        ...state,
        createFamilyMemberError: action.payload,
        createingNewFamilyMember: false,
      };
    }

    case types.CREATE_FAMILY_MEMBER_SUCCESS: {
      return {
        ...state,
        newFamilyMember: action.payload,
        createingNewFamilyMember: false,
      };
    }
    case types.RESET_CREATE_FAMILY_MEMBER_START: {
      return {
        ...state,
        newFamilyMember: null,
        createFamilyMemberError: null,
        createingNewFamilyMember: false,
      };
    }

    //delete Family  member
    case types.DELETE_FAMILY_MEMBER_START: {
      return {
        ...state,
        deleteFamilyMember: null,
        deleteFamilyMemberError: null,
        deleteingNewFamilyMember: true,
      };
    }

    case types.DELETE_FAMILY_MEMBER_SUCCESS: {
      return {
        ...state,
        deleteFamilyMember: action.payload,
        deleteingNewFamilyMember: false,
      };
    }
    case types.DELETE_FAMILY_MEMBER_ERROR: {
      return {
        ...state,

        deleteFamilyMemberError: action.payload,
        deleteingNewFamilyMember: false,
      };
    }
    case types.RESET_DELETE_FAMILY_MEMBER_START: {
      return {
        ...state,
        deleteFamilyMember: null,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default FamilyMemberReducer;
