const types = {
  GET_MAINTENANCE_REQUEST_LIST_START: "GET_MAINTENANCE_REQUEST_LIST_START",
  GET_MAINTENANCE_REQUEST_LIST_SUCCESS: "GET_MAINTENANCE_REQUEST_LIST_SUCCESS",
  GET_MAINTENANCE_REQUEST_LIST_ERROR: "GET_MAINTENANCE_REQUEST_LIST_ERROR",
  //create
  CREATE_MAINTENANCE_REQUEST_START: "CREATE_MAINTENANCE_REQUEST_START",
  CREATE_MAINTENANCE_REQUEST_SUCCESS: "CREATE_MAINTENANCE_REQUEST_SUCCESS",
  CREATE_MAINTENANCE_REQUEST_ERROR: "CREATE_MAINTENANCE_REQUEST_ERROR",
  CREATE_MAINTENANCE_REQUEST_RESET: "CREATE_MAINTENANCE_REQUEST_RESET",

  //update
  UPDATE_MAINTENANCE_REQUEST_START: "UPDATE_MAINTENANCE_REQUEST_START",
  UPDATE_MAINTENANCE_REQUEST_SUCCESS: "UPDATE_MAINTENANCE_REQUEST_SUCCESS",
  UPDATE_MAINTENANCE_REQUEST_ERROR: "UPDATE_MAINTENANCE_REQUEST_ERROR",
  UPDATE_MAINTENANCE_REQUEST_RESET: "UPDATE_MAINTENANCE_REQUEST_RESET",

  //delete
  DELETE_MAINTENANCE_REQUEST_START: "DELETE_MAINTENANCE_REQUEST_START",
  DELETE_MAINTENANCE_REQUEST_SUCCESS: "DELETE_MAINTENANCE_REQUEST_SUCCESS",
  DELETE_MAINTENANCE_REQUEST_ERROR: "DELETE_MAINTENANCE_REQUEST_ERROR",
  DELETE_MAINTENANCE_REQUEST_RESET: "DELETE_MAINTENANCE_REQUEST_RESET",

  //set single item
  SINGLE_MAINTENANCE_REQUEST_START: "SINGLE_MAINTENANCE_REQUEST_START",
  SINGLE_MAINTENANCE_REQUEST_SUCCESS: "SINGLE_MAINTENANCE_REQUEST_SUCCESS",
  SINGLE_MAINTENANCE_REQUEST_ERROR: "SINGLE_MAINTENANCE_REQUEST_ERROR",

  SEARCH_MAINTENANCE_REQUEST_START: "SEARCH_MAINTENANCE_REQUEST_START",
  SEARCH_MAINTENANCE_REQUEST_SUCCESS: "SEARCH_MAINTENANCE_REQUEST_SUCCESS",
  SEARCH_MAINTENANCE_REQUEST_ERROR: "SEARCH_MAINTENANCE_REQUEST_ERROR",
  MAINTENANCE_REQUEST_SEARCH_RESET: "MAINTENANCE_REQUEST_SEARCH_RESET",

  COMMENT_ON_MAINTENANCE_REQUEST_START: "COMMENT_ON_MAINTENANCE_REQUEST_START",
  COMMENT_ON_MAINTENANCE_REQUEST_SUCCESS:
    "COMMENT_ON_MAINTENANCE_REQUEST_SUCCESS",
  COMMENT_ON_MAINTENANCE_REQUEST_ERROR: "COMMENT_ON_MAINTENANCE_REQUEST_ERROR",
  COMMENT_ON_MAINTENANCE_REQUEST_RESET: "COMMENT_ON_MAINTENANCE_REQUEST_RESET",
};
export default types;
