const types = {
	GET_FAMILY_MEMBER_START: "GET_FAMILY_MEMBER_START",
	GET_FAMILY_MEMBER_SUCCESS: "GET_FAMILY_MEMBER_SUCCESS",
	GET_FAMILY_MEMBER_ERROR: "GET_FAMILY_MEMBER_ERROR",

	//create
	CREATE_FAMILY_MEMBER_START: "CREATE_FAMILY_MEMBER_START",
	CREATE_FAMILY_MEMBER_SUCCESS: "CREATE_FAMILY_MEMBER_SUCCESS",
	CREATE_FAMILY_MEMBER_ERROR: "CREATE_FAMILY_MEMBER_ERROR",
	RESET_CREATE_FAMILY_MEMBER_START: "RESET_CREATE_FAMILY_MEMBER_START",

	//delete
	DELETE_FAMILY_MEMBER_START: "DELETE_FAMILY_MEMBER_START",
	DELETE_FAMILY_MEMBER_SUCCESS: "DELETE_FAMILY_MEMBER_SUCCESS",
	DELETE_FAMILY_MEMBER_ERROR: "DELETE_FAMILY_MEMBER_ERROR",
	RESET_DELETE_FAMILY_MEMBER_START: "RESET_DELETE_FAMILY_MEMBER_START",
};
export default types;
