const types = {
	GET_COUPON_START: "GET_COUPON_START",
	GET_COUPON_SUCCESS: "GET_COUPON_SUCCESS",
	GET_COUPON_ERROR: "GET_COUPON_ERROR",

	//create coupon
	CREATE_COUPON_START: "CREATE_COUPON_START",
	CREATE_COUPON_SUCCESS: "CREATE_COUPON_SUCCESS",
	CREATE_COUPON_ERROR: "CREATE_COUPON_ERROR",
	RESET_CREATE_COUPON: "RESET_CREATE_COUPON",

	//update coupon
	UPDATE_COUPON_START: "UPDATE_COUPON_START",
	UPDATE_COUPON_SUCCESS: "UPDATE_COUPON_SUCCESS",
	UPDATE_COUPON_ERROR: "UPDATE_COUPON_ERROR",
	RESET_UPDATE_COUPON: "RESET_UPDATE_COUPON",
	//delete
	DELETE_COUPON_START: "DELETE_COUPON_START",
	DELETE_COUPON_SUCCESS: "DELETE_COUPON_SUCCESS",
	DELETE_COUPON_ERROR: "DELETE_COUPON_ERROR",
	RESET_DELETE_COUPON: "RESET_DELETE_COUPON",
};

export default types;
