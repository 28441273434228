import types from "./types";

const INITIAL_STATE = {
	coupon: null,
	couponError: null,
	loadingcoupon: false,

	//create coupon
	couponCreate: null,
	couponCreateError: null,
	loadingCreateCoupon: false,

	//update coupon
	couponUpdate: null,
	couponUpdateError: null,
	loadingUpdateCoupon: false,

	//delete coupon
	couponDelete: null,
	couponDeleteError: null,
	loadingDeleteCoupon: false,
};

const couponReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_COUPON_START: {
			return {
				...state,
				coupon: null,
				couponError: null,
				loadingcoupon: false,
			};
		}
		case types.GET_COUPON_SUCCESS: {
			return {
				...state,
				coupon: action.payload,
				loadingcoupon: false,
			};
		}
		case types.GET_COUPON_ERROR: {
			return {
				...state,
				couponError: action.payload,
				loadingcoupon: false,
			};
		}

		//create offer

		case types.CREATE_COUPON_START: {
			return {
				...state,
				couponCreate: null,
				couponCreateError: null,
				loadingCreateCoupon: true,
			};
		}
		case types.CREATE_COUPON_SUCCESS: {
			return {
				...state,
				couponCreate: action.payload,
				loadingCreateCoupon: false,
			};
		}
		case types.CREATE_COUPON_ERROR: {
			return {
				...state,
				couponCreateError: action.payload,
				loadingCreateCoupon: false,
			};
		}
		case types.RESET_CREATE_COUPON: {
			return {
				...state,
				couponCreateError: null,
				couponCreate: null,
			};
		}

		// //update offer

		case types.UPDATE_COUPON_START: {
			return {
				...state,

				couponUpdateError: null,
				loadingUpdateCoupon: true,
			};
		}
		case types.UPDATE_COUPON_SUCCESS: {
			return {
				...state,
				couponUpdate: action.payload,
				coupon: action.payload.response,
				loadingUpdateCoupon: false,
			};
		}
		case types.UPDATE_COUPON_ERROR: {
			return {
				...state,
				couponUpdateError: action.payload,
				loadingUpdateCoupon: false,
			};
		}
		case types.RESET_UPDATE_COUPON: {
			return {
				...state,
				couponUpdate: false,
				couponUpdateError: null,
			};
		}

		// //Delete offer

		case types.DELETE_COUPON_START: {
			return {
				...state,
				couponDelete: null,
				couponDeleteError: null,
				loadingDeleteCoupon: true,
			};
		}
		case types.DELETE_COUPON_SUCCESS: {
			return {
				...state,
				couponDelete: action.payload,
				loadingDeleteCoupon: false,
			};
		}
		case types.DELETE_COUPON_ERROR: {
			return {
				...state,

				couponDeleteError: action.payload,
				loadingDeleteCoupon: false,
			};
		}
		case types.RESET_DELETE_OFFER: {
			return {
				...state,
				couponDelete: null,
				couponDeleteError: null,
			};
		}

		default: {
			return {
				...state,
			};
		}
	}
};

export default couponReducer;
