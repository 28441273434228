import React, { useEffect, useState } from "react";
import styles from "./css/style.module.css";
import { useNavigate, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
// importing actions
import { staffLoginStart } from "../../store/Auth/actions";

// importing selectors
import {
  selectStaffLoginLoading,
  selectStaffLoginError,
  selectStaffuser,
} from "../../store/Auth/selectors";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";

// importing ui elements
import { PrimaryButton } from "../../ui-elements/Button";
import { FormFieldError } from "../../ui-elements/Error";
import { AppLogo } from "./Elements";
import {selectProject} from "../../store/Project/actions";

const LoginError = ({ error }) => {
  return (
    <FormFieldError>
      {JSON.stringify(error)}
      {error?.response ? "" : <div>{error?.message}</div>}
    </FormFieldError>
  );
};

const Login = ({
  // selectors
  loading,
  error,
  staffUser,

  // actions
  staffLogin,
}) => {
  const [user, setUser] = useState({ phone: "", password: "" });
  const [countryCode, setCountryCode] = useState(process.env.REACT_APP_DEFAULT_COUNTRY_CODE);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    const payload = {
      phone : `${countryCode}-${user.phone}`,
      password : user.password
    }
    staffLogin(payload);
  };

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (staffUser) {
      if(staffUser?.type === 'admin'){
        navigate("/projects", { return: true });
      }else{
        dispatch(selectProject(staffUser?.project));
        navigate("/dashboard", { return: true });
      }
    }
  }, [staffUser]);

  return (
    <div>
      <div className={styles.overlay}>
        <AppLogo src="/image/app-icon.png" />
        <form className={styles.form}>
          <p>Login in to access admin panel</p>
          <div
            style={{
              display : 'flex',
              justifyContent : 'space-between',
              alignItems : 'center',
              gap : 10,
              width : '100%'
            }}
          >
            <input
                style={{ flex : 1}}
                className={styles.input}
                name={"country_code"}
                type={"text"}
                placeholder={"+966"}
                value={countryCode}
                onChange={e=> setCountryCode(e.target.value)}
            />
            <input
                min={0}
                style={{ flex : 8}}
                className={styles.input}
                name="phone"
                type="number"
                placeholder="Phone"
                onChange={(e) => {
                  setUser({
                    ...user,
                    phone: e.target.value,
                  });
                }}
                value={user.email}
            />
          </div>

          <input
            style={{ width : '95%' }}
            className={styles.input}
            name="password"
            type="password"
            placeholder="Password"
            onChange={(e) => {
              setUser({
                ...user,
                password: e.target.value,
              });
            }}
            value={user.password}
          />
          {/* <p
            style={{
              color: 'grey',
              fontSize: '0.6em'
            }} 
            >* Password must be of length between 8 and 20 characters,
                numbers and letters only,
                no spaces or other special characters allowed.
            </p> */}
          {/* <Link to={"/forgot-password"}>Forgot Password?</Link> */}

          {user.phone && user.password && (
            <PrimaryButton
              name="login-button"
              onClick={handleSubmit}
              type="button"
              disabled={loading}
            >
              {loading ? (
                <Circles width={20} height={20} color={"white"} />
              ) : (
                "Login"
              )}
            </PrimaryButton>
          )}
          <br />
          {/* <LoginError 
            error={error}
          /> */}
        </form>
        {/*<Link to={'privacy-policy'} style={{display : 'block',textAlign:'center'}}>Privacy Policy</Link>*/}
        {/*<Link to={'support'} style={{display : 'block',textAlign:'center'}}>Support</Link>*/}
      </div>
      {/* <img className={styles.bgImage} src="/image/login-bg.png" alt="login" /> */}
    </div>
  );
};

const mapState = (state) => ({
  loading: selectStaffLoginLoading(state),
  error: selectStaffLoginError(state),
  staffUser: selectStaffuser(state),
});
const mapDispatch = (dispatch) => ({
  staffLogin: (data) => dispatch(staffLoginStart(data)),
});
export default connect(mapState, mapDispatch)(Login);
