import {all} from "redux-saga/effects";

import {generateGuestJWTWatcherSaga, staffLoginStartWatcherSaga,} from "../store/Auth/sagas";
import {getSingleHousingWatcherSaga} from "../store/Dashboard/sagas";
import {
    BuildingSearchStartWatcherSaga,
    createNewBuildingWatcherSaga,
    deleteBuildingStartWatcherSaga,
    getBuildingsListWatcherSaga,
    putBuildingDetailsWatcherSaga,
    bulkDeleteBuildingStartWatcherSaga,
} from "../store/Building/sagas";
import {
    createNewVillaWatcherSaga,
    deleteVillaWatcherSaga,
    deleteVillaBulkdeleteWatcherSaga,
    getVillaListStartWatcher,
    SingleVillaWatcherSaga,
    UpdateVillaWatcherSaga,
    villaAssignStartWatcherSaga,
    VillaSearchStartWatcherSaga,
    villaUnAssignResidentWatcherSaga,
} from "../store/Villa/sagas";
import {
    createOfferWatcherSaga,
    deleteOfferWatcherSaga,
    getOfferListWatcherSaga,
    OfferSearchStartWatcherSaga,
    updateOfferWatcherSaga,
    bulkDeleteOfferWatcherSaga,
} from "../store/Offer/sagas";

import {
    createCouponWatcherSaga,
    deleteCouponWatcherSaga,
    getCouponWatcherSaga,
    updateCouponWatcherSaga,
} from "../store/coupon/sagas";

import {
    changeVisitorPermitStatusWatcherSaga,
    createVisitorPermitListWatcherSaga,
    deletePermitsWatcherSaga,
    getVisitorPermitListWatcherSaga,
    selectPermitsWatcherSaga,
    updateVisitorPermitListWatcherSaga,
    visitorPermitSearchStartWatcherSaga,
} from "../store/VisitorPermit/sagas";
import {
    commentOnMaintenanceRequestStartWatcherSaga,
    createMaintenanceRequestStartWatcherSaga,
    deleteMaintenanceRequestStartWatcherSaga,
    getMaintenanceRequestListStartWatcherSaga,
    maintenanceRequestSearchStartWatcherSaga,
    singleMaintenanceRequestStartWatcherSaga,
    updateMaintenanceRequestStartWatcherSaga,
} from "../store/MaintenanceRequest/sagas";
import {getBuildingsDetailsWatcherSaga} from "./BuildingDetail/sagas";
import {
    assignManagerStartWatcherSaga, assignSubAdminStartWatcherSaga,
    createNewUserStartWatcherSaga,
    deleteNewUserStartWatcherSaga,
    getUnassignedCustomersStartWatcherSaga,
    getUserDetailsStartWatcherSaga,
    getUserListStartWatcherSaga,
    updateNewUserStartWatcherSaga,
    userSearchStartWatcherSaga,
    deleteUsersBulkdeleteWatcherSaga,
} from "./User/sagas";
import {
    assignApartmentStartWatcherSaga,
    createApartmentStartWatcherSaga,
    deleteApartmentStartWatcherSaga,
    getApartmentsListStartWatcherSaga,
    unassignApartmentStartWatcherSaga,
    updateApartmentStartWatcherSaga,
    bulkDeleteApartmentStartWatcherSaga,
} from "./Apartment/sagas";
import {
    createNewFamilyMemberWatcherSaga,
    deleteNewFamilyMemberWatcherSaga,
    getFamilyMemberWatcherSaga,
} from "./FamilyMember/sagas";

import {createCommentsWatcherSaga, getCommentsWatcherSaga,} from "./Comments/sagas";

import {passwordChangerWatcherSaga, updateAdminWatcherSaga,} from "./AdminProfile/sagas";

import {singleImageUploadStartWatcherSaga} from "./ImageUpload/sagas";
import {
    getProjectListStartWatcherSaga,
    createProjectStartWatcherSaga,
    editProjectStartWatcherSaga,
    deleteProjectWatcherSaga
} from "./Project/sagas";
import {
    sendBulkNotificationsWatcherSaga,
} from "./Notification/sagas";

import {categorySaga} from "./Category/sagas";


export default function* root() {
    yield all([
        generateGuestJWTWatcherSaga(),
        getSingleHousingWatcherSaga(),
        staffLoginStartWatcherSaga(),
        //buildiing
        createNewBuildingWatcherSaga(),
        getBuildingsListWatcherSaga(),
        putBuildingDetailsWatcherSaga(),
        deleteBuildingStartWatcherSaga(),
        bulkDeleteBuildingStartWatcherSaga(),
        BuildingSearchStartWatcherSaga(),

        //villa
        getVillaListStartWatcher(),
        createNewVillaWatcherSaga(),
        UpdateVillaWatcherSaga(),
        deleteVillaWatcherSaga(),
        deleteVillaBulkdeleteWatcherSaga(),
        SingleVillaWatcherSaga(),
        VillaSearchStartWatcherSaga(),
        villaAssignStartWatcherSaga(),
        villaUnAssignResidentWatcherSaga(),
        //offer
        getOfferListWatcherSaga(),
        createOfferWatcherSaga(),
        updateOfferWatcherSaga(),
        deleteOfferWatcherSaga(),
        OfferSearchStartWatcherSaga(),
        bulkDeleteOfferWatcherSaga(),
        //visitorpermit
        getVisitorPermitListWatcherSaga(),
        createVisitorPermitListWatcherSaga(),
        updateVisitorPermitListWatcherSaga(),
        changeVisitorPermitStatusWatcherSaga(),
        deletePermitsWatcherSaga(),
        selectPermitsWatcherSaga(),
        visitorPermitSearchStartWatcherSaga(),
        //maintenance
        getMaintenanceRequestListStartWatcherSaga(),
        createMaintenanceRequestStartWatcherSaga(),
        updateMaintenanceRequestStartWatcherSaga(),
        deleteMaintenanceRequestStartWatcherSaga(),
        maintenanceRequestSearchStartWatcherSaga(),
        singleMaintenanceRequestStartWatcherSaga(),
        commentOnMaintenanceRequestStartWatcherSaga(),
        assignManagerStartWatcherSaga(),

        //building Detail
        getBuildingsDetailsWatcherSaga(),
        //user
        getUserListStartWatcherSaga(),
        createNewUserStartWatcherSaga(),
        deleteNewUserStartWatcherSaga(),
        userSearchStartWatcherSaga(),
        updateNewUserStartWatcherSaga(),
        getUnassignedCustomersStartWatcherSaga(),
        assignSubAdminStartWatcherSaga(),
        deleteUsersBulkdeleteWatcherSaga(),
        //user detail
        getUserDetailsStartWatcherSaga(),

        //apartment
        getApartmentsListStartWatcherSaga(),
        createApartmentStartWatcherSaga(),
        deleteApartmentStartWatcherSaga(),
        updateApartmentStartWatcherSaga(),
        assignApartmentStartWatcherSaga(),
        unassignApartmentStartWatcherSaga(),
        bulkDeleteApartmentStartWatcherSaga(),

        //family member
        getFamilyMemberWatcherSaga(),
        createNewFamilyMemberWatcherSaga(),
        deleteNewFamilyMemberWatcherSaga(),
        //comment
        getCommentsWatcherSaga(),
        createCommentsWatcherSaga(),

        //coupon
        getCouponWatcherSaga(),
        createCouponWatcherSaga(),
        updateCouponWatcherSaga(),
        deleteCouponWatcherSaga(),

        //adminProfile
        passwordChangerWatcherSaga(),
        updateAdminWatcherSaga(),

        // image upload
        singleImageUploadStartWatcherSaga(),

        // projects
        getProjectListStartWatcherSaga(),
        createProjectStartWatcherSaga(),
        editProjectStartWatcherSaga(),
        deleteProjectWatcherSaga(),

        // notificaitons
        sendBulkNotificationsWatcherSaga(),

        //category
        categorySaga()
    ]);
}
