import types from "./types";

const INITIAL_STATE = {
	buildingsDetails: null,
	buildingsDetailsError: null,
	loadingBuildingsDetails: false,
};

const buildingsDetailReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		// GET BUILDING DETAILS
		case types.GET_BUILDING_DETAILS_START: {
			return {
				...state,
				buildingsDetails: null,
				loadingBuildingsDetails: true,
				buildingsDetailsError: null,
			};
		}
		case types.GET_BUILDING_DETAILS_SUCCESS: {
			return {
				...state,
				buildingsDetails: action.payload,
				loadingBuildingsDetails: false,
			};
		}
		case types.GET_BUILDING_DETAILS_ERROR: {
			return {
				...state,
				loadingBuildingsDetails: false,
				buildingsDetailsError: action.payload,
			};
		}
		default: {
			return {
				...state,
			};
		}
	}
};

export default buildingsDetailReducer;
