import React, {useState, useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingButton } from "../../Components";

// IMPORTING ACTIONS
import {
  createBuildingStart,
  createBuildingStateReset,
  updateBuildingStart,
  buildingUpdateReset,
} from "../../store/Building/actions";

// IMPORTING COMPONENTS
import { PrimaryButton } from "../../ui-elements/Button";
const BuildingForm = ({ mode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const buildings = useSelector((state) => state.buildings);
  const {selected_project} = useSelector(state => state.project);
  const {
    newBuilding,
    creatingNewBuilding,
    buildingCreationError,
    buildingForUpdate,
    updatedBuilding,
    updatingBuilding,
    buildingUpdateError,
  } = buildings;
  const selectedProject = useMemo(()=> selected_project, [selected_project]);
  const [formData, setFormData] = useState({
    id: null,
    name: "",
    building_number: "",
  });
  function submitHandler(e) {
    if (!buildingForUpdate) {
      dispatch(
        createBuildingStart({
          projectId: selectedProject?.id,
          name: formData?.name.trim(),
          building_number: formData?.building_number.trim(),
        })
      );
    } else {
      dispatch(
        updateBuildingStart({
          id: formData?.id,
          projectId: selectedProject?.id,
          name: formData?.name.trim(),
          building_number: formData?.building_number.trim(),
        })
      );
    }
  }

  useEffect(() => {
    if (buildingForUpdate) {
      setFormData({
        id: buildingForUpdate?.id || null,
        name: buildingForUpdate?.name || "",
        building_number: buildingForUpdate?.building_number || "",
      });
    } else {
      setFormData({
        id: null,
        name: "",
        building_number: "",
      });
    }
  }, [buildingForUpdate]);
  useEffect(() => {
    if (buildingCreationError) {
      if (typeof buildingCreationError?.response === "object") {
        const keys = Object.keys(buildingCreationError?.response);
        if (keys.length > 0)
          keys?.forEach((_k) => {
            toast.error(buildingCreationError?.response[_k]);
          });
        else toast.error(buildingCreationError?.message);
      }
    }
  }, [buildingCreationError]);
  useEffect(() => {
    if (buildingUpdateError) {
      toast.error(buildingUpdateError?.response);
    }
  }, [buildingUpdateError]);

  useEffect(() => {
    if (newBuilding) {
      dispatch(createBuildingStateReset());
      navigate(`/building/${newBuilding?.id}`, { return: true });
    }
  }, [newBuilding]);
  return (
    <form>
      <div className="form_row">
        <label>Building Name or Number*</label>
        <input
          name="building_number"
          onChange={(e) =>
            setFormData({
              ...formData,
              building_number: e.target.value,
            })
          }
          style={{ width: "100%" }}
          className="form_input"
          type="text"
          value={formData.building_number}
        />
      </div>

      {creatingNewBuilding || updatingBuilding ? (
        <LoadingButton style={{ width: "100%" }} />
      ) : (
        <PrimaryButton
          type="button"
          className="submit_btn"
          onClick={submitHandler}
        >
          Submit
        </PrimaryButton>
      )}
    </form>
  );
};

export default BuildingForm;
