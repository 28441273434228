import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import { apiClientFactory, endpoints } from "../../backend";

function* singleImageUploadStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.post,
      endpoints.singleImageUpload,
      action.payload
    );
    yield put({
      type: types.SINGLE_IMAGE_UPLOAD_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const payload = e?.response || e;
    yield put({
      type: types.SINGLE_IMAGE_UPLOAD_ERROR,
      payload,
    });
  }
}

export function* singleImageUploadStartWatcherSaga() {
  yield takeEvery(
    types.SINGLE_IMAGE_UPLOAD_START,
    singleImageUploadStartHandlerSaga
  );
}
