import types from "./types";

const INITIAL_STATE = {
	comment: null,
	commentError: null,
	loadingcomment: false,

	createComment: null,
	createCommentError: null,
	createLoadingComment: false,
};

const commentReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		// GET COMMENT
		case types.GET_COMMENT_START: {
			return {
				...state,
				comment: null,
				loadingcomment: true,
				commentError: null,
			};
		}
		case types.GET_COMMENT_SUCCESS: {
			return {
				...state,
				comment: action.payload,
				loadingcomment: false,
			};
		}
		case types.GET_COMMENT_ERROR: {
			return {
				...state,
				loadingcomment: false,
				commentError: action.payload,
			};
		}
		//create Comment

		case types.CREATE_COMMENT_START: {
			return {
				...state,
				createComment: null,
				createCommentError: null,
				createLoadingComment: false,
			};
		}
		case types.CREATE_COMMENT_SUCCESS: {
			return {
				...state,
				createComment: action.payload,
				createLoadingComment: false,
			};
		}
		case types.CREATE_COMMENT_ERROR: {
			return {
				...state,
				createCommentError: action.payload,
				createLoadingComment: false,
			};
		}
		default: {
			return {
				...state,
			};
		}
	}
};

export default commentReducer;
