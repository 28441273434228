import types from "./types";

const INITIAL_STATE = {
  guestToken: null,
  guestTokenLoading: false,
  guestTokenError: null,

  staffUser: null,
  staffLoginLoading: false,
  staffLoginError: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.STAFF_LOGOUT: {
      localStorage.removeItem("userToken");
      localStorage.removeItem("persist:baitti-admin");
      window.location.reload();
      return {
        ...state,
        staffUser: null,
        staffLoginLoading: false,
        staffLoginError: null,
      };
    }
    case types.STAFF_LOGIN_START: {
      return {
        ...state,
        staffUser: null,
        staffLoginLoading: true,
        staffLoginError: null,
      };
    }
    case types.STAFF_LOGIN_SUCCESS: {
      localStorage.setItem("userToken", action.payload.token);
      return {
        ...state,
        staffUser: action.payload,
        staffLoginLoading: false,
      };
    }
    case types.STAFF_LOGIN_ERROR: {
      return {
        ...state,
        staffLoginLoading: false,
        staffLoginError: action.payload,
      };
    }
    case types.GENERATE_GUEST_JWT_START: {
      return {
        ...state,
        guestToken: null,
        guestTokenLoading: true,
        guestTokenError: null,
      };
    }
    case types.GENERATE_GUEST_JWT_SUCCESS: {
      localStorage.setItem("userToken", action.payload.token);
      return {
        ...state,
        guestToken: action.payload,
        guestTokenLoading: false,
      };
    }
    case types.GENERATE_GUEST_JWT_ERROR: {
      return {
        ...state,
        guestTokenLoading: false,
        guestTokenError: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default authReducer;
