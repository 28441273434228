import types from "./types";

const INITIAL_STATE = {
  userList: null,
  loadingUserList: false,
  userListError: null,

  newUser: null,
  createUserError: null,
  creatingNewUser: false,

  userDetails: null,
  gettingUserDetails: false,
  userDetailsError: null,

  userToDelete: null,
  userDelete: null,
  userDeleteState: false,
  userDeleteError: null,

  BulkUserToDelete: null,
  BulkUserDelete: null,
  BulkUserDeleteState: false,
  BulkUserDeleteError: null,

  userToUpdate: null,
  userUpdate: null,
  userUpdateState: false,
  userUpdateError: null,

  search: null,
  searchError: null,
  searchLoading: false,

  unassignedCustomers: null,
  fetchingUnassignedCustomers: false,
  unassignedCustomersError: null,

  assignManagerSuccess: null,
  assigningManager: false,
  assignManagerError: null,

  sub_admin_assigned: null,
  assigning_sub_admin: false,
  sub_admin_assign_error: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ASSIGN_SUB_ADMIN_START: {
      return {
        ...state,
        sub_admin_assigned: null,
        assigning_sub_admin: true,
        sub_admin_assign_error: null,
      }
    }
    case types.ASSIGN_SUB_ADMIN_SUCCESS: {
      return {
        ...state,
        sub_admin_assigned: action.payload,
        assigning_sub_admin: false,
        sub_admin_assign_error: null,
      }
    }
    case types.ASSIGN_SUB_ADMIN_ERROR: {
      return {
        ...state,
        sub_admin_assigned: null,
        assigning_sub_admin: false,
        sub_admin_assign_error: action.payload,
      }
    }
    case types.ASSIGN_MANAGER_RESET: {
      return {
        ...state,
        assignManagerSuccess: null,
        assigningManager: false,
        assignManagerError: null,
      };
    }
    case types.ASSIGN_MANAGER_START: {
      return {
        ...state,
        assignManagerSuccess: null,
        assigningManager: true,
        assignManagerError: null,
      };
    }
    case types.ASSIGN_MANAGER_SUCCESS: {
      return {
        ...state,
        assignManagerSuccess: action.payload,
        assigningManager: false,
        assignManagerError: null,
      };
    }
    case types.ASSIGN_MANAGER_ERROR: {
      return {
        ...state,
        assignManagerSuccess: null,
        assigningManager: false,
        assignManagerError: action.payload,
      };
    }
    case types.CREATE_USER_STATE_RESET: {
      return {
        ...state,
        newUser: null,
        createUserError: null,
        creatingNewUser: false,
      };
    }
    case types.GET_UNASSIGNED_CUSTOMERS_START: {
      return {
        ...state,
        unassignedCustomers: null,
        fetchingUnassignedCustomers: true,
        unassignedCustomersError: null,
      };
    }
    case types.GET_UNASSIGNED_CUSTOMERS_SUCCESS: {
      return {
        ...state,
        unassignedCustomers: action.payload,
        fetchingUnassignedCustomers: false,
        unassignedCustomersError: null,
      };
    }
    case types.GET_UNASSIGNED_CUSTOMERS_ERROR: {
      return {
        ...state,
        unassignedCustomers: null,
        fetchingUnassignedCustomers: false,
        unassignedCustomersError: action.payload,
      };
    }
    case types.RESET_STATE: {
      return {
        userList: null,
        loadingUserList: false,
        userListError: null,

        newUser: null,
        createUserError: null,
        creatingNewUser: false,

        userDetails: null,
        gettingUserDetails: false,
        userDetailsError: null,
      };
    }
    case types.GET_USER_DETAILS_START: {
      return {
        ...state,
        userDetails: null,
        gettingUserDetails: true,
        userDetailsError: null,
      };
    }
    case types.GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        userDetails: action.payload,
        gettingUserDetails: false,
        userDetailsError: null,
      };
    }
    case types.GET_USER_DETAILS_ERROR: {
      return {
        ...state,
        userDetails: null,
        gettingUserDetails: false,
        userDetailsError: action.payload,
      };
    }
    case types.CREATE_USER_START: {
      return {
        ...state,
        newUser: null,
        createUserError: null,
        creatingNewUser: true,
      };
    }
    case types.CREATE_USER_SUCCESS: {
      return {
        ...state,
        newUser: action.payload,
        creatingNewUser: false,
      };
    }
    case types.CREATE_USER_ERROR: {
      return {
        ...state,
        createUserError: action.payload,
        creatingNewUser: false,
      };
    }
    case types.GET_USER_LIST_START: {
      return {
        ...state,
        userList: null,
        loadingUserList: true,
        userListError: null,
      };
    }
    case types.GET_USER_LIST_SUCCESS: {
      return {
        ...state,
        userList: action.payload,
        loadingUserList: false,
      };
    }
    case types.GET_USER_LIST_ERROR: {
      return {
        ...state,
        loadingUserList: false,
        userListError: action.payload,
      };
    }

    // USER DELETE
    case types.SET_USER_FOR_DELETE: {
      return {
        ...state,
        userToDelete: action.payload,
      };
    }
    case types.USER_DELETE_START: {
      return {
        ...state,
        userDelete: null,
        userDeleteError: null,
        userDeleteState: true,
      };
    }
    case types.USER_DELETE_SUCCESS: {
      return {
        ...state,
        userDelete: action.payload,
        userDeleteState: false,
      };
    }
    case types.USER_DELETE_ERROR: {
      return {
        ...state,
        userDeleteError: action.payload,
        userDeleteState: false,
      };
    }
    case types.USER_DELETE_STATE_RESET: {
      return {
        ...state,
        userDelete: null,
        userDeleteState: false,
        deleteUserError: null,
      };
    }
    //USER_BULK_DELETE
    case types.SET_USERS_FOR_BULK_DELETE: {
      return {
        ...state,
        BulkUserToDelete: action.payload
      };
    }
    case types.USER_BULK_DELETE_START: {
      return {
        ...state,
        BulkUserDelete: null,
        BulkUserDeleteState: true,
        BulkUserDeleteError: null,
      };
    }
    case types.USER_BULK_DELETE_SUCCESS: {
      return {
        ...state,
        BulkUserDelete: action.payload,
        BulkUserDeleteState: false,
      };
    }
    case types.USER_BULK_DELETE_ERROR:{
      return{
        BulkUserDeleteError: action.payload,
        BulkUserDeleteState: false,
      }
    }
    case types.USER_DELETE_STATE_BULK_RESET: {
      return {
        ...state,
        BulkUserDelete: null,
        BulkUserDeleteState: false,
        BulkUserDeleteError: null,
      };
    }



    //search

    case types.SEARCH_USER_START: {
      return {
        ...state,
        search: null,
        searchError: null,
        searchLoading: true,
      };
    }
    case types.SEARCH_USER_ERROR: {
      return {
        ...state,
        searchError: action.payload,
        searchLoading: false,
      };
    }
    case types.SEARCH_USER_SUCCESS: {
      return {
        ...state,
        search: action.payload,
        searchLoading: false,
      };
    }
    case types.USER_SEARCH_RESET: {
      return {
        ...state,
        search: null,
        searchError: null,
        searchLoading: false,
      };
    }

    // USER UPDATE
    case types.SET_USER_FOR_UPDATE: {
      return {
        ...state,
        userToUpdate: action.payload,
      };
    }
    case types.USER_UPDATE_START: {
      return {
        ...state,
        userUpdate: null,
        userUpdateError: null,
        userUpdateState: true,
      };
    }
    case types.USER_UPDATE_SUCCESS: {
      return {
        ...state,
        userUpdate: action.payload,
        userUpdateState: false,
      };
    }
    case types.USER_UPDATE_ERROR: {
      return {
        ...state,
        userUpdateError: action.payload,
        userUpdateState: false,
      };
    }
    case types.USER_UPDATE_STATE_RESET: {
      return {
        ...state,
        userUpdate: null,
        userUpdateState: false,
        userUpdateError: null,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default userReducer;
