import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";

/**
 * API CALL FOR PROJECT LIST START
 * */
function* getProjectListStartHandlerSaga(action){
    const apiClient = apiClientFactory();
    const { page, size } = action.payload;
    try {
        const { data } = yield call(
            apiClient.get,
            endpoints.projectList(page, size),
        );
        yield put({
            type: types.GET_PROJECT_LIST_SUCCESS,
            payload: data.response,
        });
    } catch (e) {
        yield put({
            type: types.GET_PROJECT_LIST_ERROR,
            payload: e.response.data,
        });
    }
}
export function* getProjectListStartWatcherSaga(){
    yield takeEvery(
        types.GET_PROJECT_LIST_START,
        getProjectListStartHandlerSaga,
    )
}
/**
 * API CALL FOR PROJECT LIST END
 * */


/**
 * API CALL FOR PROJECT CREATE START
 * */
function* createProjectStartHandlerSaga(action){
    const apiClient = apiClientFactory();
    try{
        const { data } = yield call(
            apiClient.post,
            endpoints.projectCreate,
            action.payload
        )
        yield put({
            type: types.CREATE_PROJECT_SUCCESS,
            payload: data?.response
        })
    }catch(e){
        yield put({
            type: types.CREATE_PROJECT_ERROR,
            payload: e?.response?.data || e
        })
    }
}
export function* createProjectStartWatcherSaga(){
    yield takeEvery(
        types.CREATE_PROJECT_START,
        createProjectStartHandlerSaga
    )
}
/**
 * API CALL FOR PROJECT CREATE END
 * */

/**
 * API CALL FOR PROJECT EDIT START
 * */
function* editProjectStartHandlerSaga(action){
    const apiClient = apiClientFactory();
    try{
        const { data } = yield call(
            apiClient.put,
            endpoints.projectUpdate,
            action.payload,
        )
        yield put({
            type: types.EDIT_PROJECT_SUCCESS,
            payload: data?.response
        })
    }catch(e){
        yield put({
            type: types.EDIT_PROJECT_ERROR,
            payload: e?.response?.data || e
        })
    }
}
export function* editProjectStartWatcherSaga(){
    yield takeEvery(
        types.EDIT_PROJECT_START,
        editProjectStartHandlerSaga,
    )
}

//Api call for delete project

function* deleteProjectHandlerSaga(action) {
    const apiClient = apiClientFactory();
    try {
      const { data } = yield call(
        apiClient.delete,
        endpoints.deleteProject(action.payload)
      );
      yield put({
        type: types.DELETE_PROJECT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      yield put({
        type: types.DELETE_PROJECT_ERROR,
        payload: e.response.data,
      });
    }
  }
  
  export function* deleteProjectWatcherSaga() {
    yield takeLatest(types.DELETE_PROJECT_START, deleteProjectHandlerSaga);
  }
/**
 * API CALL FOR PROJECT EDIT START
 * */