import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";

function* assignSubAdminStartHandlerSaga(action){
    const apiClient = apiClientFactory()
    try{
      const { data } = yield call(
          apiClient.post,
          endpoints.assignSubAdmin,
          action.payload,
      );
      yield put({
        type: types.ASSIGN_SUB_ADMIN_SUCCESS,
        payload: data?.response
      })
    } catch(e){
      yield put({
        type: types.ASSIGN_SUB_ADMIN_ERROR,
        payload: e?.response?.data || e,
      })
    }
}
export function* assignSubAdminStartWatcherSaga(){
  yield takeEvery(
      types.ASSIGN_SUB_ADMIN_START,
      assignSubAdminStartHandlerSaga,
  )
}

function* assignManagerStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.post,
      endpoints.assignManager,
      action.payload
    );
    yield put({
      type: types.ASSIGN_MANAGER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.ASSIGN_MANAGER_ERROR,
      payload,
    });
  }
}
export function* assignManagerStartWatcherSaga() {
  yield takeEvery(types.ASSIGN_MANAGER_START, assignManagerStartHandlerSaga);
}

function* getUnassignedCustomersStartHandlerSaga(action) {
  const apiClient = apiClientFactory();;
  try {
    const { data } = yield call(apiClient.get, endpoints.unassignedCustomers(action.payload));
    yield put({
      type: types.GET_UNASSIGNED_CUSTOMERS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.GET_UNASSIGNED_CUSTOMERS_ERROR,
      payload,
    });
  }
}
export function* getUnassignedCustomersStartWatcherSaga() {
  yield takeEvery(
    types.GET_UNASSIGNED_CUSTOMERS_START,
    getUnassignedCustomersStartHandlerSaga
  );
}
function* getUserListStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.get,
      endpoints.userList(action.payload)
    );
    yield put({
      type: types.GET_USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.GET_USER_LIST_ERROR,
      payload,
    });
  }
}

export function* getUserListStartWatcherSaga() {
  yield takeEvery(types.GET_USER_LIST_START, getUserListStartHandlerSaga);
}

function* createNewUserStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.post,
      endpoints.createUser,
      action.payload
    );
    yield put({
      type: types.CREATE_USER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.CREATE_USER_ERROR,
      payload,
    });
  }
}

export function* createNewUserStartWatcherSaga() {
  yield takeEvery(types.CREATE_USER_START, createNewUserStartHandlerSaga);
}

function* getUserDetailsStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.get,
      endpoints.userDetails(action.payload)
    );
    yield put({
      type: types.GET_USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.GET_USER_DETAILS_ERROR,
      payload,
    });
  }
}
export function* getUserDetailsStartWatcherSaga() {
  yield takeEvery(types.GET_USER_DETAILS_START, getUserDetailsStartHandlerSaga);
}

// USER DELETE

function* deleteNewUserStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.delete,
      endpoints.userDelete(action.payload)
    );
    yield put({
      type: types.USER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.USER_DELETE_ERROR,
      payload,
    });
  }
}

export function* deleteNewUserStartWatcherSaga() {
  yield takeEvery(types.USER_DELETE_START, deleteNewUserStartHandlerSaga);
}

//Bulk User Delete

function* deleteUsersBulkStartHandelerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.post,
      endpoints.bulkUserDelete,
      action.payload
    );
    console.log(data, "saga bulk delete build");
    yield put({
      type: types.USER_BULK_DELETE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.USER_BULK_DELETE_ERROR,
      payload,
    });
  }
}
export function* deleteUsersBulkdeleteWatcherSaga() {
  yield takeEvery(types.USER_BULK_DELETE_START, deleteUsersBulkStartHandelerSaga);
}

//search Items

function* searchItemStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.get,
      endpoints.searchUser(action.payload)
    );
    yield put({ type: types.SEARCH_USER_SUCCESS, payload: data.response });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({ type: types.SEARCH_USER_ERROR, payload });
  }
}

export function* userSearchStartWatcherSaga() {
  yield takeEvery(types.SEARCH_USER_START, searchItemStartHandlerSaga);
}

// USER UPDATE

function* updateNewUserStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.put,
      endpoints.userUpdate,
      action.payload
    );
    yield put({
      type: types.USER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.USER_UPDATE_ERROR,
      payload,
    });
  }
}

export function* updateNewUserStartWatcherSaga() {
  yield takeEvery(types.USER_UPDATE_START, updateNewUserStartHandlerSaga);
}
