const types = {
  GET_VILLA_LIST_START: "GET_VILLA_LIST_START",
  GET_VILLA_LIST_SUCCESS: "GET_VILLA_LIST_SUCCESS",
  GET_VILLA_LIST_ERROR: "GET_VILLA_LIST_ERROR",
  //create update
  CREATE_VILLA_START: "CREATE_VILLA_START",
  CREATE_VILLA_SUCCESS: "CREATE_VILLA_SUCCESS",
  CREATE_VILLA_ERROR: "CREATE_VILLA_ERROR",
  CREATE_VILLA_STATE_RESET: "CREATE_VILLA_STATE_RESET",

  //update villa
  UPDATE_VILLA_START: "UPDATE_VILLA_START",
  UPDATE_VILLA_SUCCESS: "UPDATE_VILLA_SUCCESS",
  UPDATE_VILLA_ERROR: "UPDATE_VILLA_ERROR",
  UPDATE_VILLA_STATE_RESET: "UPDATE_VILLA_STATE_RESET",

  //delete villa
  DELETE_VILLA_START: "DELETE_VILLA_START",
  DELETE_VILLA_SUCCESS: "DELETE_VILLA_SUCCESS",
  DELETE_VILLA_ERROR: "DELETE_VILLA_ERROR",
  DELETE_VILLA_STATE_RESET: "DELETE_VILLA_STATE_RESET",

  //bulk delete
  DELETE_BULK_VILLA_START: "DELETE_BULK_VILLA_START",
  DELETE_BULK_VILLA_SUCCESS: "DELETE_BULK_VILLA_SUCCESS",
  DELETE_BULK_VILLA_ERROR: "DELETE_BULK_VILLA_ERROR",
  DELETE_BULK_VILLA_STATE_RESET: "DELETE_BULK_VILLA_STATE_RESET",

  //sselect single item
  GET_SINGLE_VILLA_START: "GET_SINGLE_VILLA_START",
  GET_SINGLE_VILLA_SUCCESS: "GET_SINGLE_VILLA_SUCCESS",
  GET_SINGLE_VILLA_ERROR: "GET_SINGLE_VILLA_ERROR",
  //search
  SEARCH_VILLA_START: "SEARCH_VILLA_START",
  SEARCH_VILLA_SUCCESS: "SEARCH_VILLA_SUCCESS",
  SEARCH_VILLA_ERROR: "SEARCH_VILLA_ERROR",
  VILLA_SEARCH_RESET: "VILLA_SEARCH_RESET",

  VILLA_ASSIGN_START: "VILLA_ASSIGN_START",
  VILLA_ASSIGN_SUCCESS: "VILLA_ASSIGN_SUCCESS",
  VILLA_ASSIGN_ERROR: "VILLA_ASSIGN_ERROR",
  VILLA_ASSIGN_RESET: "VILLA_ASSIGN_RESET",

  VILLA_UNASSIGN_RESIDENT_START: "VILLA_UNASSIGN_RESIDENT_START",
  VILLA_UNASSIGN_RESIDENT_SUCCESS: "VILLA_UNASSIGN_RESIDENT_SUCCESS",
  VILLA_UNASSIGN_RESIDENT_ERROR: "VILLA_UNASSIGN_RESIDENT_ERROR",
  VILLA_UNASSIGN_RESIDENT_RESET: "VILLA_UNASSIGN_RESIDENT_RESET",
};

export default types;
