const types = {
	GET_OFFER_LIST_START: "GET_OFFER_LIST_START",
	GET_OFFER_LIST_SUCCESS: "GET_OFFER_LIST_SUCCESS",
	GET_OFFER_LIST_ERROR: "GET_OFFER_LIST_ERROR",

	//create offer
	CREATE_OFFER_START: "CREATE_OFFER_START",
	CREATE_OFFER_SUCCESS: "CREATE_OFFER_SUCCESS",
	CREATE_OFFER_ERROR: "CREATE_OFFER_ERROR",
	RESET_CREATE_OFFER: "RESET_CREATE_OFFER",

	//update offer
	UPDATE_OFFER_START: "UPDATE_OFFER_START",
	UPDATE_OFFER_SUCCESS: "UPDATE_OFFER_SUCCESS",
	UPDATE_OFFER_ERROR: "UPDATE_OFFER_ERROR",
	RESET_UPDATE_OFFER: "RESET_UPDATE_OFFER",

	DELETE_OFFER_START: "DELETE_OFFER_START",
	DELETE_OFFER_SUCCESS: "DELETE_OFFER_SUCCESS",
	DELETE_OFFER_ERROR: "DELETE_OFFER_ERROR",
	RESET_DELETE_OFFER: "RESET_DELETE_OFFER",

	//bulk
	BULK_DELETE_OFFER_START: "BULK_DELETE_OFFER_START",
	BULK_DELETE_OFFER_SUCCESS: "BULK_DELETE_OFFER_SUCCESS",
	BULK_DELETE_OFFER_ERROR: "BULK_DELETE_OFFER_ERROR",
	BULK_RESET_DELETE_OFFER: "BULK_RESET_DELETE_OFFER",

	//Single Offer

	GET_SINGLE_OFFER: "GET_SINGLE_OFFER",

	//search
	SEARCH_OFFER_START: "SEARCH_OFFER_START",
	SEARCH_OFFER_SUCCESS: "SEARCH_OFFER_SUCCESS",
	SEARCH_OFFER_ERROR: "SEARCH_OFFER_ERROR",
	OFFER_SEARCH_RESET: "OFFER_SEARCH_RESET",
};

export default types;
