import types from "./types";

export const getOfferListStart = (payload) => ({
	type: types.GET_OFFER_LIST_START,
	payload,
});

export const createOfferStart = (payload) => ({
	type: types.CREATE_OFFER_START,
	payload,
});

export const resetCreateOffer = () => ({
	type: types.RESET_CREATE_OFFER,
});

export const updateOfferStart = (payload) => ({
	type: types.UPDATE_OFFER_START,
	payload,
});

export const resetUpdateOffer = () => ({
	type: types.RESET_UPDATE_OFFER,
});
//delete
export const deleteOfferStart = (payload) => ({
	type: types.DELETE_OFFER_START,
	payload,
});

export const deleteOfferreset = () => ({
	type: types.RESET_DELETE_OFFER,
});

//bulk
export const bulkDeleteOfferStart = (payload) => ({
	type: types.BULK_DELETE_OFFER_START,
	payload,
});

export const bulkDeleteOfferreset = () => ({
	type: types.BULK_RESET_DELETE_OFFER,
});

export const singleOfferState = (payload) => ({
	type: types.GET_SINGLE_OFFER,
	payload,
});

export const searchofferStart = (payload) => ({
	type: types.SEARCH_OFFER_START,
	payload,
});

export const searchofferReset = () => ({
	type: types.OFFER_SEARCH_RESET,
});
