import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";
function* getSingleHousingSaga() {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(apiClient.get, endpoints.housingComplex);
    yield put({
      type: types.GET_HOUSING_SINGLE_ITEM_SUCCESS,
      payload: data.response,
    });
  } catch (e) {
    yield put({
      type: types.GET_HOUSING_SINGLE_ITEM_ERROR,
      payload: e,
    });
  }
}

export function* getSingleHousingWatcherSaga() {
  yield takeEvery(types.GET_HOUSING_SINGLE_ITEM_START, getSingleHousingSaga);
}
