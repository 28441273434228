import React from "react";
import styles from "./css/style.module.css";

const Notification = (props) => {
	return (
		<div className={styles.notification}>
			<div className={styles.header}>{props.title}</div>
			<div>{props.children}</div>
			<p className={styles.footer}>{props.footer}</p>
		</div>
	);
};

Notification.Item = (props) => {
	return <div className={styles.item}>{props.children}</div>;
};

Notification.Icon = (props) => {
	return (
		<div className={styles.iconContainer}>
			<img className={styles.icon} src={props.icon} alt='icon' />
		</div>
	);
};

Notification.Message = (props) => {
	return <div className={styles.message}>{props.children}</div>;
};

export default Notification;
