const types = {
  GENERATE_GUEST_JWT_START: "GENERATE_GUEST_JWT_START",
  GENERATE_GUEST_JWT_SUCCESS: "GENERATE_GUEST_JWT_SUCCESS",
  GENERATE_GUEST_JWT_ERROR: "GENERATE_GUEST_JWT_ERROR",

  STAFF_LOGIN_START: "STAFF_LOGIN_START",
  STAFF_LOGIN_SUCCESS: "STAFF_LOGIN_SUCCESS",
  STAFF_LOGIN_ERROR: "STAFF_LOGIN_ERROR",

  STAFF_LOGOUT: "STAFF_LOGOUT",
};
export default types;
