import styled from "styled-components";

export const FormInput = styled.input`
  border: 1px solid #1b1b1e;
  padding: 0.6em 0.5em;
  border-radius: 5px;
  outline: none;
  width: ${(props) => props.width || "auto"};
`;
export const FormLabel = styled.h5`
  padding: 0.6em 0.3em;
  margin: 0;
  text-align: left;
  width: 85%;
`;
export const SelectInput = styled.select`
  border: 1px solid #1b1b1e;
  background-color: #ffff;
  padding: 0.6em 0.5em;
  border-radius: 5px;
  outline: none;
  width: ${(props) => props.width || "auto"};
`;
