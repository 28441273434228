import React from "react";
import style from "./css/style.module.css";
import { usePagination, DOTS } from "../../util/usePagination";
import { MdArrowBackIosNew } from "react-icons/md";

const Pagination = (props) => {
	const {
		onPageChange,
		totalCount,
		siblingCount = 1,
		currentPage,
		pageSize,
		className,
	} = props;

	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	let lastPage = paginationRange[paginationRange.length - 1];

	return (
		<div
			className={`${style.container} ${className}
            `}>
			<div className={style.pageContainer}>
				<MdArrowBackIosNew
					className={style.btn}
					onClick={currentPage === 1 ? () => {} : onPrevious}
				/>
				<div style={{ display: "flex" }}>
					{paginationRange?.map((pageNumber, index) => {
						if (pageNumber === DOTS) {
							return <span>$#8230;</span>;
						}
						return (
							<span
								key={index}
								className={style.page}
								onClick={() => onPageChange(pageNumber)}>
								{pageNumber}
							</span>
						);
					})}
				</div>
				<MdArrowBackIosNew
					onClick={lastPage !== currentPage ? onNext : () => {}}
					className={`${style.btn} ${style.btnfwd}`}
				/>
			</div>
		</div>
	);
};

Pagination.defaultProps = {
	length: 5,
	className: "",
};

export default Pagination;
