import types from "./types";

const INITIAL_STATE = {
  singleUploadedImage: null,
  singleImageUpdateError: null,
  uploadingSingleImage: false,
};

const imageUploadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SINGLE_IMAGE_UPLOAD_START: {
      return {
        ...state,
        singleUploadedImage: null,
        singleImageUpdateError: null,
        uploadingSingleImage: true,
      };
    }
    case types.SINGLE_IMAGE_UPLOAD_SUCCESS: {
      return {
        ...state,
        singleUploadedImage: action.payload,
        singleImageUpdateError: null,
        uploadingSingleImage: false,
      };
    }
    case types.SINGLE_IMAGE_UPLOAD_ERROR: {
      return {
        ...state,
        singleUploadedImage: null,
        singleImageUpdateError: action.payload,
        uploadingSingleImage: false,
      };
    }
    case types.SINGLE_IMAGE_UPLOAD_STATE_RESET: {
      return {
        ...state,
        singleUploadedImage: null,
        singleImageUpdateError: null,
        uploadingSingleImage: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default imageUploadReducer;
