import types from "./types";


const INITIAL_STATE = {
    sentNotificationsInfo: null,
    sendingNotifications: false,
    sendNotificationError: null,

}


const notificationReducer = (state = INITIAL_STATE, action) => {

    switch(action.type){
        case types.SEND_BULK_NOTIFICATIONS_START : {
            return {
                ...state,
                sentNotificationsInfo: null,
                sendingNotifications: true,
                sendNotificationError: null,
            }
        }
        case types.SEND_BULK_NOTIFICATIONS_SUCCESS : {
            return {
                ...state,
                sentNotificationsInfo: action.payload,
                sendingNotifications: false,
                sendNotificationError: null,
            }
        }
        case types.SEND_BULK_NOTIFICATIONS_ERROR : {
            return {
                ...state,
                sentNotificationsInfo: null,
                sendingNotifications: false,
                sendNotificationError: action.payload,
            }
        }
        case types.SEND_BULK_NOTIFICATIONS_STATE_RESET : {
            return {
                ...state,
                sentNotificationsInfo: null,
                sendingNotifications: false,
                sendNotificationError: null,
            }
        }

        default: {
            return {
                ...state,
            }
        }
    }

}


export default notificationReducer;