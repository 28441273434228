import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";

function* getCouponHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endpoints.getCoupon(action.payload),
		);
		yield put({
			type: types.GET_COUPON_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		yield put({
			type: types.GET_COUPON_ERROR,
			payload: e.response.data,
		});
	}
}

export function* getCouponWatcherSaga() {
	yield takeEvery(types.GET_COUPON_START, getCouponHandlerSaga);
}

//create coupon

function* createCouponHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.post,
			endpoints.createCoupon,
			action.payload,
		);
		yield put({
			type: types.CREATE_COUPON_SUCCESS,
			payload: data,
		});
	} catch (e) {
		yield put({
			type: types.CREATE_COUPON_ERROR,
			payload: e.response.data,
		});
	}
}

export function* createCouponWatcherSaga() {
	yield takeEvery(types.CREATE_COUPON_START, createCouponHandlerSaga);
}

//update offre
function* updateCouponHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.put,
			endpoints.updateCoupon,
			action.payload,
		);
		yield put({
			type: types.UPDATE_COUPON_SUCCESS,
			payload: data,
		});
	} catch (e) {
		yield put({
			type: types.UPDATE_COUPON_ERROR,
			payload: e.response.data,
		});
	}
}

export function* updateCouponWatcherSaga() {
	yield takeEvery(types.UPDATE_COUPON_START, updateCouponHandlerSaga);
}

function* deleteCouponHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endpoints.deleteCoupon(action.payload),
		);
		yield put({
			type: types.DELETE_COUPON_SUCCESS,
			payload: data,
		});
	} catch (e) {
		yield put({
			type: types.DELETE_COUPON_ERROR,
			payload: e.response.data,
		});
	}
}

export function* deleteCouponWatcherSaga() {
	yield takeEvery(types.DELETE_COUPON_START, deleteCouponHandlerSaga);
}
