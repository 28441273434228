import React, { useEffect, useState, useMemo } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  Table,
  Modal,
  Button,
  Loading,
  BreadCumb,
  SearchBox,
  LoadingButton,
} from "../../Components";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { Link } from "react-router-dom";
import style from "./css/style.module.css";
import Pagination from "../../ui-elements/Pagination";

// importing actions
import {
  getOfferListStart,
  createOfferStart,
  resetCreateOffer,
  updateOfferStart,
  resetUpdateOffer,
  deleteOfferStart,
  deleteOfferreset,
  bulkDeleteOfferStart,
  bulkDeleteOfferreset,
  singleOfferState,
  searchofferReset,
  searchofferStart,
} from "../../store/Offer/actions";
import {
  singleImageUploadStart,
  singleImageUploadStateReset,
} from "../../store/ImageUpload/actions";

// importing selectors
import {
  selectOfferList,
  selectOfferLoading,
  selectOfferError,
  selectCreateOffer,
  selectCreateOfferLoading,
  selectCreateOfferError,
  selectUpdateOfferError,
  selectUpdateOfferLoading,
  selectUpdateOffer,
  selectdeleteOfferError,
  selectdeleteOffer,
  selectdeleteOfferLoading,
  selectBulkdeleteOffer,
  selectBulkdeleteOfferError,
  selectBulkdeleteOfferLoading,
  selectSearchStart,
} from "../../store/Offer/selectors";
import { toast } from "react-toastify";
import { InfinitySpin } from "react-loader-spinner";
import { PrimaryButton } from "../../ui-elements/Button";

let deleteItem = 0;
let bulkDeleteItem = 0;
const Offer = ({
  // actions
  getOffers,
  createOffer,
  resetCreateOffer,
  updateOffer,
  resetUpdateOffer,
  resetDelete,
  deleteOffer,
  bulkDeleteOfferStart,
  bulkResetDelete,
  SingleOffer,
  searchOffer,
  resetSearch,

  // selectors

  list,
  error,
  loading,
  createError,
  create,
  createLoading,
  updateError,
  updateOfferSuccess,
  updateLoading,
  deleteError,
  selectDelete,
  selectAllDelete,
  BulkOfferToDelete,
  bulkDeleteError,
  selectbulkDelete,
  bulkDeleteLoading,
  Search,
  deleteLoading,
}) => {
  const dispatch = useDispatch();
  const { selected_project } = useSelector(state => state.project);
  const selectedProject = useMemo(() => selected_project, [selected_project]);
  const imageUpload = useSelector((state) => state.imageUpload);
  const { singleUploadedImage, singleImageUpdateError, uploadingSingleImage } =
    imageUpload;
  const { searchMain, searchError, searchLoading } = Search;
  const [modal2, setModal2] = useState(true);
  const [modal3, setModal3] = useState(true);
  const [data, setData] = useState([]);
  const [toDelete, setToDelete] = useState("");
  const [tobulkDelete, setToBulkDelete] = useState("");
  const bulkDeleteOffer = useSelector(state => state.offer.bulkDeleteOffer);
  const bulkDeleteingOffer = useSelector(state => state.offer.bulkDeleteingOffer);

  const [modal, setModal] = useState(true);
  const [update, setUpdate] = useState(false); //for toggling between update and add
  const [form, setform] = useState({
    hero_image: "",
    title: "",
    description: "",
    discount: "",
    coupon_code: "",
  });
  const [pagination, setPagination] = useState({
    page: 0,
    showing: 0,
    total: 0,
  });
  const [currentPage, setCurrentPage] = useState();
  const [imageFile, setImageFile] = useState(null);



  /**
   * CHECK BOX RELATED FUNCTIONS
   * Date: 01-12-2022
   * */
  const [checkedItems, setCheckedItems] = useState([]);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const onCheckBoxClick = (e, position) => {
    if (e.target.checked) {
      setItemsToDelete(prevItems => [...prevItems, parseInt(e.target.id)]);
    } else {
      const index = itemsToDelete?.findIndex(_b => _b === parseInt(e.target.id));
      if (index > -1) {
        const tempItemsToDelete = Object.assign([], itemsToDelete);
        tempItemsToDelete.splice(index, 1)
        setItemsToDelete(tempItemsToDelete);
      }
    }
    const updatedCheckedState = checkedItems.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedItems(updatedCheckedState);
  }
  const onSelectAllCheckBoxClick = e => {
    if (e.target.checked) {
      setItemsToDelete(data?.map(element => element.id));
    } else {
      setItemsToDelete([]);
    }
    const updatedCheckedState = checkedItems.map((item, index) =>
      e.target.checked ? true : false
    );
    setCheckedItems(updatedCheckedState);
  }
  const bulkDeleteCheckedAndItemsReset = () => {
    setCheckedItems(new Array(searchMain?.data?.length).fill(false));
    setItemsToDelete([]);
  }
  /**
   * ****************************
   * */




  //for Count
  const [totalSelectedCheckboxes, setTotalSelectedCheckboxes] = useState(0);
  function handleChk() {
    setTotalSelectedCheckboxes(document.querySelectorAll('input[type=checkbox]:checked').length);
  }

  useEffect(() => {
    window.localStorage.removeItem("filterOptions");
    console.log(totalSelectedCheckboxes);
  }, [totalSelectedCheckboxes]);

  const [isCheck, setIsCheck] = useState([]);

  const formSubmit = (e) => {
    e.preventDefault();
    dispatch(singleImageUploadStateReset());

    if (update) {
      updateOffer({ ...form, id: update?.id, projectId: selectedProject?.id });
    } else {
      createOffer({ ...form, projectId: selectedProject?.id });
    }
  };

  const inputHandler = (e) => {
    setform((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  //set  offer list from
  useEffect(() => {
    if (list !== null) {
      setData(list.data);
      setPagination({
        page: list.page,
        showing: list.showing,
        total: list.total,
      });
      setCheckedItems(new Array(list?.data?.length).fill(false))
    }
  }, [list]);

  // WHEN SINGLE IMAGE IS UPLOADED SUCCESSFULLY
  useEffect(() => {
    if (singleUploadedImage) {
      toast.success("Offer Image Uploaded Successfully");
      setform((prevState) => ({
        ...prevState,
        hero_image: singleUploadedImage?.response?.files[0]?.path,
      }));
    }
  }, [singleUploadedImage]);

  // WHEN IMAGE UPLOADING FAILS
  useEffect(() => {
    if (singleImageUpdateError?.data) {
      toast.error(singleImageUpdateError?.data?.message);
    }
  }, [singleImageUpdateError]);

  //getting offer
  useEffect(() => {
    dispatch(singleImageUploadStateReset());
    getOffers({ page: 0, size: process.env.REACT_APP_DEFAULT_PAGE_SIZE, projectId: selectedProject?.id });
  }, []);

  //for delete
  const onDelete = () => {
    //delete item with deleteItem

    deleteOffer(deleteItem);
  };

  //for bulk  delete
  const onBulkDelete = () => {
    bulkDeleteOfferStart(itemsToDelete);
  };

  //search handler
  useEffect(() => {
    if (searchMain !== null) {
      if (searchMain.data.length > 0) {
        setData(searchMain.data);
        setPagination({
          page: searchMain.page,
          showing: searchMain.showing,
          total: searchMain.total,
        });
      }
      if (searchMain.data.length === 0) {
        setData([]);
        setPagination({
          page: list.page,
          showing: list.showing,
          total: list.total,
        });
      }
    }
  }, [searchMain]);


  useEffect(() => {
    if (createError) {
      if (createError?.response) {
        toast.error(createError.response);
      } else if (Array.isArray(createError.response)) {
        createError.response?.forEach((err) => {
          const keys = Object.keys(createError.response);
          keys.forEach((key) => {
            toast.error(err[key]);
          });
        });
      } else {
        toast.error(createError?.message);
      }
      // resetCreateOffer();
    }
    if (updateError) {
      if (typeof updateError?.response === "string") {
        toast.error(updateError?.response);
      } else if (Array.isArray(updateError?.response)) {
        updateError?.response?.forEach((err) => {
          const keys = Object.keys(updateError?.response);
          keys.forEach((key) => {
            toast.error(err[key]);
          });
        });
      } else {
        toast.error(updateError?.message);
      }
      resetUpdateOffer();
    }
    if (deleteError) {
      toast.error(deleteError.message);
      resetDelete();
    }
    if (bulkDeleteError) {
      toast.error(bulkDeleteError.message);
      bulkResetDelete();
    }
    if (searchError) {
      toast.error(searchError.message);
      resetSearch();
    }
  }, [createError, updateError, deleteError, bulkDeleteError]);

  useEffect(() => {
    if (create?.success) {
      toast.success("Offer Added");
      getOffers({ page: 0, size: process.env.REACT_APP_DEFAULT_PAGE_SIZE, projectId: selectedProject?.id });
      resetCreateOffer();
      dispatch(singleImageUploadStateReset());
      setModal(true);
    }
    if (selectDelete?.success) {
      toast.success("Offer deleted");
      if (list?.data?.length > 1) {
        getOffers({
          page: currentPage,
          size: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
          projectId: selectedProject?.id
        });
      } else {
        getOffers({
          page: currentPage - 1,
          size: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
          projectId: selectedProject?.id
        });
      }
      resetDelete();
      setModal2(true);
      bulkDeleteCheckedAndItemsReset();
    }
    if (bulkDeleteOffer) {
      toast.success("All Offer deleted");
      if (list?.data?.length > 1) {
        getOffers({
          page: currentPage,
          size: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
          projectId: selectedProject?.id
        });
      } else {
        getOffers({
          page: currentPage - 1,
          size: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
          projectId: selectedProject?.id
        });
      }
      bulkDeleteCheckedAndItemsReset();
      bulkResetDelete();
      setModal3(!modal3);
    }
    if (updateOfferSuccess) {
      toast.success("offer updated");
      resetUpdateOffer();
      dispatch(singleImageUploadStateReset());
      setModal(true);
    }
  }, [create, selectDelete, bulkDeleteOffer, updateOfferSuccess]);

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <BreadCumb>
          <BreadCumb.Item href="/dashboard">Dashboard</BreadCumb.Item>
          <BreadCumb.Item>Offers</BreadCumb.Item>
        </BreadCumb>

        <div className="search-container">
          <SearchBox tosearch={searchOffer} style={{ width: "50%" }} />
          <Button
            onClick={() => {
              setModal(!modal);
              setform({
                hero_image: "",
                title: "",
                description: "",
                discount: "",
                coupon_code: "",
              });
              setUpdate(false);
            }}
          >
            Create New Offer
          </Button>
        </div>

        <Modal
          open={modal2}
          setOpen={setModal2}
          onClose={() => {
            setform({
              hero_image: "",
              title: "",
              description: "",
              discount: "",
              coupon_code: "",
            });
            dispatch(singleImageUploadStateReset());
          }}
        >
          <p>
            Do you want to delete <strong>{toDelete} ?</strong>
          </p>
          <div className="flex">
            {deleteLoading ? (
              <LoadingButton />
            ) : (
              <button className="btn confirmBtn" onClick={onDelete}>
                Confirm
              </button>
            )}

            <button
              className="btn cancleBtn"
              onClick={() => {
                setModal2(!modal2);
              }}
            >
              Cancel
            </button>
          </div>
        </Modal>
        <Modal
          open={modal3}
          setOpen={setModal3}
          onClose={() => {
            setform({
              hero_image: "",
              title: "",
              description: "",
              discount: "",
              coupon_code: "",
            });
            dispatch(singleImageUploadStateReset());
          }}
        >
          <p>
            Do you want to delete <strong>{tobulkDelete} ?</strong>
          </p>
          <div className="flex">
            {bulkDeleteingOffer ? (
              <LoadingButton />
            ) : (
              <button className="btn confirmBtn"
                onClick={onBulkDelete}

              >
                Confirm
              </button>
            )}

            <button
              className="btn cancleBtn"
              onClick={() => {
                setModal3(!modal3);
              }}
            >
              Cancel
            </button>
          </div>
        </Modal>

        <Modal
          open={modal}
          setOpen={setModal}
          onClose={() => {
            setform({
              hero_image: "",
              title: "",
              description: "",
              discount: "",
              coupon_code: "",
            });
            dispatch(singleImageUploadStateReset());
          }}
        >
          <div
            style={{
              width: "100%",
              height: "30em",
              overflow: "auto",
            }}
          >
            {update ? (
              <p
                style={{ borderBottom: "1px solid #0F4485", display: "block" }}
              >
                Update Offer
              </p>
            ) : (
              <p
                style={{ borderBottom: "1px solid #0F4485", display: "block" }}
              >
                Create Offer
              </p>
            )}

            <form onSubmit={formSubmit}>
              {update && !singleUploadedImage && (
                <img
                  style={{
                    height: "30vh",
                    margin: "0 auto",
                    display: "block",
                  }}
                  src={
                    form?.hero_image?.includes("http")
                      ? form?.hero_image
                      : process.env.REACT_APP_IMAGE_BASE_PATH + form?.hero_image
                  }
                />
              )}
              {uploadingSingleImage && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <InfinitySpin width="200" color="#4fa94d" />
                </div>
              )}
              {singleUploadedImage && (
                <img
                  style={{
                    height: "30vh",
                    margin: "0 auto",
                    display: "block",
                  }}
                  src={
                    process.env.REACT_APP_IMAGE_BASE_PATH +
                    singleUploadedImage?.response?.files[0]?.path
                  }
                />
              )}
              <div className="form_row" style={{ width: "100%" }}>
                <div className="form_column">
                  <label>Image*</label>
                  <input
                    name="hero_image"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        const file = e.target.files[0];
                        setImageFile(file);
                        const multipartFormData = new FormData();
                        multipartFormData.append("image", file);
                        dispatch(singleImageUploadStart(multipartFormData));
                      }
                    }}
                    className="form_input"
                    type="file"
                    placeholder="Profile Picture."
                    accept="image/*"
                  />
                </div>
                <div className="form_column">
                  <label>Title*</label>
                  <input
                    value={form.title}
                    name="title"
                    onChange={inputHandler}
                    className="form_input"
                    type="text"
                    placeholder="What should the offer known as"
                  />
                </div>
                <div className="form_column">
                  <label>Discount*</label>
                  <input
                    value={form.discount}
                    name="discount"
                    onChange={inputHandler}
                    className="form_input"
                    type="text"
                    placeholder="Discount"
                  />
                </div>
                <div className="form_column">
                  <label>Coupon Code</label>
                  <input
                    value={form.coupon_code}
                    name="coupon_code"
                    onChange={inputHandler}
                    className="form_input"
                    type="text"
                    placeholder="Coupon Code"
                  />
                </div>
                <div className="form_column" style={{ flex: "0 100%" }}>
                  <label>Description*</label>
                  <textarea
                    placeholder="Details about the offer"
                    value={form.description}
                    rows={15}
                    name="description"
                    onChange={inputHandler}
                    className="form_input"
                    style={{ height: "200px" }}
                    type="text"
                  ></textarea>
                </div>
              </div>
              {update ? (
                <>
                  {updateLoading || uploadingSingleImage ? (
                    <LoadingButton className="submit_btn" />
                  ) : (
                    <PrimaryButton className="submit_btn">Submit</PrimaryButton>
                  )}
                </>
              ) : uploadingSingleImage ? (
                <LoadingButton className="submit_btn" />
              ) : (
                form?.hero_image &&
                form?.title &&
                form?.description &&
                form?.discount &&
                form?.hero_image && (
                  <PrimaryButton className="submit_btn">Submit</PrimaryButton>
                )
              )}
            </form>
          </div>
        </Modal>
        {data?.length > 0 && (
          <Pagination
            page={list?.page}
            showing={list?.showing}
            total={list?.total}
            onPageChangeHandler={(page, size) =>
              getOffers({ page: page, size: size })
            }
          />
        )}
        <div className="table_container">
          {createLoading || searchLoading ? (
            <Loading />
          ) : (
            <Table>
              <thead>
                {itemsToDelete?.length > 0 && <tr>
                  <th colSpan={7}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}>
                        <input
                          type="checkbox"
                          name="selectAll"
                          id="selectAll"
                          style={{ cursor: 'pointer', transform: 'scale(1.6)' }}
                          onChange={onSelectAllCheckBoxClick}
                          checked={checkedItems?.filter(item => item === true && item)?.length === data?.length}
                        />
                        {
                          checkedItems?.length > 0 && <span
                            style={{
                              marginLeft: '10px',
                              backgroundColor: '#cc9900',
                              color: '#ffff',
                              padding: '0.1em 0.6em'
                            }}
                          >{checkedItems?.filter(item => item === true && item)?.length + ' selected'}
                          </span>}
                      </div>
                      <button
                        onClick={() => {
                          setModal3(!modal3);
                          bulkDeleteItem = data.id;
                          //console.log(data,"ln647");
                          setToBulkDelete(data.title);
                          setCurrentPage(pagination.page);
                        }}
                        class="delete-area">
                        Delete Selected
                        <MdDelete
                          className={"cursor"}
                          fontSize={"1.5em"}
                          color="red"

                        />
                      </button>
                    </div>
                  </th>
                </tr>}
                {/* <tr>
                    {JSON.stringify(checkedItems)}
                    {JSON.stringify(itemsToDelete)}
                  </tr> */}
                <tr>
                  {/* <th>Sl No.</th> */}
                  <th>Select</th>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Description</th>

                  <th>Discount</th>
                  <th>Coupon Code</th>
                  <th>Edit | Delete</th>
                </tr>
              </thead>
              <tbody>
                {data.map((offer, index) => (
                  <tr key={offer.id}>
                    {/* <td>
                      {index +
                        (parseInt(pagination.page) === 0
                          ? 1
                          : parseInt(pagination.page) *
                              process.env.REACT_APP_DEFAULT_PAGE_SIZE +
                            1)}
                    </td> */}
                    <td><input
                      key={offer.id}
                      type="checkbox"
                      style={{ cursor: 'pointer', transform: 'scale(1.6)' }}
                      checked={checkedItems[index]}
                      id={offer.id}
                      onChange={(e) => onCheckBoxClick(e, index)}
                    /></td>
                    <td className={"thumbnailContainer"}>
                      <img
                        className={"thumbnail"}
                        src={
                          offer.hero_image.includes("http")
                            ? offer.hero_image
                            : process.env.REACT_APP_IMAGE_BASE_PATH +
                            offer.hero_image
                        }
                        alt={offer.title}
                      />
                    </td>
                    <td>{offer.title}</td>
                    <td style={{ textAlign: "center", width: "40%" }}>
                      {offer.description.length > 200
                        ? offer?.description?.slice(0, 100) + "..."
                        : offer?.description}
                    </td>

                    <td>{offer.discount}</td>
                    <td>{offer?.coupon_code}</td>
                    <td>
                      {/* <Link to={`/offer/${offer.id}`}>
                        <IoMdEye
                          className="cursor icon-primary"
                          fontSize={"2em"}
                          onClick={() => {
                            addSingleItem(offer.id);
                          }}
                        />
                      </Link> */}
                      <MdEdit
                        className="cursor icon-info"
                        onClick={() => {
                          setform({
                            hero_image: offer?.hero_image,
                            title: offer?.title,
                            description: offer?.description,
                            coupon_code: offer?.coupon_code,
                            discount: offer?.discount,
                          });
                          setUpdate(offer);
                          setModal(false);
                        }}
                        fontSize={"2em"}
                        color="#000"
                      />
                      <MdDelete
                        className="cursor icon-primary"
                        fontSize={"2em"}
                        onClick={() => {
                          setModal2(!modal2);
                          deleteItem = offer.id;
                          setToDelete(offer.title);
                          setCurrentPage(pagination.page);
                        }}
                        color="red"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {data?.length === 0 && (
            <p style={{ textAlign: "center", color: "red" }}>
              No Records Found
            </p>
          )}
        </div>

        {data?.length > 0 && (
          <Pagination
            page={list?.page}
            showing={list?.showing}
            total={list?.total}
            onPageChangeHandler={(page, size) =>
              getOffers({ page: page, size: size })
            }
          />
        )}
        <br />
      </>
    );
  }
};

const mapState = (state) => ({
  list: selectOfferList(state),
  loading: selectOfferLoading(state),
  error: selectOfferError(state),
  create: selectCreateOffer(state),
  createLoading: selectCreateOfferLoading(state),
  createError: selectCreateOfferError(state),
  updateOfferSuccess: selectUpdateOffer(state),
  updateError: selectUpdateOfferError(state),
  updateLoading: selectUpdateOfferLoading(state),
  selectDelete: selectdeleteOffer(state),
  selectAllDelete: selectBulkdeleteOffer(state),
  deleteError: selectdeleteOfferError(state),
  deleteLoading: selectdeleteOfferLoading(state),
  selectbulkDelete: selectBulkdeleteOffer(state),
  bulkDeleteError: selectBulkdeleteOfferError(state),
  bulkDeleteLoading: selectBulkdeleteOfferLoading(state),
  Search: {
    searchMain: selectSearchStart.search(state),
    searchError: selectSearchStart.error(state),
    searchLoading: selectSearchStart.loading(state),
  },
});

const mapDispatch = (dispatch) => ({
  getOffers: (data) => dispatch(getOfferListStart(data)),
  createOffer: (data) => dispatch(createOfferStart(data)),
  resetCreateOffer: () => dispatch(resetCreateOffer()),
  resetUpdateOffer: () => dispatch(resetUpdateOffer()),
  updateOffer: (data) => dispatch(updateOfferStart(data)),
  deleteOffer: (data) => dispatch(deleteOfferStart(data)),
  resetDelete: () => dispatch(deleteOfferreset()),
  bulkDeleteOfferStart: (data) => dispatch(bulkDeleteOfferStart(data)),
  bulkResetDelete: () => dispatch(bulkDeleteOfferreset()),
  SingleOffer: (data) => dispatch(singleOfferState(data)),

  searchOffer: (data) => dispatch(searchofferStart(data)),
  resetSearch: () => dispatch(searchofferReset()),
});

export default connect(mapState, mapDispatch)(Offer);
