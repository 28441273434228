import React from "react";

const Page404 = () => {
	return (
		<div style={{ margin: "5% 35%" }}>
			<p style={{ fontSize: "72px", color: "#0F4485" }}>404 Page</p>
		</div>
	);
};

export default Page404;
