import types from "./types";

const INITIAL_STATE = {
  requestList: null,
  requestListError: null,
  requestListLoading: false,

  //create
  createMaintenanceRequest: null,
  createMaintenanceRequestError: null,
  createMaintenanceRequestLoadig: false,

  //update
  updateMaintenanceRequest: false,
  updateMaintenanceRequestError: null,
  updateMaintenanceRequestLoadig: false,

  //delete
  deleteMaintenance: null,
  deleteMaintenanceRequestError: null,
  deleteMaintenanceRequestLoadig: false,

  //singleItem

  SingleMaintenanceRequest: null,
  singleMaintenanceRequestLoading: false,
  singleMaintenanceRequestError: null,
  //search

  search: null,
  searchError: null,
  searchLoading: false,

  newComment: null,
  addingComment: false,
  newCommentError: null,
};

const maintenanceRequestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.COMMENT_ON_MAINTENANCE_REQUEST_RESET: {
      return {
        ...state,
        newComment: null,
        addingComment: false,
        newCommentError: null,
      };
    }
    case types.COMMENT_ON_MAINTENANCE_REQUEST_START: {
      return {
        ...state,
        newComment: null,
        addingComment: true,
        newCommentError: null,
      };
    }
    case types.COMMENT_ON_MAINTENANCE_REQUEST_SUCCESS: {
      return {
        ...state,
        newComment: action.payload,
        addingComment: false,
        newCommentError: null,
      };
    }
    case types.COMMENT_ON_MAINTENANCE_REQUEST_ERROR: {
      return {
        ...state,
        newComment: null,
        addingComment: false,
        newCommentError: action.payload,
      };
    }
    case types.GET_MAINTENANCE_REQUEST_LIST_START: {
      return {
        ...state,
        requestList: null,
        requestListError: null,
        requestListLoading: true,
      };
    }
    case types.GET_MAINTENANCE_REQUEST_LIST_SUCCESS: {
      return {
        ...state,
        requestList: action.payload,
        requestListLoading: false,
      };
    }
    case types.GET_MAINTENANCE_REQUEST_LIST_ERROR: {
      return {
        ...state,
        requestListError: action.payload,
        requestListLoading: false,
      };
    }
    //create
    case types.CREATE_MAINTENANCE_REQUEST_START: {
      return {
        ...state,

        createMaintenanceRequestError: null,
        createMaintenanceRequestLoadig: true,
      };
    }
    case types.CREATE_MAINTENANCE_REQUEST_SUCCESS: {
      return {
        ...state,
        createMaintenanceRequest: true,
        createMaintenanceRequestLoadig: false,
      };
    }
    case types.CREATE_MAINTENANCE_REQUEST_ERROR: {
      return {
        ...state,
        createMaintenanceRequestError: action.payload,
        createMaintenanceRequestLoadig: false,
      };
    }
    case types.CREATE_MAINTENANCE_REQUEST_RESET: {
      return {
        ...state,
        createMaintenanceRequest: false,
        createMaintenanceRequestError: null,
        createMaintenanceRequestLoadig: false,
      };
    }
    //update

    case types.UPDATE_MAINTENANCE_REQUEST_START: {
      return {
        ...state,
        updateMaintenanceRequestError: null,
        updateMaintenanceRequestLoadig: true,
      };
    }
    case types.UPDATE_MAINTENANCE_REQUEST_SUCCESS: {
      const list = state.requestList.data;
      const newList = list.map((data) =>
        data.id !== action.payload.id ? data : action.payload
      );
      return {
        ...state,
        updateMaintenanceRequest: true,
        requestList: { ...state.requestList, data: newList },
        updateMaintenanceRequestLoadig: false,
      };
    }
    case types.UPDATE_MAINTENANCE_REQUEST_ERROR: {
      return {
        ...state,
        updateMaintenanceRequestError: action.payload,
        updateMaintenanceRequestLoadig: false,
      };
    }
    case types.UPDATE_MAINTENANCE_REQUEST_RESET: {
      return {
        ...state,
        updateMaintenanceRequest: false,
        updateMaintenanceRequestError: null,
        updateMaintenanceRequestLoadig: false,
      };
    }
    //delete
    case types.DELETE_MAINTENANCE_REQUEST_START: {
      return {
        ...state,
        deleteMaintenanceRequestError: null,
        deleteMaintenanceRequestLoadig: true,
      };
    }
    case types.DELETE_MAINTENANCE_REQUEST_SUCCESS: {
      return {
        ...state,
        deleteMaintenance: action.payload,
        deleteMaintenanceRequestLoadig: false,
      };
    }
    case types.DELETE_MAINTENANCE_REQUEST_ERROR: {
      return {
        ...state,
        deleteMaintenanceRequestError: action.payload,
        deleteMaintenanceRequestLoadig: false,
      };
    }
    case types.DELETE_MAINTENANCE_REQUEST_RESET: {
      return {
        ...state,
        deleteMaintenance: null,
        deleteMaintenanceRequestError: null,
        deleteMaintenanceRequestLoadig: false,
      };
    }

    //select single
    case types.SINGLE_MAINTENANCE_REQUEST_START: {
      return {
        ...state,
        SingleMaintenanceRequest: null,
        singleMaintenanceRequestLoading: true,
        singleMaintenanceRequestError: null,
      };
    }
    case types.SINGLE_MAINTENANCE_REQUEST_SUCCESS: {
      return {
        ...state,
        SingleMaintenanceRequest: action.payload,
        singleMaintenanceRequestLoading: false,
        singleMaintenanceRequestError: null,
      };
    }
    case types.SINGLE_MAINTENANCE_REQUEST_ERROR: {
      return {
        ...state,
        SingleMaintenanceRequest: null,
        singleMaintenanceRequestLoading: false,
        singleMaintenanceRequestError: action.payload,
      };
    }

    //search
    case types.SEARCH_MAINTENANCE_REQUEST_START: {
      return {
        ...state,
        search: null,
        searchError: null,
        searchLoading: true,
      };
    }
    case types.SEARCH_MAINTENANCE_REQUEST_ERROR: {
      return {
        ...state,
        searchError: action.payload,
        searchLoading: false,
      };
    }
    case types.SEARCH_MAINTENANCE_REQUEST_SUCCESS: {
      return {
        ...state,
        search: action.payload,
        searchLoading: false,
      };
    }
    case types.MAINTENANCE_REQUEST_SEARCH_RESET: {
      return {
        ...state,
        search: null,
        searchError: null,
        searchLoading: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default maintenanceRequestReducer;
