import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";

//create offre
function* updateAdminHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.put,
			endpoints.updateAdminProfile,
			action.payload,
		);
		yield put({
			type: types.UPDATE_ADMIN_SUCCESS,
			payload: data,
		});
	} catch (e) {
		yield put({
			type: types.UPDATE_ADMIN_ERROR,
			payload: e.response.data,
		});
	}
}

export function* updateAdminWatcherSaga() {
	yield takeEvery(types.UPDATE_ADMIN_START, updateAdminHandlerSaga);
}

//update offre
function* ChangePasswordHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.post,
			endpoints.changeAdminPassword,
			action.payload,
		);
		yield put({
			type: types.PASSWORD_RESET_SUCCESS,
			payload: data,
		});
	} catch (e) {
		yield put({
			type: types.PASSWORD_RESET_ERROR,
			payload: e.response.data,
		});
	}
}

export function* passwordChangerWatcherSaga() {
	yield takeEvery(types.PASSWORD_RESET_START, ChangePasswordHandlerSaga);
}
