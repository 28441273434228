import types from "./types";

export const generateGuestJWTStart = () => ({
  type: types.GENERATE_GUEST_JWT_START,
});

export const staffLoginStart = (payload) => ({
  type: types.STAFF_LOGIN_START,
  payload,
});
export const staffLogout = () => ({
  type: types.STAFF_LOGOUT,
});
