import React from "react";
import styles from "./css/style.module.css";
import { Link } from "react-router-dom";

const BreadCumb = (props) => {
	return <div className={`${styles.container}`}>{props.children}</div>;
};

BreadCumb.Item = (props) => {
	return (
		<>
			{props.href.length > 0 ? (
				<Link to={props.href} style={props.style} className={`${styles.item}`}>
					{props.children}
				</Link>
			) : (
				<div className={styles.item_noLink} style={props.style}>
					{props.children}
				</div>
			)}
		</>
	);
};

BreadCumb.defaultProps = {
	className: "",
	style: "",
	href: "",
};

BreadCumb.Item.defaultProps = {
	className: "",
	styles: "",
	href: "",
};

export default BreadCumb;
