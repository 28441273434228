import React, {useRef} from 'react';
import {
    sendBulkNotificationsStart,

} from "../../store/Notification/actions";
import { PrimaryButton } from "../../ui-elements/Button";
import { FormInput, FormLabel } from "../../ui-elements/Input";
import { LoadingButton, Modal } from "../../Components";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';

const NotificationModal = ({
    // state
    sendingNotifications,


    // modal controls
    showNotificationContentModal,
    setShowNotificationContentModal,

    // payload
    payload,

 }) => {
    const dispatch = useDispatch();

    // refs to notification form elements
    const notificationTitleRef = useRef();
    const notificationBodyRef = useRef();

    return (
        <Modal
            open={showNotificationContentModal}
            setOpen={setShowNotificationContentModal}
            onClose={() => setShowNotificationContentModal(true)}
        >
            <h2>New Notification</h2>
            <FormLabel style={{width:"100%",}}>Title</FormLabel>
            <FormInput
                style={{width:"98%",}}
                ref={notificationTitleRef}
            />
            <FormLabel style={{width:"100%",}}>Body*</FormLabel>
            <textarea rows={5}  style={{width:"99%",}} ref={notificationBodyRef}></textarea>

            {
                sendingNotifications
                    ?
                    <LoadingButton />
                    :
                    <PrimaryButton
                        disabled={false}
                        style={{width:"100%",marginTop:10,}}
                        onClick={() => {
                            const title = notificationTitleRef.current.value?.trim();
                            const body = notificationBodyRef.current.value?.trim();
                            if(!title || !body)
                            // if(!body)
                                toast.warning("Please add notification title and body.");
                                // toast.warning("Please add notification body.");
                            else
                                dispatch(sendBulkNotificationsStart({...payload, title, body}));
                        }}
                    >
                        Send
                    </PrimaryButton>
            }
        </Modal>
    );

}



export default NotificationModal;