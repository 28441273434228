import types from "./types";

export const getVillaListStart = (payload) => ({
  type: types.GET_VILLA_LIST_START,
  payload,
});
export const createVillaStart = (payload) => ({
  type: types.CREATE_VILLA_START,
  payload,
});

export const resetCreateVilla = () => ({
  type: types.CREATE_VILLA_STATE_RESET,
});

//update

export const updateVillaStart = (payload) => ({
  type: types.UPDATE_VILLA_START,
  payload,
});

export const resetUpdateVilla = () => ({
  type: types.UPDATE_VILLA_STATE_RESET,
});

//delete

export const deleteVillaStart = (payload) => ({
  type: types.DELETE_VILLA_START,
  payload,
});

export const resetdeleteVilla = () => ({
  type: types.DELETE_VILLA_STATE_RESET,
});

//bulk delete
export const startVilaBulkDelete = (payload) =>({
  type: types.DELETE_BULK_VILLA_START,
  payload,
});

export const resetBulkDeleteVilla = () => ({
  type: types.DELETE_BULK_VILLA_STATE_RESET,
});

export const createVillaStateReset = () => ({
  type: types.CREATE_VILLA_STATE_RESET,
});

export const getSingleVillaLocal = (payload) => ({
  type: types.GET_SINGLE_VILLA_SUCCESS,
  payload,
});
export const getSingleVillaServer = (payload) => ({
  type: types.GET_SINGLE_VILLA_START,
  payload,
});

export const searchVillaStart = (payload) => ({
  type: types.SEARCH_VILLA_START,
  payload,
});

export const searchVillaReset = () => ({
  type: types.VILLA_SEARCH_RESET,
});

export const villaAssignStart = (payload) => ({
  type: types.VILLA_ASSIGN_START,
  payload,
});
export const villaAssignReset = () => ({
  type: types.VILLA_ASSIGN_RESET,
});
export const villaUnassignResidentStart = (payload) => ({
  type: types.VILLA_UNASSIGN_RESIDENT_START,
  payload,
});
export const villaUnAssignReset = () => ({
  type: types.VILLA_UNASSIGN_RESIDENT_RESET,
});
