import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";

// API CALL FOR Comment
function* getCommentHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endpoints.commentlist(action.payload),
		);
		yield put({
			type: types.GET_COMMENT_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		yield put({
			type: types.GET_COMMENT_ERROR,
			payload: e.response.data,
		});
	}
}

export function* getCommentsWatcherSaga() {
	yield takeEvery(types.GET_COMMENT_START, getCommentHandlerSaga);
}

// API creat FOR Comment
function* createCommentHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.post,
			endpoints.createComment,
			action.payload,
		);
		yield put({
			type: types.CREATE_COMMENT_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		yield put({
			type: types.CREATE_COMMENT_ERROR,
			payload: e.response.data,
		});
	}
}

export function* createCommentsWatcherSaga() {
	yield takeEvery(types.CREATE_COMMENT_START, createCommentHandlerSaga);
}
