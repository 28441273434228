import { createSelector } from "reselect";

const selectBuildings = (state) => state.buildings;

// BUILDING LIST SELECTORS
export const selectBuildingsList = createSelector(
	[selectBuildings],
	(buildings) => buildings.buildings,
);
export const selectBuildingsError = createSelector(
	[selectBuildings],
	(buildings) => buildings.buildingsError,
);
export const selectLoadingBuildings = createSelector(
	[selectBuildings],
	(buildings) => buildings.loadingBuildings,
);

// BUILDING CREATE SELECTORS
export const selectNewBuilding = createSelector(
	[selectBuildings],
	(buildings) => buildings.newBuilding,
);
export const selectCreatingNewBuilding = createSelector(
	[selectBuildings],
	(buildings) => buildings.creatingNewBuilding,
);
export const selectBuildingCreationError = createSelector(
	[selectBuildings],
	(buildings) => buildings.buildingCreationError,
);

// BUILDING DETAILS SELECTORS
export const selectBuildingDetails = createSelector(
	[selectBuildings],
	(buildings) => buildings.buildingDetails,
);
export const selectGettingBuildingDetails = createSelector(
	[selectBuildings],
	(buildings) => buildings.gettingBuildingDetails,
);
export const selectBuildingDetailsError = createSelector(
	[selectBuildings],
	(buildings) => buildings.buildingDetailsError,
);

// BUILDING UPDATE SELECTORS
export const selectUpdatedBuilding = createSelector(
	[selectBuildings],
	(buildings) => buildings.updatedBuilding,
);
export const selectUpdatingBuilding = createSelector(
	[selectBuildings],
	(buildings) => buildings.updatingBuilding,
);
export const selectBuildingUpdateError = createSelector(
	[selectBuildings],
	(buildings) => buildings.buildingUpdateError,
);

// BUILDING DELETE SELECTORS
export const selectBuildingDeleted = createSelector(
	[selectBuildings],
	(buildings) => buildings.buildingDeleted,
);
export const selectDeletingBuilding = createSelector(
	[selectBuildings],
	(buildings) => buildings.deletingBuilding,
);
export const selectDeleteBuildingError = createSelector(
	[selectBuildings],
	(buildings) => buildings.deleteBuildingError,
);

// BUILDING Bulk DELETE SELECTORS
export const selectBuildingBulkDeleted = createSelector(
	[selectBuildings],
	(buildings) => buildings.buildingBulkDeleted,
);
export const selectBulkDeletingBuilding = createSelector(
	[selectBuildings],
	(buildings) => buildings.bulkDeletingBuilding,
);
export const selectBulkDeleteBuildingError = createSelector(
	[selectBuildings],
	(buildings) => buildings.bulkDeleteBuildingError,
);

export const selectSearchStart = {
	search: createSelector([selectBuildings], (state) => state.search),
	error: createSelector([selectBuildings], (state) => state.searchError),
	loading: createSelector([selectBuildings], (state) => state.searchLoading),
};
