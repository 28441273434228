import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";

// API CALL FOR BUILDING LIST
function* getBuildingsDetailHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.get,
      endpoints.buildingDetails(action.payload)
    );
    yield put({
      type: types.GET_BUILDING_DETAILS_SUCCESS,
      payload: data.response,
    });
  } catch (e) {
    yield put({
      type: types.GET_BUILDING_DETAILS_ERROR,
      payload: e.response.data,
    });
  }
}

export function* getBuildingsDetailsWatcherSaga() {
  yield takeEvery(
    types.GET_BUILDING_DETAILS_START,
    getBuildingsDetailHandlerSaga
  );
}
