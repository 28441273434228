import React from 'react';

const Support  = () => {

    return(
        <div
            style={{
                display : 'flex',
                flexDirection: 'column',
                alignItems : 'center',
                justifyContent: 'center',
                height: '90vh'
            }}
        >
            <h2>Maha Mohammed</h2>
            <p>Email: maha@baitti.sa</p>
            <p>Address: Al Khobar, 31952, Saudi Arabia</p>
            <p>Phone: +966590020022</p>
        </div>
    )
}

export default  Support;
