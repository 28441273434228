import { useEffect, useState } from "react";

const useScreenSize = () => {
	const [screen, setScreen] = useState();
	useEffect(() => {
		setScreen(window.innerWidth);
		window.addEventListener("resize", () => {
			setScreen(window.innerWidth);
		});
	}, []);

	return screen;
};

export default useScreenSize;
