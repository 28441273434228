import types from "./types";

const INITIAL_STATE = {
  permitList: null,
  loadingPermits: false,
  permitsError: null,

  //create

  create: null,
  createloading: false,
  createError: null,

  //update
  update: false,
  updateloading: false,
  updateError: null,

  //changeStatus
  status: false,
  statusError: null,
  statusLoading: false,

  //delete
  itemDelete: null,
  deleteError: null,
  deleteLoading: false,

  //singleitem
  single: null,
  singleError: null,
  singleLoading: false,

  //search

  search: null,
  searchError: null,
  searchLoading: false,
};

const visitorPermitReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_VISITOR_PERMIT_LIST_START: {
      return {
        ...state,
        permitList: null,
        loadingPermits: true,
        permitsError: null,
      };
    }
    case types.GET_VISITOR_PERMIT_LIST_SUCCESS: {
      return {
        ...state,
        permitList: action.payload,
        loadingPermits: false,
      };
    }
    case types.GET_VISITOR_PERMIT_LIST_ERROR: {
      return {
        ...state,
        loadingPermits: false,
        permitsError: action.payload,
      };
    }

    //create visitor

    case types.CREATE_VISITOR_PERMIT_START: {
      return {
        ...state,
        create: null,
        createloading: true,
        createError: null,
      };
    }
    case types.CREATE_VISITOR_PERMIT_SUCCESS: {
      return {
        ...state,
        create: action.payload,
        createloading: false,
      };
    }
    case types.CREATE_VISITOR_PERMIT_ERROR: {
      return {
        ...state,
        createloading: false,
        createError: action.payload,
      };
    }

    case types.RESET_CREATE_VISITOR_PERMIT: {
      return {
        ...state,
        create: null,
        createloading: false,
        createError: null,
      };
    }

    //update visitor

    case types.UPDATE_VISITOR_PERMIT_START: {
      return {
        ...state,
        update: false,
        updateloading: true,
        updateError: null,
      };
    }
    case types.UPDATE_VISITOR_PERMIT_SUCCESS: {
      const res = action.payload.response;
      const list = state.permitList.data;
      const newList = list.map((data) => (data.id !== res.id ? data : res));
      return {
        ...state,
        // permitList: { ...state.permitList, data: newList },
        update: action.payload,
        updateloading: false,
        updateError: null,
      };
    }
    case types.UPDATE_VISITOR_PERMIT_ERROR: {
      return {
        ...state,
        updateloading: false,
        updateError: action.payload,
      };
    }
    case types.RESET_UPDATE_VISITOR_PERMIT: {
      return {
        ...state,
        update: false,
        updateloading: false,
        updateError: null,
      };
    }
    //status update
    case types.CHANGE_STATUS_VISITOR_PERMIT_START: {
      return {
        ...state,
        status: null,
        statusError: null,
        statusLoading: true,
      };
    }
    case types.CHANGE_STATUS_VISITOR_PERMIT_SUCCESS: {
      const list = state.permitList.data;
      const newList = list.map((data) =>
        data.id !== action.payload.id ? data : action.payload
      );

      return {
        ...state,
        permitList: { ...state.permitList, data: newList },
        status: true,
        statusError: null,
        statusLoading: false,
      };
    }
    case types.CHANGE_STATUS_VISITOR_PERMIT_ERROR: {
      return {
        ...state,
        status: false,
        statusError: action.payload,
        statusLoading: false,
      };
    }
    case types.RESET_STATUS_VISITOR_PERMIT: {
      return {
        ...state,
        status: false,
        statusError: null,
        statusLoading: false,
      };
    }
    //delete
    case types.DELETE_VISITOR_PERMIT_START: {
      return {
        ...state,
        itemDelete: null,
        deleteError: null,
        deleteLoading: true,
      };
    }
    case types.DELETE_VISITOR_PERMIT_SUCCESS: {
      return {
        ...state,
        itemDelete: action.payload,
        deleteLoading: false,
      };
    }
    case types.DELETE_VISITOR_PERMIT_ERROR: {
      return {
        ...state,

        deleteError: action.payload,
        deleteLoading: false,
      };
    }
    case types.RESET_DELETE_VISITOR_PERMIT: {
      return {
        ...state,
        itemDelete: null,
        deleteError: null,
        deleteLoading: false,
      };
    }

    case types.GET_SINGLE_VISITOR_PERMIT_START: {
      return {
        ...state,
        single: null,
        singleError: null,
        singleLoading: true,
      };
    }
    case types.GET_SINGLE_VISITOR_PERMIT_SUCCESS: {
      return {
        ...state,
        single: action.payload,
        singleLoading: false,
      };
    }
    case types.GET_SINGLE_VISITOR_PERMIT_ERROR: {
      return {
        ...state,

        singleError: action.payload,
        singleLoading: false,
      };
    }

    //search
    case types.SEARCH_VISITOR_PERMIT_START: {
      return {
        ...state,
        search: null,
        searchError: null,
        searchLoading: true,
      };
    }
    case types.SEARCH_VISITOR_PERMIT_ERROR: {
      return {
        ...state,
        searchError: action.payload,
        searchLoading: false,
      };
    }
    case types.SEARCH_VISITOR_PERMIT_SUCCESS: {
      return {
        ...state,
        search: action.payload,
        searchLoading: false,
      };
    }
    case types.VISITOR_PERMIT_SEARCH_RESET: {
      return {
        ...state,
        search: null,
        searchError: null,
        searchLoading: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default visitorPermitReducer;
