const types = {
	GET_VISITOR_PERMIT_LIST_START: "GET_VISITOR_PERMIT_LIST_START",
	GET_VISITOR_PERMIT_LIST_SUCCESS: "GET_VISITOR_PERMIT_LIST_SUCCESS",
	GET_VISITOR_PERMIT_LIST_ERROR: "GET_VISITOR_PERMIT_LIST_ERROR",

	//CREATE

	CREATE_VISITOR_PERMIT_START: "CREATE_VISITOR_PERMIT_START",
	CREATE_VISITOR_PERMIT_SUCCESS: "CREATE_VISITOR_PERMIT_SUCCESS",
	CREATE_VISITOR_PERMIT_ERROR: "CREATE_VISITOR_PERMIT_ERROR",
	RESET_CREATE_VISITOR_PERMIT: "RESET_CREATE_VISITOR_PERMIT",

	//UPDATE

	UPDATE_VISITOR_PERMIT_START: "UPDATE_VISITOR_PERMIT_START",
	UPDATE_VISITOR_PERMIT_SUCCESS: "UPDATE_VISITOR_PERMIT_SUCCESS",
	UPDATE_VISITOR_PERMIT_ERROR: "UPDATE_VISITOR_PERMIT_ERROR",
	RESET_UPDATE_VISITOR_PERMIT: "RESET_UPDATE_VISITOR_PERMIT",

	//delete
	DELETE_VISITOR_PERMIT_START: "DELETE_VISITOR_PERMIT_START",
	DELETE_VISITOR_PERMIT_SUCCESS: "DELETE_VISITOR_PERMIT_SUCCESS",
	DELETE_VISITOR_PERMIT_ERROR: "DELETE_VISITOR_PERMIT_ERROR",
	RESET_DELETE_VISITOR_PERMIT: "RESET_DELETE_VISITOR_PERMIT",

	//status

	CHANGE_STATUS_VISITOR_PERMIT_START: "CHANGE_STATUS_VISITOR_PERMIT_START",
	CHANGE_STATUS_VISITOR_PERMIT_SUCCESS: "CHANGE_STATUS_VISITOR_PERMIT_SUCCESS",
	CHANGE_STATUS_VISITOR_PERMIT_ERROR: "CHANGE_STATUS_VISITOR_PERMIT_ERROR",
	RESET_STATUS_VISITOR_PERMIT: "RESET_STATUS_VISITOR_PERMIT",

	//select single visitor
	GET_SINGLE_VISITOR_PERMIT_START: "GET_SINGLE_VISITOR_PERMIT_START",
	GET_SINGLE_VISITOR_PERMIT_SUCCESS: "GET_SINGLE_VISITOR_PERMIT_SUCCESS",
	GET_SINGLE_VISITOR_PERMIT_ERROR: "GET_SINGLE_VISITOR_PERMIT_ERROR",

	//search
	SEARCH_VISITOR_PERMIT_START: "SEARCH_VISITOR_PERMIT_START",
	SEARCH_VISITOR_PERMIT_SUCCESS: "SEARCH_VISITOR_PERMIT_SUCCESS",
	SEARCH_VISITOR_PERMIT_ERROR: "SEARCH_VISITOR_PERMIT_ERROR",
	VISITOR_PERMIT_SEARCH_RESET: "VISITOR_PERMIT_SEARCH_RESET",
};
export default types;
