import React from "react";
import styles from "./css/style.module.css";

const Container = (props) => {
	return (
		<div className={`${styles.container} ${props.className}`}>
			{props.children}
		</div>
	);
};

Container.defaultProps = {
	className: "",
	style: "",
};

export default Container;
