import React, {useMemo, useState} from "react";
import { MdClose, MdSearch } from "react-icons/md";
import styles from "./css/style.module.css";
import {useSelector} from "react-redux";

const SearchBox = ({ tosearch, className, style }) => {
	const [searchText, setSearchText] = useState("");
	const [reset, setreset] = useState(false);
	const {selected_project} = useSelector(state => state.project);
	const selectedProject = useMemo(()=> selected_project,[selected_project]);

	const Search = () => {
		if (reset) {
			setSearchText("");
			tosearch({
				page: 0,
				size: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
				search: "",
				projectId: selectedProject?.id
			});
			setreset(false);
		} else {
			if (searchText.length > 0) {
				tosearch({
					page: 0,
					size: process.env.REACT_APP_DEFAULT_PAGE_SIZE,
					search: searchText,
					projectId: selectedProject?.id
				});
				setreset(true);
			}
		}
	};

	// setSearchText(" ");

	return (
		<div
			className={`${styles.search_bar_container} ${className}`}
			style={style}>
			<input
				value={searchText}
				type='text'
				onChange={(e) => {
					setSearchText(e.target.value);
					setreset(false);
				}}
				className={styles.search_box}
				placeholder='search'
			/>
			{reset ? (
				<MdClose onClick={Search} className={styles.search_btn} />
			) : (
				<MdSearch onClick={Search} className={styles.search_btn} />
			)}
		</div>
	);
};

SearchBox.defaultProps = {
	className: "",
	styles: "",
};

export default SearchBox;
