import types from "./types";

const INITIAL_STATE = {
	housingComplex: null,
	housingComplexLoading: true,
	housingComplexError: null,
};

const housingComplexReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_HOUSING_SINGLE_ITEM_START: {
			return {
				...state,
				housingComplex: null,
				housingComplexLoading: true,
				housingComplexError: null,
			};
		}
		case types.GENERATE_GUEST_JWT_SUCCESS: {
			return {
				...state,
				housingComplex: action.payload,
				housingComplexLoading: false,
			};
		}
		case types.GENERATE_GUEST_JWT_ERROR: {
			return {
				...state,
				housingComplexLoading: false,
				housingComplexError: action.payload,
			};
		}

		default: {
			return {
				...state,
			};
		}
	}
};

export default housingComplexReducer;
