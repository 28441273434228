import types from './types';

const INITIAL_STATE = {
    project_list: null,
    loading_project_list: false,
    project_list_error: null,

    selected_project: null,

    new_project: null,
    creating_new_project: false,
    new_project_error: null,

    project_under_edit: null,
    edited_project: null,
    editing_project: false,
    edit_project_error: null,
    
    //delete Project
    deleteProject: false,
    deleteProjectError: null,
    deleteingProject: true,

}

const projectReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){
        /**
         * PROJECT EDIT RELATED CASES START
         * */
        case types.EDIT_PROJECT_STATE_RESET: {
            return {
                ...state,
                edited_project: null,
                editing_project: false,
                edit_project_error: null,
            }
        }
        case types.SELECT_PROJECT_UNDER_EDIT:{
            return {
                ...state,
                project_under_edit: action.payload
            }
        }
        case types.EDIT_PROJECT_START:{
            return {
                ...state,
                edited_project: null,
                editing_project: true,
                edit_project_error: null,
            }
        }
        case types.EDIT_PROJECT_SUCCESS: {
            const projectList = Object.assign([] , state.project_list.data);
            const projectIndex = projectList.findIndex(i=>i.id === action.payload.id);
            if(projectIndex === -1) throw new TypeError('Invalid Project Id');
            projectList[projectIndex] = action.payload;
            return {
                ...state,
                edited_project: action.payload,
                project_list: {
                    ...state.project_list,
                    data: projectList
                },
                edit_project_error: null,
                editing_project: false,
                project_under_edit: action.payload,

            }
        }
        case types.EDIT_PROJECT_ERROR: {
            return {
                ...state,
                edited_project: null,
                editing_project: false,
                edit_project_error: action.payload
            }
        }
        /**
         * PROJECT EDIT RELATED CASES END
         * */

        /**
         * PROJECT LIST RELATED CASES START
         * */
        case types.GET_PROJECT_LIST_START:{
            return {
                ...state,
                project_list: null,
                loading_project_list: true,
                project_list_error: null,

            }
        }
        case types.GET_PROJECT_LIST_SUCCESS:{
            return {
                ...state,
                project_list: action.payload,
                loading_project_list: false,
                project_list_error: null,

            }
        }
        case types.GET_PROJECT_LIST_ERROR:{
            return {
                ...state,
                project_list: null,
                loading_project_list: false,
                project_list_error: action.payload,

            }
        }
        /**
         * PROJECT LIST RELATED CASES END
         * */

        /**
         * PROJECT CREATE RELATED CASES START
         * */
        case types.CREATE_PROJECT_STATE_RESET: {
            return {
                ...state,
                new_project: null,
                creating_new_project: false,
                new_project_error: null,
            }
        }
        case types.CREATE_PROJECT_START: {
            return {
                ...state,
                new_project: null,
                creating_new_project: true,
                new_project_error: null,
            }
        }
        case types.CREATE_PROJECT_SUCCESS: {
            const projectList = Object.assign([],state.project_list.data)
            return {
                ...state,
                new_project: action.payload,
                creating_new_project: false,
                project_list: {
                    ...state.project_list,
                    data: [action.payload,...projectList,]
                },
                new_project_error: null,
            }
        }
        case types.CREATE_PROJECT_ERROR: {
            return {
                ...state,
                new_project: null,
                creating_new_project: false,
                new_project_error: action.payload,
            }
        }
        /**
         * PROJECT CREATE RELATED CASES END
         * */

        /**
         * SELECT PROJECT RELATED CASES START
         * */
        case types.SELECT_PROJECT: {
            return {
                ...state,
                selected_project: action.payload,
            }
        }
        /**
         * SELECT PROJECT RELATED CASES END
         * */

        //delete project
        case types.DELETE_PROJECT_START: {
            return {
              ...state,
              deleteProject: false,
              deleteProjectError: null,
              deleteingProject: true,
            };
          }
          case types.DELETE_PROJECT_SUCCESS: {
            const projectId = Number.parseInt(action.payload.response.id);
            const projectList = Object.assign([], state.project_list.data);
            const index = projectList.findIndex(i => i.id === projectId);
            if(index > -1){
                projectList.splice(index, 1);
            }
            
            return {
              ...state,
              project_list: {
                ...state.project_list,
                data: projectList
              },
              deleteProject: action.payload,
              deleteingProject: false,
            };
          }
          case types.DELETE_PROJECT_ERROR: {
            return {
              ...state,
              deleteProjectError: action.payload,
              deleteingProject: false,
            };
          }
          case types.DELETE_PROJECT_RESET:{
            return {
                ...state,
                deleteProject: false,
                deleteProjectError: null,
                deleteingProject: false,
            }
          }
        default: {
            return state
        }
    }
}

export default projectReducer;