import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";

function* villaUnAssignResidentHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.post,
      endpoints.villaResidentUnAssign(action.payload)
    );
    yield put({
      type: types.VILLA_UNASSIGN_RESIDENT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.VILLA_UNASSIGN_RESIDENT_ERROR,
      payload,
    });
  }
}
export function* villaUnAssignResidentWatcherSaga() {
  yield takeEvery(
    types.VILLA_UNASSIGN_RESIDENT_START,
    villaUnAssignResidentHandlerSaga
  );
}

function* villaAssignStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.put,
      endpoints.villaResidentAssign,
      action.payload
    );
    yield put({ type: types.VILLA_ASSIGN_SUCCESS, payload: data });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.VILLA_ASSIGN_ERROR,
      payload,
    });
  }
}
export function* villaAssignStartWatcherSaga() {
  yield takeEvery(types.VILLA_ASSIGN_START, villaAssignStartHandlerSaga);
}

// API CALL TO GET VILLA LIST
function* getVillaListStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { page, size } = action.payload;
    const { data } = yield call(apiClient.get, endpoints.villaList(page, size));
    yield put({
      type: types.GET_VILLA_LIST_SUCCESS,
      payload: data.response,
    });
  } catch (e) {
    yield put({
      type: types.GET_VILLA_LIST_ERROR,
      payload: e.response.data,
    });
  }
}

export function* getVillaListStartWatcher() {
  yield takeEvery(types.GET_VILLA_LIST_START, getVillaListStartHandlerSaga);
}

// API CALL TO CREATE NEW VILLA
function* createNewVillaHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.post,
      endpoints.createVilla,
      action.payload
    );
    yield put({
      type: types.CREATE_VILLA_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: types.CREATE_VILLA_ERROR,
      payload: e.response.data,
    });
  }
}

export function* createNewVillaWatcherSaga() {
  yield takeEvery(types.CREATE_VILLA_START, createNewVillaHandlerSaga);
}

// API CALL TO CREATE update VILLA
function* createUpdateVillaHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const response = yield call(
      apiClient.put,
      endpoints.updateVilla,
      action.payload
    );
    yield put({
      type: types.UPDATE_VILLA_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    const payload = e?.response || e;
    yield put({
      type: types.UPDATE_VILLA_ERROR,
      payload: payload,
    });
  }
}

export function* UpdateVillaWatcherSaga() {
  yield takeEvery(types.UPDATE_VILLA_START, createUpdateVillaHandlerSaga);
}

//delete

function* deleteVillaHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.delete,
      endpoints.deleteVilla(action.payload)
    );
    yield put({
      type: types.DELETE_VILLA_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: types.DELETE_VILLA_ERROR,
      payload: e.response.data,
    });
  }
}

export function* deleteVillaWatcherSaga() {
  yield takeEvery(types.DELETE_VILLA_START, deleteVillaHandlerSaga);
}

//Bulk Delete

function* deleteVillsBulkStartHandelerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.post,
      endpoints.villaBulkDelete,
      action.payload

    );
    yield put({
      type: types.DELETE_BULK_VILLA_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: types.DELETE_BULK_VILLA_ERROR,
      payload: e.response.data,
    });
  }
}
export function* deleteVillaBulkdeleteWatcherSaga() {
  yield takeEvery(types.DELETE_BULK_VILLA_START, deleteVillsBulkStartHandelerSaga)
};

function* singleVillaHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.get,
      endpoints.singleVilla(action.payload)
    );
    yield put({
      type: types.GET_SINGLE_VILLA_SUCCESS,
      payload: data.response,
    });
  } catch (e) {
    yield put({
      type: types.GET_SINGLE_VILLA_ERROR,
      payload: e.response.data,
    });
  }
}

export function* SingleVillaWatcherSaga() {
  yield takeEvery(types.GET_SINGLE_VILLA_START, singleVillaHandlerSaga);
}

function* searchItemStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.get,
      endpoints.villaSearch(action.payload)
    );
    yield put({ type: types.SEARCH_VILLA_SUCCESS, payload: data.response });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({ type: types.SEARCH_VILLA_ERROR, payload });
  }
}

export function* VillaSearchStartWatcherSaga() {
  yield takeEvery(types.SEARCH_VILLA_START, searchItemStartHandlerSaga);
}
