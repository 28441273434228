import React, { useState, useEffect, useMemo, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Table,
  Loading,
  Button,
  Modal,
  BreadCumb,
  SearchBox,
  BuildingForm,
  LoadingButton,
} from "../../Components";
import Pagination from "../../ui-elements/Pagination";
import { PrimaryButton } from "../../ui-elements/Button";
import { CirclesWithBar } from "react-loader-spinner";
import { Link } from "react-router-dom";

import { MdDelete, MdEdit, MdNotificationAdd } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import style from "./css/style.module.css";
import styled from "styled-components";
import { toast } from "react-toastify";
import NotificationModal from '../../Components/Notification/NotificationModal';


// importing selectors
import {
  selectBuildingsList,
  selectLoadingBuildings,
  selectBuildingsError,
  selectSearchStart,
} from "../../store/Building/selectors";

// importing actions
import {
  getBuildingListStart,
  createBuildingStart,
  updateBuildingStart,
  setBuildingForUpdate,
  buildingUpdateReset,
  setBuildingToDelete,
  deleteBuildingStart,
  buildingDeleteStateReset,
  setBuildingToBulkDelete,
  deleteBulkBuildingStart,
  buildingBulkDeleteStateReset,
  searchBuildingStart,
  searchBuildingReset,
  createBuildingStateReset,
} from "../../store/Building/actions";
import {
  sendBulkNotificationsStateReset,

} from "../../store/Notification/actions";

let PageSize = 10;
let toupdateId = 1;
let deleteItem = 0;
const Building = ({
  // selectors
  list,
  loading,
  error,
  Search,

  // actions
  getBuildingList,
  setBuildingForUpdate,
  createBuilding,
  buildingUpdateReset,
  setBuildingToDelete,
  deleteBuildingStart,
  buildingDeleteStateReset,
  setBuildingToBulkDelete,
  deleteBulkBuildingStart,
  buildingBulkDeleteStateReset,
  searchBuilding,
  searchReset,
}) => {
  const DEFAULT_PAGE_SIZE = Number.parseInt(
    process.env.REACT_APP_DEFAULT_PAGE_SIZE
  );
  const buildings = useSelector((state) => state.buildings);

  const notificationState = useSelector(state => state.notification);
  const sentNotificationsInfo = useMemo(() => notificationState?.sentNotificationsInfo, [notificationState?.sentNotificationsInfo]);
  const sendingNotifications = useMemo(() => notificationState?.sendingNotifications, [notificationState?.sendingNotifications]);
  const sendNotificationError = useMemo(() => notificationState?.sendNotificationError, [notificationState?.sendNotificationError]);


  const { selected_project } = useSelector(state => state.project);
  const {
    newBuilding,
    creatingNewBuilding,
    buildingCreationError,
    buildingToDelete,
    deletingBuilding,
    buildingDeleted,
    deleteBuildingError,
    buildingToBulkDelete,
    BulklDeletingBuilding,
    BulklBuildingDeleted,
    BulkDeleteBuildingError,
    updatedBuilding,
    buildingUpdateError,
  } = buildings;
  //console.log(BulklBuildingDeleted, "BulklBuildingDeleted 92");
  const selectedProject = useMemo(() => selected_project, [selected_project]);
  const dispatch = useDispatch();
  //for pagination
  const [data, setData] = useState([]);

  // refs to elements
  const notificationTitleRef = useRef();
  const notificationBodyRef = useRef();

  //for pupup
  const [modal2, setModal2] = useState(true);
  const [modal3, setModal3] = useState(true);
  const [modal, setModal] = useState(true);
  const [showNotificationContentModal, setShowNotificationContentModal] = useState(true);

  const [pagination, setPagination] = useState({
    page: 0,
    showing: 0,
    total: 0,
  });

  //for update
  const [update, setUpdate] = useState(false); //for togalling between update and add
  const [form, setform] = useState({
    name: "",
    building_number: "",
    maintenance_manager: "",
    security: "",
  });
  //for Count
  const [totalSelectedCheckboxes, setTotalSelectedCheckboxes] = useState(0);
  function handleChk() {
    setTotalSelectedCheckboxes(document.querySelectorAll('input[type=checkbox]:checked').length);
  }

  useEffect(() => {
    window.localStorage.removeItem("filterOptions");
    // console.log(totalSelectedCheckboxes);
  }, [totalSelectedCheckboxes]);
  //for Select
  const [isCheckAll, setIsCheckAll] = useState(false);
  //console.log(isCheckAll,`fhudhygiuf`);
  const [isCheck, setIsCheck] = useState([]);
  useEffect(() => {
    setData(data);
  }, [data]);

  // const handleSelectAll = e => {
  //   setIsCheckAll(!isCheckAll);
  //   setIsCheck(data.map(li => li.id));
  //   if (isCheckAll) {
  //     setIsCheck([]);
  //   }
  // };

  // const handleClick = e => {
  //   const { id, checked } = e.target;
  //   setIsCheck([...isCheck, id]);
  //   if (!checked) {
  //     setIsCheck(isCheck.filter(item => item !== id));
  //   }
  // };

  /**
   * CHECK BOX RELATED FUNCTIONS
   * Date: 01-12-2022
   * */
  const [checkedItems, setCheckedItems] = useState([]);
  const [buildingsToDelete, setBuildingsTODelete] = useState([]);
  const onCheckBoxClick = (e, position) => {
    if (e.target.checked) {
      setBuildingsTODelete(prevBuildings => [...prevBuildings, parseInt(e.target.id)]);
    } else {
      const index = buildingsToDelete?.findIndex(_b => _b === parseInt(e.target.id));
      if (index > -1) {
        const tempBuildingsToDelete = Object.assign([], buildingsToDelete);
        tempBuildingsToDelete.splice(index, 1)
        setBuildingsTODelete(tempBuildingsToDelete);
      }
    }
    const updatedCheckedState = checkedItems.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedItems(updatedCheckedState);
  }
  const onSelectAllCheckBoxClick = e => {
    if (e.target.checked) {
      setBuildingsTODelete(data?.map(element => element.id));
    } else {
      setBuildingsTODelete([]);
    }
    const updatedCheckedState = checkedItems.map((item, index) =>
      e.target.checked ? true : false
    );
    setCheckedItems(updatedCheckedState);
  }
  const bulkDeleteCheckedAndItemsReset = () => {
    setCheckedItems(new Array(searchMain?.data?.length).fill(false));
    setBuildingsTODelete([]);
  }
  /**
   * ****************************
   * */

  const { searchMain, searchError, searchLoading } = Search;

  useEffect(() => {
    window.localStorage.removeItem("filterOptions");
    // eslint-disable-next-line
    buildingUpdateReset();
    dispatch(createBuildingStateReset());
    buildingDeleteStateReset();
    getBuildingList(list?.page || 0, DEFAULT_PAGE_SIZE, selectedProject?.id);
  }, []);

  useEffect(() => {
    if (list !== null) {
      setData(list.data);
      setPagination({
        page: list.page,
        showing: list.showing,
        total: list.total,
      });
      setCheckedItems(new Array(list?.data?.length).fill(false))
    }
  }, [list]);

  useEffect(() => {
    if (error) toast.error(JSON.stringify(error));
  }, [error]);

  useEffect(() => {
    if (buildingDeleted) {
      setBuildingToDelete(null);
      getBuildingList(list.page || 0, DEFAULT_PAGE_SIZE, selectedProject?.id);
      toast.success("Building deleted successfully");
      setModal2(true);
      bulkDeleteCheckedAndItemsReset();
    }
  }, [buildingDeleted]);
  useEffect(() => {
    if (deleteBuildingError) {
      toast.error(deleteBuildingError?.response?.message);
      bulkDeleteCheckedAndItemsReset();
    }
  }, [deleteBuildingError]);


  // notificaitons response handling useEffect
  useEffect(() => {
    if (sentNotificationsInfo) {
      toast.success(sentNotificationsInfo?.message);
      dispatch(sendBulkNotificationsStateReset());
      setShowNotificationContentModal(true);
    }
  }, [sentNotificationsInfo]);
  useEffect(() => {
    if (sendNotificationError) {
      toast.error(sentNotificationsInfo?.message);
      dispatch(sendBulkNotificationsStateReset())
    }
  }, [sendNotificationError])

  const onDelete = (id) => { };

  //to Bulk Delete
  useEffect(() => {
    if (BulklBuildingDeleted) {
      setBuildingToBulkDelete(null);
      getBuildingList(list.page || 0, DEFAULT_PAGE_SIZE, selectedProject?.id);
      toast.success("Buildings deleted successfully");
      setModal3(true);
      buildingBulkDeleteStateReset();
      setCheckedItems(new Array(searchMain?.data?.length).fill(false));
      setBuildingsTODelete([]);
    }
  }, [BulklBuildingDeleted]);
  useEffect(() => {
    if (BulkDeleteBuildingError) {
      toast.error(BulkDeleteBuildingError?.response?.message);
    }
  }, [BulkDeleteBuildingError]);

  const onBulkDelete = (id) => { };
  //to update
  const onUpdate = (id) => {
    console.log(id);
    setUpdate(true);
    setModal(!modal);
    toupdateId = id;
  };
  const formSubmit = (e) => {
    e.preventDefault();
    if (update) {
      //call update here
      console.log(form, toupdateId);
    } else {
      //call add here
    }
    setModal(!modal);
  };
  const inputHandler = (e) => {
    setform((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  //search handler
  useEffect(() => {
    if (searchMain !== null) {
      if (searchMain?.data?.length > 0) {
        setData(searchMain.data);
        setPagination({
          page: searchMain.page,
          showing: searchMain.showing,
          total: searchMain.total,
        });
      }
      if (searchMain?.data?.length === 0) {
        // toast.warning("Building Not Found");
        setData([]);
        setPagination({
          page: list.page,
          showing: list.showing,
          total: list.total,
        });
        searchReset();
      }
    }
  }, [searchMain]);

  useEffect(() => {
    if (searchError) {
      toast.error(searchError?.message);
      searchReset();
    }
  }, [searchError]);

  // When building update is successful
  useEffect(() => {
    if (updatedBuilding) {
      toast.success("Building Updated Successfully");
      setModal(true);
      buildingUpdateReset();
      getBuildingList(list?.page || 0, DEFAULT_PAGE_SIZE, selectedProject?.id);
    }
  }, [updatedBuilding]);

  // when updating a building fails
  useEffect(() => {
    if (buildingUpdateError) {
      if (typeof buildingUpdateError?.response === "object") {
        const keys = Object.keys(buildingUpdateError?.response);
        keys?.forEach((_k) => {
          toast.error(buildingUpdateError?.response[_k]);
        });
      }
      if (buildingUpdateError?.statusCode === 400) {
        toast?.error(buildingUpdateError?.message);
      }
    }
  }, [buildingUpdateError]);
  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <BreadCumb>
          <BreadCumb.Item href="/dashboard">Dashboard</BreadCumb.Item>
          <BreadCumb.Item>Buildings</BreadCumb.Item>
        </BreadCumb>
        <div className="search-container">
          <SearchBox tosearch={searchBuilding} style={{ width: "50%" }} />
          <Button
            onClick={() => {
              setModal(!modal);
              setUpdate(false);
            }}
            style={{ userSelect: "none" }}
          >
            Create New Building
          </Button>
        </div>

        {/* Modal for crafting notification title & body */}
        <NotificationModal
          sendingNotifications={sendingNotifications}
          showNotificationContentModal={showNotificationContentModal}
          setShowNotificationContentModal={setShowNotificationContentModal}
          payload={{
            propertyType: "building",
            idList: buildingsToDelete,
            badge: "1",
          }}
        />


        {/* //model for delete */}
        <Modal
          open={modal2}
          setOpen={setModal2}
          onClose={() => {
            setBuildingToDelete(null);
          }}
        >
          <p>
            Are you sure you want to delete{" "}
            <b>{buildingToDelete?.building_number}</b> ?
          </p>
          {deletingBuilding ? (
            <CirclesWithBar
              height={40}
              color={"blue"}
              style={{ margin: "0 auto", display: "block" }}
            />
          ) : (
            <div className="flex">
              <button
                className="btn confirmBtn"
                onClick={() => deleteBuildingStart(buildingToDelete?.id)}
              >
                Confirm
              </button>
              <button
                className="btn cancleBtn"
                onClick={() => {
                  setModal2(!modal2);
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </Modal>

        {/* //model for delete all */}
        <Modal
          open={modal3}
          setOpen={setModal3}
          onClose={() => {
            setBuildingToBulkDelete(null);
          }}
        >
          <p>
            Are you sure you want to delete Selected Buildings {" "}
            <b>{buildingToBulkDelete?.building_number}</b> ?
          </p>
          {BulklDeletingBuilding ? (
            <CirclesWithBar
              height={40}
              color={"blue"}
              style={{ margin: "0 auto", display: "block" }}
            />
          ) : (
            <div className="flex">

              <button
                className="btn confirmBtn"
                onClick={() => {
                  deleteBulkBuildingStart(buildingsToDelete)
                }}
              >
                Confirm
              </button>

              <button
                className="btn cancleBtn"
                onClick={() => {
                  setModal3(!modal3);
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </Modal>

        <Modal
          open={modal}
          setOpen={setModal}
          onClose={() => {
            setBuildingForUpdate(null);
            buildingUpdateReset();
            dispatch(createBuildingStateReset());
          }}
        >
          <BuildingForm mode={update ? "edit" : "create"} />
        </Modal>

        {data?.length > 0 && (
          <Pagination
            page={pagination.page}
            showing={pagination.showing}
            total={pagination.total}
            onPageChangeHandler={(page, size) => {
              getBuildingList(page, size);
              setBuildingToDelete([]);
            }}
          />
        )}

        <div className={style.table_container}>
          {buildingsToDelete?.length > 0 &&
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <input
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  style={{ cursor: 'pointer', transform: 'scale(1.6)' }}
                  onChange={onSelectAllCheckBoxClick}
                  checked={checkedItems?.filter(item => item === true && item)?.length === data?.length}
                />
                {
                  checkedItems?.length > 0 && <span
                    style={{
                      marginLeft: '10px',
                      backgroundColor: '#cc9900',
                      color: '#ffff',
                      padding: '0.1em 0.6em'
                    }}
                  >{checkedItems?.filter(item => item === true && item)?.length + ' selected'}
                  </span>}
              </div>

              <button
                onClick={() => {
                  setModal3(!modal3);
                  setBuildingToBulkDelete(data);
                  deleteItem = data.id;
                }}
                class="delete-area">
                Delete Selected
                <MdDelete
                  className="cursor"
                  fontSize={"2em"}
                  color="red"
                />
              </button>
              <button
                onClick={() => {
                  setShowNotificationContentModal(false);
                }}
                class="delete-area">
                Send Notification
                <MdNotificationAdd
                  className="cursor"
                  fontSize={"2em"}
                  color="green"
                />
              </button>
            </div>}
          <br />
          <Table>
            <thead>

              {/*<tr>*/}
              {/*  {JSON.stringify(checkedItems)}*/}
              {/*  {JSON.stringify(buildingsToDelete)}*/}
              {/*</tr>*/}
              <tr>

                <th>Select</th>
                <th>Name/Number</th>
                <th>Apartments</th>
                <th>Edit | Delete</th>
              </tr>

            </thead>

            {(searchLoading) ? (
              <Loading />
            ) : (
              <tbody>
                {data.map((building, index) => (
                  <tr key={building.id}>
                    {/* <td>{index + 1}</td> */}
                    <td>

                      <input
                        key={building.id}
                        type="checkbox"
                        style={{ cursor: 'pointer', transform: 'scale(1.6)' }}
                        checked={checkedItems[index]}
                        id={building.id}
                        onChange={(e) => onCheckBoxClick(e, index)}
                      />
                    </td>
                    <td>{building.building_number}</td>
                    <td>
                      <Link
                        to={`/building/${building.id}`}
                        style={{
                          backgroundColor: "#cc9900",
                          color: "#ffff",
                          textDecoration: "none",
                          padding: "0.4em 0.6em",
                          borderRadius: "15px",
                        }}
                      >
                        View/Edit
                      </Link>
                    </td>
                    <td>
                      {/* <Link
                        style={{ textDecoration: "none" }}
                        to={`/building/${building.id}`}
                      >
                        <IoMdEye
                          color="#323232"
                          className="cursor"
                          fontSize={"2em"}
                          onClick={() => {}}
                        />
                      </Link> */}

                      <MdEdit
                        className="cursor"
                        onClick={() => {
                          setBuildingForUpdate(building);
                          setModal(!modal);
                          setUpdate(true);
                        }}
                        fontSize={"2em"}
                        color="#000"
                      />
                      <MdDelete
                        className="cursor"
                        fontSize={"2em"}
                        color="red"
                        onClick={() => {
                          setModal2(!modal2);
                          setBuildingToDelete(building);
                          deleteItem = building.id;
                        }}
                      />
                    </td>
                  </tr>

                ))}
              </tbody>
            )}
          </Table>
          {!searchLoading && data?.length === 0 && (
            <p style={{ textAlign: "center", color: "red" }}>
              No Records Found
            </p>
          )}
        </div>
        {data?.length > 0 && (
          <Pagination
            page={pagination.page}
            showing={pagination.showing}
            total={pagination.total}
            onPageChangeHandler={(page, size) => {
              getBuildingList(page, size);
              setBuildingToDelete([]);
            }}
          />
        )}
        <br />
      </>
    );
  }
};

const mapState = (state) => ({
  list: selectBuildingsList(state),
  loading: selectLoadingBuildings(state),
  error: selectBuildingsError(state),
  Search: {
    searchMain: selectSearchStart.search(state),
    searchError: selectSearchStart.error(state),
    searchLoading: selectSearchStart.loading(state),
  },
});
const mapDispatch = (dispatch) => ({
  getBuildingList: (page, size, projectId) =>
    dispatch(getBuildingListStart({ page, size, projectId })),
  createBuilding: (data) => dispatch(createBuildingStart(data)),
  updateBuilding: (data) => dispatch(updateBuildingStart(data)),
  setBuildingForUpdate: (data) => dispatch(setBuildingForUpdate(data)),
  buildingUpdateReset: () => dispatch(buildingUpdateReset()),
  setBuildingToDelete: (data) => dispatch(setBuildingToDelete(data)),
  deleteBuildingStart: (data) => dispatch(deleteBuildingStart(data)),
  buildingDeleteStateReset: (data) => dispatch(buildingDeleteStateReset(data)),
  setBuildingToBulkDelete: (data) => dispatch(setBuildingToBulkDelete(data)),
  deleteBulkBuildingStart: (data) => dispatch(deleteBulkBuildingStart(data)),
  buildingBulkDeleteStateReset: (data) => dispatch(buildingBulkDeleteStateReset(data)),
  searchBuilding: (data) => dispatch(searchBuildingStart(data)),
  searchReset: (data) => dispatch(searchBuildingReset(data)),
});

export default connect(mapState, mapDispatch)(Building);