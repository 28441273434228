import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";

function* getVisitorPermitListStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  const { page, size, search, status, userId, projectId } = action.payload;
  try {
    const { data } = yield call(
      apiClient.get,
      endpoints.visitorPermitList(page, size, search, status, userId, projectId)
    );
    yield put({
      type: types.GET_VISITOR_PERMIT_LIST_SUCCESS,
      payload: data.response,
    });
  } catch (e) {
    yield put({
      type: types.GET_VISITOR_PERMIT_LIST_ERROR,
      payload: e.response.data,
    });
  }
}

export function* getVisitorPermitListWatcherSaga() {
  yield takeEvery(
    types.GET_VISITOR_PERMIT_LIST_START,
    getVisitorPermitListStartHandlerSaga
  );
}

//create visitor permit

function* createVisitorPermitListStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.post,
      endpoints.createVisitorPermit,
      action.payload
    );
    yield put({
      type: types.CREATE_VISITOR_PERMIT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: types.CREATE_VISITOR_PERMIT_ERROR,
      payload: e.response.data,
    });
  }
}

export function* createVisitorPermitListWatcherSaga() {
  yield takeEvery(
    types.CREATE_VISITOR_PERMIT_START,
    createVisitorPermitListStartHandlerSaga
  );
}

//update visitor permit

function* updateVisitorPermitListStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.put,
      endpoints.updateVisitor,
      action.payload
    );
    yield put({
      type: types.UPDATE_VISITOR_PERMIT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: types.UPDATE_VISITOR_PERMIT_ERROR,
      payload: e.response.data,
    });
  }
}

export function* updateVisitorPermitListWatcherSaga() {
  yield takeEvery(
    types.UPDATE_VISITOR_PERMIT_START,
    updateVisitorPermitListStartHandlerSaga
  );
}

//change Status

function* changeVisitorPermitStatusStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.put,
      endpoints.updateStatus,
      action.payload
    );
    yield put({
      type: types.CHANGE_STATUS_VISITOR_PERMIT_SUCCESS,
      payload: data.response,
    });
  } catch (e) {
    yield put({
      type: types.CHANGE_STATUS_VISITOR_PERMIT_ERROR,
      payload: e.response.data,
    });
  }
}

export function* changeVisitorPermitStatusWatcherSaga() {
  yield takeEvery(
    types.CHANGE_STATUS_VISITOR_PERMIT_START,
    changeVisitorPermitStatusStartHandlerSaga
  );
}

//delete

function* deletePermitsHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.delete,
      endpoints.deleteVisitorPermit(action.payload)
    );
    yield put({
      type: types.DELETE_VISITOR_PERMIT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: types.DELETE_VISITOR_PERMIT_ERROR,
      payload: e.response.data,
    });
  }
}

export function* deletePermitsWatcherSaga() {
  yield takeEvery(types.DELETE_VISITOR_PERMIT_START, deletePermitsHandlerSaga);
}

function* selectPermitsHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.get,
      endpoints.selectVisitorPermit(action.payload)
    );
    yield put({
      type: types.GET_SINGLE_VISITOR_PERMIT_SUCCESS,
      payload: data.response,
    });
  } catch (e) {
    yield put({
      type: types.GET_SINGLE_VISITOR_PERMIT_ERROR,
      payload: e.response.data,
    });
  }
}

export function* selectPermitsWatcherSaga() {
  yield takeEvery(
    types.GET_SINGLE_VISITOR_PERMIT_START,
    selectPermitsHandlerSaga
  );
}

function* searchItemStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.get,
      endpoints.visitorPermitSearch(action.payload)
    );
    yield put({
      type: types.SEARCH_VISITOR_PERMIT_SUCCESS,
      payload: data.response,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({ type: types.SEARCH_VISITOR_PERMIT_ERROR, payload });
  }
}

export function* visitorPermitSearchStartWatcherSaga() {
  yield takeEvery(
    types.SEARCH_VISITOR_PERMIT_START,
    searchItemStartHandlerSaga
  );
}
