const types = {
  GET_USER_LIST_START: "GET_USER_LIST_START",
  GET_USER_LIST_SUCCESS: "GET_USER_LIST_SUCCESS",
  GET_USER_LIST_ERROR: "GET_USER_LIST_ERROR",

  CREATE_USER_START: "CREATE_USER_START",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_ERROR: "CREATE_USER_ERROR",
  CREATE_USER_STATE_RESET: "CREATE_USER_STATE_RESET",

  GET_USER_DETAILS_START: "GET_USER_DETAILS_START",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_ERROR: "GET_USER_DETAILS_ERROR",

  RESET_STATE: "RESET_STATE",

  SET_USER_FOR_DELETE: "SET_USER_FOR_DELETE",
  USER_DELETE_START: "USER_DELETE_START",
  USER_DELETE_SUCCESS: "USER_DELETE_SUCCESS",
  USER_DELETE_ERROR: "USER_DELETE_ERROR",
  USER_DELETE_STATE_RESET: "USER_DELETE_STATE_RESET",

  //BUlk_DELETE
  SET_USERS_FOR_BULK_DELETE: "SET_USERS_FOR_BULK_DELETE",
  USER_BULK_DELETE_START:"USER_BULK_DELETE_START",
  USER_BULK_DELETE_SUCCESS: "USER_BULK_DELETE_SUCCESS",
  USER_BULK_DELETE_ERROR: "USER_BULK_DELETE_ERROR",
  USER_DELETE_STATE_BULK_RESET: "USER_DELETE_STATE_BULK_RESET",

  SET_USER_FOR_UPDATE: "SET_USER_FOR_UPDATE",
  USER_UPDATE_START: "USER_UPDATE_START",
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
  USER_UPDATE_ERROR: "USER_UPDATE_ERROR",
  USER_UPDATE_STATE_RESET: "USER_UPDATE_STATE_RESET",

  SEARCH_USER_START: "SEARCH_USER_START",
  SEARCH_USER_SUCCESS: "SEARCH_USER_SUCCESS",
  SEARCH_USER_ERROR: "SEARCH_USER_ERROR",
  USER_SEARCH_RESET: "USER_SEARCH_RESET",

  GET_UNASSIGNED_CUSTOMERS_START: "GET_UNASSIGNED_CUSTOMERS_START",
  GET_UNASSIGNED_CUSTOMERS_SUCCESS: "GET_UNASSIGNED_CUSTOMERS_SUCCESS",
  GET_UNASSIGNED_CUSTOMERS_ERROR: "GET_UNASSIGNED_CUSTOMERS_ERROR",

  ASSIGN_MANAGER_START: "ASSIGN_MANAGER_START",
  ASSIGN_MANAGER_SUCCESS: "ASSIGN_MANAGER_SUCCESS",
  ASSIGN_MANAGER_ERROR: "ASSIGN_MANAGER_ERROR",
  ASSIGN_MANAGER_RESET: "ASSIGN_MANAGER_RESET",
  
  ASSIGN_SUB_ADMIN_START : "ASSIGN_SUB_ADMIN_START",
  ASSIGN_SUB_ADMIN_SUCCESS : "ASSIGN_SUB_ADMIN_SUCCESS",
  ASSIGN_SUB_ADMIN_ERROR : "ASSIGN_SUB_ADMIN_ERROR",
};

export default types;
