import React from "react";

import styles from "./css/style.module.css";

const Table = (props) => {
	return (
		<div className={`${props.className} ${styles.table_conatiner}`}>
			<table cellSpacing='0' cellPadding='0' className={styles.container}>
				{props.children}
			</table>
		</div>
	);
};

Table.defaultProps = {
	className: "",
	style: "",
};

export default Table;
