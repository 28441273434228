import axios from "axios";

const apiClientFactory = () => {
    const apiClient = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 30000,
        headers: {
            Authorization: `Bearer ${
                localStorage.getItem("userToken") || "no token"
            }`,
        },
    });
    apiClient.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            const statusCode = error.response.status;
            if (statusCode === 401 || statusCode === 403) {
                localStorage.removeItem("userToken");
                localStorage.removeItem("persist:baitti-admin");
                window.location = "/"
                window.location.reload();
            }
            return Promise.reject(error);
        });
    return apiClient;
};

export default apiClientFactory;
