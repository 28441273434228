import React from "react";
import Accordion from "../Accordion/Accordion";
import styles from "./css/styles.module.css";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const MenuList = () => {
    const staffUser = useSelector((state) => state.Auth.staffUser);
    const selectedProject = useSelector(state => state.project.selected_project);
    return (
        <div className={styles.container}>
            {
                selectedProject
                &&
                <Accordion
                    expand
                    className={styles.accordion}
                    header={
                        <p className={`${styles.suUser}`}>
                            <Link to="/dashboard">Dashboard</Link>
                        </p>
                    }
                />
            }
            {staffUser?.type === "admin" && (
                <Accordion
                    expand
                    className={styles.accordion}
                    header={
                        <p className={`${styles.suUser}`}>
                            <Link to="/projects">Projects ({selectedProject ? selectedProject?.name : "Choose Project"})</Link>
                        </p>
                    }
                />
            )}
            {(staffUser?.type === "admin" || staffUser?.type === "sub_admin") && selectedProject && (
                <Accordion
                    expand
                    className={styles.accordion}
                    header={
                        <p className={`${styles.suUser}`}>
                            <Link to="/building">Buildings</Link>
                        </p>
                    }
                />
            )}

            {(staffUser?.type === "admin" || staffUser?.type === "sub_admin") && selectedProject && (
                <Accordion
                    className={styles.accordion}
                    header={
                        <Link to="/villa" className={`${styles.optionHeader} `}>
                            Villas
                        </Link>
                    }
                />
            )}
            {(staffUser?.type === "admin" || staffUser?.type === "sub_admin") && selectedProject && (
                <Accordion
                    className={styles.accordion}
                    header={
                        <Link to="/users" className={`${styles.optionHeader} `}>
                            Users
                        </Link>
                    }
                />
            )}
            {(staffUser?.type === "admin" || staffUser?.type === "sub_admin") && selectedProject && (
                <Accordion
                    className={styles.accordion}
                    header={
                        <Link to="/offer" className={`${styles.optionHeader} `}>
                            Offers
                        </Link>
                    }
                />
            )}
            {(staffUser?.type === "admin" || staffUser?.type === "security" || staffUser?.type === "sub_admin") && selectedProject && (
                <Accordion
                    className={styles.accordion}
                    header={
                        <Link to="/visitorpermits" className={`${styles.optionHeader} `}>
                            Visitor Permits
                        </Link>
                    }
                />
            )}
            {(staffUser?.type === "admin" ||
                staffUser?.type === "maintenance_manager"|| staffUser?.type === "sub_admin") && selectedProject && (
                <Accordion
                    className={styles.accordion}
                    header={
                        <Link
                            to="/maintenancerequests"
                            className={`${styles.optionHeader} `}
                        >
                            Maintenance Requests
                        </Link>
                    }
                />
            )}

        </div>
    );
};

export default MenuList;
