import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";

function* unassignApartmentStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.post,
      endpoints.apartmentUnAssign(action.payload)
    );
    yield put({
      type: types.APARTMENT_UN_ASSIGN_SUCCESS,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.APARTMENT_UN_ASSIGN_ERROR,
      payload,
    });
  }
}
export function* unassignApartmentStartWatcherSaga() {
  yield takeEvery(
    types.APARTMENT_UN_ASSIGN_START,
    unassignApartmentStartHandlerSaga
  );
}

function* assignApartmentStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.put,
      endpoints.apartmentAssign,
      action.payload
    );
    yield put({
      type: types.APARTMENT_ASSIGN_SUCCESS,
      payload: data?.response,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.APARTMENT_ASSIGN_ERROR,
      payload,
    });
  }
}
export function* assignApartmentStartWatcherSaga() {
  yield takeEvery(
    types.APARTMENT_ASSIGN_START,
    assignApartmentStartHandlerSaga
  );
}

function* getApartmentsListStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { page, size, building } = action.payload;
    const { data } = yield call(
      apiClient.get,
      endpoints.apartmentList(page, size, building)
    );
    yield put({
      type: types.GET_APARTMENT_LIST_SUCCESS,
      payload: data.response,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.GET_APARTMENT_LIST_ERROR,
      payload,
    });
  }
}

export function* getApartmentsListStartWatcherSaga() {
  yield takeEvery(
    types.GET_APARTMENT_LIST_START,
    getApartmentsListStartHandlerSaga
  );
}

function* createApartmentStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.post,
      endpoints.apartmentCreate,
      action.payload
    );
    yield put({ type: types.CREATE_APARTMENT_SUCCESS, payload: data.response });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.CREATE_APARTMENT_ERROR,
      payload,
    });
  }
}

export function* createApartmentStartWatcherSaga() {
  yield takeEvery(
    types.CREATE_APARTMENT_START,
    createApartmentStartHandlerSaga
  );
}

function* deleteApartmentStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.delete,
      endpoints.apartmentDelete(action.payload)
    );
    yield put({
      type: types.DELETE_APARTMENT_SUCCESS,
      payload: data?.response || data,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.DELETE_APARTMENT_ERROR,
      payload,
    });
  }
}
export function* deleteApartmentStartWatcherSaga() {
  yield takeEvery(
    types.DELETE_APARTMENT_START,
    deleteApartmentStartHandlerSaga
  );
}

//bulk apt Delete
function* BulkDeleteApartmentStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.post,
      endpoints.BulkAparmentDelete,
      action.payload
    );
    yield put({
      type: types.BULK_DELETE_APARTMENT_SUCCESS,
      payload: data?.response || data,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.BULK_DELETE_APARTMENT_ERROR,
      payload,
    });
  }
}
export function* bulkDeleteApartmentStartWatcherSaga() {
  yield takeEvery(
    types.BULK_DELETE_APARTMENT_START,
    BulkDeleteApartmentStartHandlerSaga
  );
}

function* updateApartmentStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.put,
      endpoints.apartment,
      action.payload
    );
    yield put({
      type: types.UPDATE_APARTMENT_SUCCESS,
      payload: data?.response,
    });
  } catch (e) {
    console.log("Update apartment error");
    const payload = e?.response?.data || e;
    yield put({
      type: types.UPDATE_APARTMENT_ERROR,
      payload,
    });
  }
}

export function* updateApartmentStartWatcherSaga() {
  yield takeEvery(
    types.UPDATE_APARTMENT_START,
    updateApartmentStartHandlerSaga
  );
}
