const types = {
  CREATE_APARTMENT_START: "CREATE_APARTMENT_START",
  CREATE_APARTMENT_SUCCESS: "CREATE_APARTMENT_SUCCESS",
  CREATE_APARTMENT_ERROR: "CREATE_APARTMENT_ERROR",
  RESET_CREATE_APARTMENT_STATE: "RESET_CREATE_APARTMENT_STATE",

  GET_APARTMENT_LIST_START: "GET_APARTMENT_LIST_START",
  GET_APARTMENT_LIST_SUCCESS: "GET_APARTMENT_LIST_SUCCESS",
  GET_APARTMENT_LIST_ERROR: "GET_APARTMENT_LIST_ERROR",

  UPDATE_APARTMENT_START: "UPDATE_APARTMENT_START",
  UPDATE_APARTMENT_SUCCESS: "UPDATE_APARTMENT_SUCCESS",
  UPDATE_APARTMENT_ERROR: "UPDATE_APARTMENT_ERROR",
  RESET_UPDATE_APARTMENT_STATE: "RESET_UPDATE_APARTMENT_STATE",

  SET_APARTMENT_FOR_EDIT: "SET_APARTMENT_FOR_EDIT",

  SET_APARTMENT_FOR_DELETE: "SET_APARTMENT_FOR_DELETE",
  DELETE_APARTMENT_START: "DELETE_APARTMENT_START",
  DELETE_APARTMENT_SUCCESS: "DELETE_APARTMENT_SUCCESS",
  DELETE_APARTMENT_ERROR: "DELETE_APARTMENT_ERROR",
  RESET_APARTMENT_DELETE_STATE: "RESET_APARTMENT_DELETE_STATE",

  //bulk delete

  SET_APARTMENT_FOR_BULK_DELETE: "SET_APARTMENT_FOR_BULK_DELETE",
  BULK_DELETE_APARTMENT_START: "BULK_DELETE_APARTMENT_START",
  BULK_DELETE_APARTMENT_SUCCESS: "BULK_DELETE_APARTMENT_SUCCESS",
  BULK_DELETE_APARTMENT_ERROR: "BULK_DELETE_APARTMENT_ERROR",
  RESET_APARTMENT_BULK_DELETE_STATE: "RESET_APARTMENT_BULK_DELETE_STATE",

  // DELETE LATER AFTER FIXING
  RESET_APARTMENT_SUCCESS_ERROR: "RESET_APARTMENT_SUCCESS_ERROR",

  APARTMENT_ASSIGN_START: "APARTMENT_ASSIGN_START",
  APARTMENT_ASSIGN_SUCCESS: "APARTMENT_ASSIGN_SUCCESS",
  APARTMENT_ASSIGN_ERROR: "APARTMENT_ASSIGN_ERROR",
  APARTMENT_ASSIGN_RESET: "APARTMENT_ASSIGN_RESET",

  APARTMENT_UN_ASSIGN_START: "APARTMENT_UN_ASSIGN_START",
  APARTMENT_UN_ASSIGN_SUCCESS: "APARTMENT_UN_ASSIGN_SUCCESS",
  APARTMENT_UN_ASSIGN_ERROR: "APARTMENT_UN_ASSIGN_ERROR",
  APARTMENT_UN_ASSIGN_RESET: "APARTMENT_UN_ASSIGN_RESET",
};

export default types;
