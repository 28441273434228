import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";

function* getOfferListHandlerSaga(action) {
  const apiClient = apiClientFactory();
  const { page, size , projectId } = action.payload;
  try {
    const { data } = yield call(apiClient.get, endpoints.offerList(page, size , projectId));
    yield put({
      type: types.GET_OFFER_LIST_SUCCESS,
      payload: data.response,
    });
  } catch (e) {
    const payload = e?.response || e;
    yield put({
      type: types.GET_OFFER_LIST_ERROR,
      payload,
    });
  }
}

export function* getOfferListWatcherSaga() {
  yield takeEvery(types.GET_OFFER_LIST_START, getOfferListHandlerSaga);
}
//create offer
function* createOfferHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.post,
      endpoints.createoffer,
      action.payload
    );
    yield put({
      type: types.CREATE_OFFER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: types.CREATE_OFFER_ERROR,
      payload: e.response.data,
    });
  }
}

export function* createOfferWatcherSaga() {
  yield takeEvery(types.CREATE_OFFER_START, createOfferHandlerSaga);
}

//update offre
function* updateOfferHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.put,
      endpoints.updateOffer,
      action.payload
    );
    yield put({
      type: types.UPDATE_OFFER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({
      type: types.UPDATE_OFFER_ERROR,
      payload,
    });
  }
}

export function* updateOfferWatcherSaga() {
  yield takeEvery(types.UPDATE_OFFER_START, updateOfferHandlerSaga);
}

function* deleteOfferHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.delete,
      endpoints.deleteOffer(action.payload)
    );
    yield put({
      type: types.DELETE_OFFER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: types.DELETE_OFFER_ERROR,
      payload: e.response.data,
    });
  }
}

export function* deleteOfferWatcherSaga() {
  yield takeEvery(types.DELETE_OFFER_START, deleteOfferHandlerSaga);
}

function* searchItemStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.get,
      endpoints.offerSearch(action.payload)
    );
    yield put({ type: types.SEARCH_OFFER_SUCCESS, payload: data.response });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({ type: types.SEARCH_OFFER_ERROR, payload });
  }
}

export function* OfferSearchStartWatcherSaga() {
  yield takeEvery(types.SEARCH_OFFER_START, searchItemStartHandlerSaga);
}

//bulk delete
function* bulkDeleteOfferHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.post,
      endpoints.BulkDeleteOffer,
      action.payload
    );
    yield put({
      type: types.BULK_DELETE_OFFER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: types.BULK_DELETE_OFFER_ERROR,
      payload: e.response.data,
    });
  }
}

export function* bulkDeleteOfferWatcherSaga() {
  yield takeEvery(types.BULK_DELETE_OFFER_START, bulkDeleteOfferHandlerSaga);
}
