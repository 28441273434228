import { createSelector } from "reselect";

const selectOffer = (state) => state.offer;

export const selectOfferList = createSelector(
	[selectOffer],
	(offer) => offer.offers,
);
export const selectOfferError = createSelector(
	[selectOffer],
	(offer) => offer.offersError,
);
export const selectOfferLoading = createSelector(
	[selectOffer],
	(offer) => offer.loadingOffers,
);

//create

export const selectCreateOffer = createSelector(
	[selectOffer],
	(offer) => offer.createOffers,
);
export const selectCreateOfferError = createSelector(
	[selectOffer],
	(offer) => offer.createOffersError,
);
export const selectCreateOfferLoading = createSelector(
	[selectOffer],
	(offer) => offer.createOffersloading,
);

//update offer

export const selectUpdateOffer = createSelector(
	[selectOffer],
	(offer) => offer.updateOffer,
);

export const selectUpdateOfferError = createSelector(
	[selectOffer],
	(offer) => offer.updateOfferError,
);
export const selectUpdateOfferLoading = createSelector(
	[selectOffer],
	(offer) => offer.updateOfferloading,
);

//delete

export const selectdeleteOffer = createSelector(
	[selectOffer],
	(offer) => offer.deleteOffer,
);

export const selectdeleteOfferError = createSelector(
	[selectOffer],
	(offer) => offer.deleteOfferError,
);
export const selectdeleteOfferLoading = createSelector(
	[selectOffer],
	(offer) => offer.deleteingOffer,
);

//bulk delete 
export const selectBulkdeleteOffer = createSelector(
	[selectOffer],
	(offer) => offer.BulkDeleteOffer,
);

export const selectBulkdeleteOfferError = createSelector(
	[selectOffer],
	(offer) => offer.BulkDeleteOfferError,
);
export const selectBulkdeleteOfferLoading = createSelector(
	[selectOffer],
	(offer) => offer.BulkDeleteingOffer,
);

//single Item

export const selectSingleOffer = createSelector(
	[selectOffer],
	(offer) => offer.singleOffer,
);

export const selectSearchStart = {
	search: createSelector([selectOffer], (state) => state.search),
	error: createSelector([selectOffer], (state) => state.searchError),
	loading: createSelector([selectOffer], (state) => state.searchLoading),
};
