import types from "./types";

export const getBuildingListStart = (payload) => ({
	type: types.GET_BUILDING_LIST_START,
	payload,
});
export const createBuildingStart = (payload) => ({
	type: types.CREATE_BUILDING_START,
	payload,
});
export const createBuildingStateReset = () => ({
	type: types.CREATE_BUILDING_STATE_RESET,
});
export const getBuildingDetailsStart = (building) => ({
	type: types.GET_BUILDING_DETAILS_START,
	payload: building,
});
export const setBuildingForUpdate = (building) => ({
	type: types.SET_BUILDING_FOR_UPDATE,
	payload: building,
});
export const updateBuildingStart = (payload) => ({
	type: types.UPDATE_BUILDING_START,
	payload,
});
export const buildingUpdateReset = () => ({
	type: types.BUILDING_UPDATE_RESET,
});

//Delete
export const setBuildingToDelete = (payload) => ({
	type: types.SET_BUILDING_FOR_DELETE,
	payload,
});
export const deleteBuildingStart = (payload) => ({
	type: types.DELETE_BUILDING_START,
	payload,
});
export const buildingDeleteStateReset = () => ({
	type: types.BUILDING_DELETE_STATE_RESET,
});

//Bulk Delete
export const setBuildingToBulkDelete = (payload) => ({
	type: types.SET_BUILDING_FOR_BULK_DELETE,
	payload,
});
export const deleteBulkBuildingStart = (payload) => ({
	type: types.BULK_DELETE_BUILDING_START,
	payload,
});
export const buildingBulkDeleteStateReset = () => ({
	type: types.BUILDING_BULK_DELETE_STATE_RESET,
});


export const searchBuildingStart = (payload) => ({
	type: types.SEARCH_BUILDING_START,
	payload,
});

export const searchBuildingReset = () => ({
	type: types.BUILDING_SEARCH_RESET,
});
