import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";

function* commentOnMaintenanceRequestStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.post,
      endpoints.createComment,
      action.payload
    );
    yield put({
      type: types.COMMENT_ON_MAINTENANCE_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const payload = e?.response?.data;
    yield put({
      type: types.COMMENT_ON_MAINTENANCE_REQUEST_ERROR,
      payload,
    });
  }
}
export function* commentOnMaintenanceRequestStartWatcherSaga() {
  yield takeEvery(
    types.COMMENT_ON_MAINTENANCE_REQUEST_START,
    commentOnMaintenanceRequestStartHandlerSaga
  );
}
function* singleMaintenanceRequestStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.get,
      endpoints.maintenanceRequestDetails(action.payload)
    );
    yield put({
      type: types.SINGLE_MAINTENANCE_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const payload = e?.response?.data;
    yield put({
      type: types.SINGLE_MAINTENANCE_REQUEST_SUCCESS,
      payload,
    });
  }
}
export function* singleMaintenanceRequestStartWatcherSaga() {
  yield takeEvery(
    types.SINGLE_MAINTENANCE_REQUEST_START,
    singleMaintenanceRequestStartHandlerSaga
  );
}

function* getMaintenanceRequestListStartHandlerSaga(action) {
  const { page, size, status , search, userId, projectId, building, villa, category, startDate, endDate, reqId, apartment } = action.payload;

  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.get,
      endpoints.maintenanceRequestList(page, size, status, userId, projectId, search, building, villa, category, startDate, endDate, reqId, apartment)
    );

    yield put({
      type: types.GET_MAINTENANCE_REQUEST_LIST_SUCCESS,
      payload: data.response,
    });
  } catch (e) {
    yield put({
      type: types.GET_MAINTENANCE_REQUEST_LIST_ERROR,
      payload: e.response.data,
    });
  }
}

export function* getMaintenanceRequestListStartWatcherSaga() {
  yield takeEvery(
    types.GET_MAINTENANCE_REQUEST_LIST_START,
    getMaintenanceRequestListStartHandlerSaga
  );
}

//create

function* createMaintenanceRequestStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.post,
      endpoints.maintenanceCreate,
      action.payload
    );
    yield put({
      type: types.CREATE_MAINTENANCE_REQUEST_SUCCESS,
      payload: data.response,
    });
  } catch (e) {
    yield put({
      type: types.CREATE_MAINTENANCE_REQUEST_ERROR,
      payload: e.response.data,
    });
  }
}

export function* createMaintenanceRequestStartWatcherSaga() {
  yield takeEvery(
    types.CREATE_MAINTENANCE_REQUEST_START,
    createMaintenanceRequestStartHandlerSaga
  );
}

//update

function* updateMaintenanceRequestStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.put,
      endpoints.maintenanceUpdate,
      action.payload
    );
    yield put({
      type: types.UPDATE_MAINTENANCE_REQUEST_SUCCESS,
      payload: data.response,
    });
  } catch (e) {
    yield put({
      type: types.UPDATE_MAINTENANCE_REQUEST_ERROR,
      payload: e.response.data,
    });
  }
}

export function* updateMaintenanceRequestStartWatcherSaga() {
  yield takeEvery(
    types.UPDATE_MAINTENANCE_REQUEST_START,
    updateMaintenanceRequestStartHandlerSaga
  );
}

//delete

function* deleteMaintenanceRequestStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.delete,
      endpoints.deleteMaintenanceRequest(action.payload)
    );
    yield put({
      type: types.DELETE_MAINTENANCE_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: types.DELETE_MAINTENANCE_REQUEST_ERROR,
      payload: e.response.data,
    });
  }
}

export function* deleteMaintenanceRequestStartWatcherSaga() {
  yield takeEvery(
    types.DELETE_MAINTENANCE_REQUEST_START,
    deleteMaintenanceRequestStartHandlerSaga
  );
}

function* searchItemStartHandlerSaga(action) {
  const apiClient = apiClientFactory();
  try {
    const { data } = yield call(
      apiClient.get,
      endpoints.maintenanceRequestSearch(action.payload)
    );
    yield put({
      type: types.SEARCH_MAINTENANCE_REQUEST_SUCCESS,
      payload: data.response,
    });
  } catch (e) {
    const payload = e?.response?.data || e;
    yield put({ type: types.SEARCH_MAINTENANCE_REQUEST_ERROR, payload });
  }
}

export function* maintenanceRequestSearchStartWatcherSaga() {
  yield takeEvery(
    types.SEARCH_MAINTENANCE_REQUEST_START,
    searchItemStartHandlerSaga
  );
}
