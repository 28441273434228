import styled from "styled-components";


export const Container = styled.div`
    width : 100%;
    display : block;
    margin : 0.5em 0;
`;
export const TabContainer = styled.div`
    display : flex;
    justify-content : space-between;
    align-items : center;
`;
export const Tab = styled.h5`
    text-align: center;
    flex : 1;
    color : white;
    background-color : ${props => props.active ? '#0f4485' : '#1098f7'};
    cursor : pointer;
    user-select : none;
    &:nth-child(1){
        border-radius : 5px 0 0 5px;
    }
    &:nth-child(2){
        border-radius : 0 5px 5px 0;
    }
`;


export const TabContent = styled.div`
    
`;