import React, {useState, useEffect, useRef, useMemo} from "react";
import { Container, Heading, InvalidMode, FormBody } from "./Elements";
import { FormInput, FormLabel, SelectInput } from "../../ui-elements/Input";
import { PrimaryButton } from "../../ui-elements/Button";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";
import { CirclesWithBar } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// importing actions
import {
  singleImageUploadStart,
  singleImageUploadStateReset,
} from "../../store/ImageUpload/actions";
import { createUserStart, updateUserStart } from "../../store/User/actions";

const UserForm = ({ mode }) => {
  const dispatch = useDispatch();
  const imageUpload = useSelector((state) => state.imageUpload);
  const {selected_project} = useSelector(state => state.project);
  const selectedProject = useMemo(() => selected_project , [selected_project]);
  const { singleUploadedImage, singleImageUpdateError, uploadingSingleImage } =
    imageUpload;
  const user = useSelector((state) => state.user);
  const staffUser = useSelector(state => state.Auth.staffUser);
  const navigate = useNavigate();
  const {
    newUser,
    createUserError,
    creatingNewUser,
    userUpdateState,
    userUpdateError,
  } = user;
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    profile_picture: "",
    type: "customer",
    password: "",
    confirm_password: "",
  });
  const [countryCode , setCountryCode] = useState(process.env.REACT_APP_DEFAULT_COUNTRY_CODE);
  const [selectedImage, setSelectedImage] = useState(null);
  function userFormSubmitHandler(e) {
    if (mode === "create") {
      if (formData.type === "customer") formData.is_primary = 1;
      dispatch(createUserStart({...formData , phone: `${countryCode}-${formData.phone}` , projectId : selectedProject?.id}));
    } else {
      formData.id = user?.userToUpdate?.id;
      dispatch(updateUserStart({...formData , phone: `${countryCode}-${formData.phone}` , projectId : selectedProject?.id}));
    }
  }
  useEffect(() => {
    if (newUser) {
      if (window.location.pathname.includes("users")){
        navigate(`/users/${newUser.response.dataValues.id}`, { return: true });
      }
    }
  }, [newUser]);

  useEffect(() => {
    if (user?.userToUpdate) {
      setFormData({
        name: user?.userToUpdate?.name || null,
        phone: user?.userToUpdate?.phone || null,
        email: user?.userToUpdate?.email || null,
        profile_picture: user?.userToUpdate?.profile_picture || null,
        type: "customer",
        password: user?.userToUpdate?.password || null,
        confirm_password: "",
      });
    } else {
      setFormData({
        name: "",
        phone: "",
        email: "",
        profile_picture: "",
        type: "customer",
        password: "",
        confirm_password: "",
      });
    }
  }, [user?.userToUpdate]);
  useEffect(() => {
    if (createUserError) {
      if (Array.isArray(createUserError?.response)) {
        const errorList = createUserError?.response;
        errorList.forEach((error) => {
          const keys = Object.keys(error);
          keys.forEach((key) => {
            toast.error(error[key]);
          });
        });
        if (createUserError?.response?.length === 0) {
          toast.error(createUserError?.message);
        }
      } else {
        toast.error(JSON.stringify(createUserError));
      }
    }
  }, [createUserError]);
  useEffect(() => {
    if (userUpdateError) {
      if (Array.isArray(userUpdateError?.response)) {
        user?.userUpdateError?.response?.forEach((err) => {
          const keys = Object.keys(err);
          keys.forEach((key) => {
            toast.error(`${key} : ${err[key]}`);
          });
        });
      }
    }
  }, [userUpdateError]);
  useEffect(() => {
    if (user?.userUpdate) {
      toast.success("User updated successfully");
    }
  }, [user?.userUpdate]);

  useEffect(() => {
    if (singleUploadedImage) {
      // toast.success("Image Uploaded successfully");
      setFormData({
        ...formData,
        profile_picture: singleUploadedImage?.response?.files[0]?.path,
      });
    }
  }, [singleUploadedImage]);

  if (mode !== "create" && mode !== "edit")
    return <InvalidMode>Invalid mode for User Form</InvalidMode>;
  return (
    <Container>
      <Heading>{mode === "create" && `Register New User`}</Heading>
      <FormBody>
        <FormLabel>Full Name*</FormLabel>
        <FormInput
          width="500px"
          disabled={uploadingSingleImage}
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
        {mode === "create" && <hr />}
        {mode === "create" && <FormLabel>Phone Number*</FormLabel>}
        {mode === "create" && (
          <div
            style={{ display : 'flex' , alignItems : 'center' , gap : 5 }}
          >
            <FormInput
                width={"80px"}
                disabled={uploadingSingleImage}
                value={countryCode}
                onChange={e=> setCountryCode(e.target.value)}
            />

            <FormInput
                width="400px"
                disabled={uploadingSingleImage}
                value={formData.phone}
                onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                }
                type='text'
            />
          </div>
        )}
        <hr />
        <FormLabel>Email</FormLabel>
        <FormInput
          width="500px"
          disabled={uploadingSingleImage}
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
        <hr />
        <FormLabel>Proflie Picture</FormLabel>
        {formData?.profile_picture && (
          <>
            <img
              style={{
                height: "400px",
                width: "400px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              src={
                formData?.profile_picture?.includes("http")
                  ? formData?.profile_picture
                  : process.env.REACT_APP_IMAGE_BASE_PATH +
                    formData?.profile_picture
              }
            />
            <br />
          </>
        )}
        {uploadingSingleImage && (
          <>
            <Circles width={30} /> <br />
          </>
        )}
        <FormInput
          type="file"
          width="500px"
          disabled={uploadingSingleImage}
          onChange={(e) => {
            if (e.target.files.length > 0) {
              setSelectedImage(e.target.files[0]);
              const multipartFormData = new FormData();
              multipartFormData.append("image", e.target.files[0]);
              dispatch(singleImageUploadStart(multipartFormData));
            }
          }}
          accept="image/*"
        />
        <hr />

        {mode === "create" && (
          <>
            <FormLabel>Type of User*</FormLabel>
            <SelectInput
              width="510px"
              disabled={uploadingSingleImage}
              value={formData.type}
              onChange={(e) =>
                setFormData({ ...formData, type: e.target.value })
              }
            >
              {
                window.location.pathname.includes("assign")
                  ? <option value="customer">Customer</option>
                  : (
                    staffUser?.type === 'admin'
                      ? <>
                          <option value="customer">Customer</option>
                          <option value="security">Security</option>
                          <option value="maintenance_manager">Maintenance Manager</option>
                          {/*<option value="admin">Admin</option>*/}
                          <option value="sub_admin">Sub Admin</option>
                        </>
                        : <>
                          <option value="customer">Customer</option>
                          <option value="security">Security</option>
                          <option value="maintenance_manager">Maintenance Manager</option>
                        </>
                  )
              }
            </SelectInput>
          </>
        )}
        {formData?.type !== "customer" && (
          <>
            {mode === "create" && <hr />}
            {mode === "create" && <FormLabel>Password*</FormLabel>}
            {mode === "create" && (
              <FormInput
                width="500px"
                disabled={uploadingSingleImage}
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
            )}
            <p
            style={{
              color: 'grey',
              padding: '5px',
              fontSize: '0.6em',
              width:'510px'
            }} 
            >* Password must be of length between 8 and 20 characters,
                numbers and letters only,
                no spaces or other special characters allowed.
            </p>
            {/* {mode === "create" && <hr />} */}
            {/* {mode === "create" && <FormLabel>Confirm Password*</FormLabel>} */}
            {/* {mode === "create" && (
              <FormInput
                width="500px"
                disabled={uploadingSingleImage}
                value={formData.confirm_password}
                onChange={(e) =>
                  setFormData({ ...formData, confirm_password: e.target.value })
                }
              />
            )} */}
            {mode === "create" && <hr />}
          </>
        )}
        <hr />
        {creatingNewUser || userUpdateState ? (
          <CirclesWithBar
            height={40}
            color={"blue"}
            style={{ margin: "0 auto", display: "block" }}
          />
        ) : (
          <PrimaryButton
            disabled={uploadingSingleImage}
            width="300px"
            onClick={userFormSubmitHandler}
          >
            {mode === "create" ? `Register` : "Update User"}
          </PrimaryButton>
        )}

        <hr />
      </FormBody>
    </Container>
  );
};

export default UserForm;
