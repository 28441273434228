import types from "./types";

const INITIAL_STATE = {
  villas: null,
  loadingVillas: true,
  villasError: null,

  newVilla: null,
  creatingNewVilla: false,
  villaCreationError: null,

  updateVilla: false,
  updatVillaLodaing: false,
  updateError: null,

  deletedVilla: null,
  deleteVillaLodaing: false,
  deleteError: null,

  bulkDeletedVilla: null,
  bulkDeleteVillaLodaing: false,
  bulkDeleteError: null,
  deletedAllVilla:null,

  singleVilla: null,
  singleVillaLodaing: false,
  singleVillaError: null,

  search: null,
  searchError: null,
  searchLoading: false,

  villaAssigned: false,
  assigningVilla: false,
  villaAssignError: null,

  villaResidentUnAssigned: false,
  unAssigningVillaResident: false,
  villaResidentUnAssignError: null,
};

const VillaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.VILLA_UNASSIGN_RESIDENT_START: {
      return {
        ...state,
        villaResidentUnAssigned: false,
        unAssigningVillaResident: true,
        villaResidentUnAssignError: null,
      };
    }
    case types.VILLA_UNASSIGN_RESIDENT_SUCCESS: {
      return {
        ...state,
        villaResidentUnAssigned: true,
        unAssigningVillaResident: false,
        villaResidentUnAssignError: null,
      };
    }
    case types.VILLA_UNASSIGN_RESIDENT_ERROR: {
      return {
        ...state,
        villaResidentUnAssigned: false,
        unAssigningVillaResident: false,
        villaResidentUnAssignError: action.payload,
      };
    }
    case types.VILLA_UNASSIGN_RESIDENT_RESET: {
      return {
        ...state,
        villaResidentUnAssigned: false,
        unAssigningVillaResident: false,
        villaResidentUnAssignError: null,
      };
    }
    case types.VILLA_ASSIGN_RESET: {
      return {
        ...state,
        villaAssigned: false,
        assigningVilla: false,
        villaAssignError: null,
      };
    }
    case types.VILLA_ASSIGN_START: {
      return {
        ...state,
        villaAssigned: false,
        assigningVilla: true,
        villaAssignError: null,
      };
    }
    case types.VILLA_ASSIGN_SUCCESS: {
      return {
        ...state,
        villaAssigned: true,
        assigningVilla: false,
        villaAssignError: null,
      };
    }
    case types.VILLA_ASSIGN_ERROR: {
      return {
        ...state,
        villaAssigned: false,
        assigningVilla: false,
        villaAssignError: action.payload,
      };
    }
    // VILLA LIST
    case types.GET_VILLA_LIST_START: {
      return {
        ...state,
        villas: null,
        loadingVillas: true,
        villasError: null,
      };
    }
    case types.GET_VILLA_LIST_SUCCESS: {
      return {
        ...state,
        villas: action.payload,
        loadingVillas: false,
      };
    }
    case types.GET_VILLA_LIST_ERROR: {
      return {
        ...state,
        loadingVillas: false,
        villasError: action.payload,
      };
    }

    // CREATE NEW VILLA
    case types.CREATE_VILLA_START: {
      return {
        ...state,
        newVilla: null,
        creatingNewVilla: true,
        villaCreationError: null,
      };
    }
    case types.CREATE_VILLA_SUCCESS: {
      return {
        ...state,
        newVilla: action.payload,
        creatingNewVilla: false,
        villaCreationError: null,
      };
    }
    case types.CREATE_VILLA_ERROR: {
      return {
        ...state,
        creatingNewVilla: false,
        villaCreationError: action.payload,
      };
    }
    case types.CREATE_VILLA_STATE_RESET: {
      return {
        ...state,
        newVilla: null,
        creatingNewVilla: false,
        villaCreationError: null,
      };
    }

    //villa update
    case types.UPDATE_VILLA_START: {
      return {
        ...state,
        updateVilla: false,
        updatVillaLodaing: true,
        updateError: null,
      };
    }
    case types.UPDATE_VILLA_SUCCESS: {
      return {
        ...state,
        updateVilla: action.payload,
        updatVillaLodaing: false,
        updateError: null,
      };
    }
    case types.UPDATE_VILLA_ERROR: {
      return {
        ...state,
        updatVillaLodaing: false,
        updateError: action.payload,
      };
    }
    case types.UPDATE_VILLA_STATE_RESET: {
      return {
        ...state,
        updateVilla: false,
        updatVillaLodaing: false,
        updateError: null,
      };
    }

    //villa delete
    case types.DELETE_VILLA_START: {
      return {
        ...state,
        deletedVilla: null,
        deleteVillaLodaing: true,
        deleteError: null,
      };
    }
    case types.DELETE_VILLA_SUCCESS: {
      return {
        ...state,
        deletedVilla: action.payload,
        deleteVillaLodaing: false,
        deleteError: null,
      };
    }
    case types.DELETE_VILLA_ERROR: {
      return {
        ...state,
        deleteVillaLodaing: false,
        deleteError: action.payload,
      };
    }
    case types.DELETE_VILLA_STATE_RESET: {
      return {
        ...state,
        deletedVilla: null,
        deleteVillaLodaing: false,
        deleteError: null,
      };
    }

    //villa Bulk Delete
    case types.DELETE_BULK_VILLA_START: {
      return {
        ...state,
        deletedAllVilla: null,
        bulkDeletedVilla: null,
        bulkDeleteVillaLodaing: true,
        bulkDeleteError: null,

      };
    }
    case types.DELETE_BULK_VILLA_SUCCESS: {
      return {
        ...state,
        deletedAllVilla:action.payload,
        bulkDeletedVilla: action.payload,
        bulkDeleteVillaLodaing: false,
        bulkDeleteError: null,
        
      };
    }
    case types.DELETE_BULK_VILLA_ERROR: {
      return {
        ...state,
        deletedAllVilla:false,
        bulkDeleteVillaLodaing: false,
        bulkDeleteError: action.payload,
      };
    }
    case types.DELETE_BULK_VILLA_STATE_RESET: {
      return {
        ...state,
        bulkDeletedVilla: null,
        bulkDeleteVillaLodaing: false,
        bulkDeleteError: null,

      };
    }

    //villa single item
    case types.GET_SINGLE_VILLA_START: {
      return {
        ...state,
        singleVilla: null,
        singleVillaLodaing: true,
        singleVillaError: null,
      };
    }
    case types.GET_SINGLE_VILLA_SUCCESS: {
      return {
        ...state,
        singleVilla: action.payload,
        singleVillaLodaing: false,
        singleVillaError: null,
      };
    }
    case types.GET_SINGLE_VILLA_ERROR: {
      return {
        ...state,
        singleVilla: null,
        singleVillaLodaing: false,
        singleVillaError: action.payload,
      };
    }
    //search
    case types.SEARCH_VILLA_START: {
      return {
        ...state,
        search: null,
        searchError: null,
        searchLoading: true,
      };
    }
    case types.SEARCH_VILLA_ERROR: {
      return {
        ...state,
        searchError: action.payload,
        searchLoading: false,
      };
    }
    case types.SEARCH_VILLA_SUCCESS: {
      return {
        ...state,
        search: action.payload,
        searchLoading: false,
      };
    }
    case types.VILLA_SEARCH_RESET: {
      return {
        ...state,
        search: null,
        searchError: null,
        searchLoading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default VillaReducer;
