import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";

// API CALL FOR BUILDING LIST
function* getBuildingsListHandlerSaga(action) {
	const apiClient = apiClientFactory();
	const { page, size, projectId } = action.payload;
	try {
		const { data } = yield call(
			apiClient.get,
			endpoints.buildingList(page, size, projectId),
		);
		yield put({
			type: types.GET_BUILDING_LIST_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		yield put({
			type: types.GET_BUILDING_LIST_ERROR,
			payload: e.response.data,
		});
	}
}

export function* getBuildingsListWatcherSaga() {
	yield takeEvery(types.GET_BUILDING_LIST_START, getBuildingsListHandlerSaga);
}

// API CALL FOR CREATE NEW BUILDING
function* createNewBuildingHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.post,
			endpoints.createBuilding,
			action.payload,
		);
		yield put({
			type: types.CREATE_BUILDING_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		yield put({
			type: types.CREATE_BUILDING_ERROR,
			payload: e.response.data,
		});
	}
}

export function* createNewBuildingWatcherSaga() {
	yield takeEvery(types.CREATE_BUILDING_START, createNewBuildingHandlerSaga);
}

// API CALL FOR BUILDING DETAILS
// function* getBuildingDetailsStartHandlerSaga(action) {
//   const apiClient = apiClientFactory();
//   try {
//     const { data } = yield call(
//       apiClient.post,
//       endpoints.buildingCreate,
//       action.payload
//     );
//     yield put({ type: types.CREATE_BUILDING_SUCCESS, payload: data.response });
//   } catch (e) {
//     const payload = e?.response?.data || e;
//     yield put({ type: types.CREATE_BUILDING_ERROR, payload });
//   }
// }

// export function* getBuildingDetailsWatcherSaga() {
//   yield takeEvery(
//     types.GET_BUILDING_DETAILS_START,
//     getBuildingDetailsStartHandlerSaga
//   );
// }

//API CALL FOR EDITING BUILDING DETAILS
function* putBuildingDetailsStartHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.put,
			endpoints.building,
			action.payload,
		);
		yield put({ type: types.UPDATE_BUILDING_SUCCESS, payload: data.response });
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({ type: types.UPDATE_BUILDING_ERROR, payload });
	}
}

export function* putBuildingDetailsWatcherSaga() {
	yield takeEvery(
		types.UPDATE_BUILDING_START,
		putBuildingDetailsStartHandlerSaga,
	);
}

//API CALL TO DELETE A BUILDING
function* deleteBuildingStartHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endpoints.buildingDelete(action.payload),
		);
		yield put({ type: types.DELETE_BUILDING_SUCCESS, payload: data.response });
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({ type: types.DELETE_BUILDING_ERROR, payload });
	}
}

export function* deleteBuildingStartWatcherSaga() {
	yield takeEvery(types.DELETE_BUILDING_START, deleteBuildingStartHandlerSaga);
}

// bulk Buildings delete

function* bulkDeleteBuildingStartHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(

			apiClient.post,
			endpoints.bulkBuildingDelete,
			action.payload
		);
		console.log(data, "saga bulk delete build");
		yield put({ type: types.BULK_DELETE_BUILDING_SUCCESS, payload: data });
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({ type: types.BULK_DELETE_BUILDING_ERROR, payload });
	}
}

export function* bulkDeleteBuildingStartWatcherSaga() {
	yield takeEvery(types.BULK_DELETE_BUILDING_START, bulkDeleteBuildingStartHandlerSaga);
}

function* searchItemStartHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endpoints.searchBuilding(action.payload),
		);
		yield put({ type: types.SEARCH_BUILDING_SUCCESS, payload: data.response });
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({ type: types.SEARCH_BUILDING_ERROR, payload });
	}
}

export function* BuildingSearchStartWatcherSaga() {
	yield takeEvery(types.SEARCH_BUILDING_START, searchItemStartHandlerSaga);
}




