const types = {
	//update admin
	UPDATE_ADMIN_START: "UPDATE_ADMIN_START",
	UPDATE_ADMIN_SUCCESS: "UPDATE_ADMIN_SUCCESS",
	UPDATE_ADMIN_ERROR: "UPDATE_ADMIN_ERROR",
	RESET_UPDATE_ADMIN: "RESET_UPDATE_ADMIN",
	//password Change
	PASSWORD_RESET_START: "PASSWORD_RESET_START",
	PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
	PASSWORD_RESET_ERROR: "PASSWORD_RESET_ERROR",
	RESET_PASSWORD_RESET: "RESET_PASSWORD_RESET",
};

export default types;
