import React from "react";
import styles from "./css/style.module.css";

const Footer = () => {
	return (
		<div className={styles.container}>
			<p>All reserved 2024 ADAR</p>
		</div>
	);
};

export default Footer;
