import { createSelector } from "reselect";

const selectAuth = (state) => state.Auth;

export const selectGuestToken = createSelector(
  [selectAuth],
  (auth) => auth.guestToken
);
export const selectGuestTokenLoading = createSelector(
  [selectAuth],
  (auth) => auth.guestTokenLoading
);
export const selectGuestTokenError = createSelector(
  [selectAuth],
  (auth) => auth.guestTokenError
);

export const selectStaffuser = createSelector(
  [selectAuth],
  (auth) => auth.staffUser
);
export const selectStaffLoginLoading = createSelector(
  [selectAuth],
  (auth) => auth.staffLoginLoading
);
export const selectStaffLoginError = createSelector(
  [selectAuth],
  (auth) => auth.staffLoginError
);
