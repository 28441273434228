import React from "react";
import styles from "./css/style.module.css";
import { MdMenu } from "react-icons/md";
import { Dropdown } from "../";
// import { Notification } from "../";
import useScreenSize from "../../util/useScreenSize";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// importing actions
import { staffLogout } from "../../store/Auth/actions";

const Navbar = ({ fullScreen, setScreen }) => {
  const screen = useScreenSize();
  const dispatch = useDispatch();
  const staffUser = useSelector((state) => state.Auth.staffUser);
  const selectedProject = useSelector(state => state?.project?.selected_project);
  const AdminProfile = useSelector((state) => state.AdminProfile);

  function profile_picture() {
    if (AdminProfile?.update?.response) {
      return (
        process.env.REACT_APP_IMAGE_BASE_PATH +
        AdminProfile?.update?.response?.profile_picture
      );
    } else {
      return staffUser?.profile_picture
        ? staffUser?.profile_picture?.includes("http")
          ? staffUser?.profile_picture
          : process.env.REACT_APP_IMAGE_BASE_PATH + staffUser?.profile_picture
        : `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${staffUser?.name?.replace(
          " ",
          "+"
        )}`;
    }
  }

  return (
    <nav className={styles.container}>
      <div className={styles.navbar}>
        {screen < 450 ? (
          <MdMenu
            onClick={() => {
              setScreen(!fullScreen);
            }}
          />
        ) : (
          ""
        )}
        <div>
          <Link to="/dashboard">
            <img
              className={styles.navbar_icon}
              src="/image/app-icon.png"
              alt="admin-logo"
              onClick={() => {
                console.log(12345678);
              }}
            />
          </Link>
        </div>

        {/* <SearchBox style={{ width: "40%", marginLeft: "auto" }} /> */}

        <ul className={styles.nav} style={{ width: "fit-content" }}>
          {
            selectedProject
              ? <li className={styles.selected_project}>{selectedProject?.name}</li>
              : <li className={styles.selected_project}>No Project Selected</li>

          }
          {staffUser?.type === "admin" && (
            <li className={styles.user_type}>Admin</li>
          )}
          {staffUser?.type === "security" && (
            <li className={styles.user_type}>Security</li>
          )}
          {staffUser?.type === "maintenance_manager" && (
            <li className={styles.user_type}>Maintenance Manager</li>
          )}
          {staffUser?.type === "sub_admin" && (
            <li className={styles.user_type}>Sub Admin</li>
          )}
          {/* <li className={styles.nav_item}>
                        <Dropdown
                          left
                          title={
                            <>
                              <MdNotificationsNone className={styles.notification} />
                              <span className={styles.notification_counter}>5</span>
                            </>
                          }
                        >
                          <Notification title="Alerts Center" footer="Show all alerts">
                            <Notification.Item>
                              <Notification.Icon icon="/logo192.png" />
                              <Notification.Message>
                                <p className="m-0" style={{ fontSize: "8px" }}>
                                  23-jun-2022
                                </p>
                                <p className="m-0">thie is message</p>
                              </Notification.Message>
                            </Notification.Item>
                            <Notification.Item>
                              <Notification.Icon icon="/logo192.png" />
                              <Notification.Message>
                                <p className="m-0" style={{ fontSize: "8px" }}>
                                  23-jun-2022
                                </p>
                                <p className="m-0">thie is message</p>
                              </Notification.Message>
                            </Notification.Item>
                          </Notification>
                        </Dropdown>
                      </li>
                      <li className={styles.nav_item}>
                        <Dropdown
                          left
                          title={<MdOutlineSettings className={styles.notification} />}
                        >
                          <Notification title="Message Center" footer="Show all alerts">
                            <Notification.Item>
                              <Notification.Icon icon="/logo192.png" />
                              <Notification.Message>
                                <p className="m-0" style={{ fontSize: "8px" }}>
                                  23-jun-2022
                                </p>
                                <p className="m-0">thie is message</p>
                              </Notification.Message>
                            </Notification.Item>
                          </Notification>
                        </Dropdown>
                      </li> */}
          <li className={styles.nav_item}>
            <Dropdown
              left
              title={
                <div className={styles.profileContainer}>
                  <img
                    className={styles.profilepic}
                    src={profile_picture()}
                    alt=""
                  />
                </div>
              }
            >
              <Dropdown.Item>
                <Link to={"/user-profile"} className={styles.admindropdownItem}>
                  Profile
                </Link>
              </Dropdown.Item>

              <Dropdown.Item>
                <Link
                  className={styles.admindropdownItem}
                  to={"/"}
                  onClick={() => {
                    window.localStorage.clear();
                    // window.localStorage.removeItem("filterOptions");
                    dispatch(staffLogout())
                  }}
                >
                  Logout
                </Link>
              </Dropdown.Item>
            </Dropdown>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
