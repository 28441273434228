import React from "react";
import style from "./css/style.module.css";

const Card = (props) => {
	return (
		<div
			className={`${style.container} ${props.className}`}
			style={props.style}>
			{props.children}
		</div>
	);
};

export default Card;

Card.defaultProps = {
	className: "",
	styles: "",
};
