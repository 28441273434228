import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";
function* getFamilyMemberSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.get,
			endpoints.familyMember(action.payload),
		);
		yield put({
			type: types.GET_FAMILY_MEMBER_SUCCESS,
			payload: data.response,
		});
	} catch (e) {
		yield put({
			type: types.GET_FAMILY_MEMBER_ERROR,
			payload: e,
		});
	}
}

export function* getFamilyMemberWatcherSaga() {
	yield takeEvery(types.GET_FAMILY_MEMBER_START, getFamilyMemberSaga);
}

//create family member

function* createNewFamilyMemberHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.post,
			endpoints.createFamilyMember,
			action.payload,
		);
		yield put({
			type: types.CREATE_FAMILY_MEMBER_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.CREATE_FAMILY_MEMBER_ERROR,
			payload,
		});
	}
}

export function* createNewFamilyMemberWatcherSaga() {
	yield takeEvery(
		types.CREATE_FAMILY_MEMBER_START,
		createNewFamilyMemberHandlerSaga,
	);
}

//delete family member

function* deleteNewFamilyMemberHandlerSaga(action) {
	const apiClient = apiClientFactory();
	try {
		const { data } = yield call(
			apiClient.delete,
			endpoints.deleteFamilyMember(action.payload),
		);
		yield put({
			type: types.DELETE_FAMILY_MEMBER_SUCCESS,
			payload: data,
		});
	} catch (e) {
		const payload = e?.response?.data || e;
		yield put({
			type: types.DELETE_FAMILY_MEMBER_ERROR,
			payload,
		});
	}
}

export function* deleteNewFamilyMemberWatcherSaga() {
	yield takeEvery(
		types.DELETE_FAMILY_MEMBER_START,
		deleteNewFamilyMemberHandlerSaga,
	);
}
