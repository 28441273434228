import types from "./types";

const INITIAL_STATE = {
	search: null,
	searchLoading: false,
	searchError: null,
};

const SearchReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		// VILLA LIST
		case types.SET_SEARCH_TEXT_START: {
			return {
				...state,
				search: null,
				searchLoading: false,
				searchError: null,
			};
		}
		case types.SET_SEARCH_TEXT_SUCCESS: {
			return {
				...state,
				search: action.payload,
				searchLoading: false,
				searchError: null,
			};
		}
		case types.SET_SEARCH_TEXT_RESET: {
			return {
				...state,
				search: null,
				searchLoading: false,
				searchError: null,
			};
		}

		default: {
			return {
				...state,
			};
		}
	}
};

export default SearchReducer;
