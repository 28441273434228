import types from "./types";

const INITIAL_STATE = {
  newApartment: null,
  creatingApartment: false,
  createApartmentError: null,

  apartmentList: null,
  loadingApartments: false,
  apartmentListError: null,

  apartmentUnderEdit: null,
  apartmentUpdateSuccess: null,
  updatingApartment: false,
  updateApartmentError: null,

  apartmentToDelete: null,
  apartmentDeleteSuccess: false,
  deletingApartment: false,
  deleteApartmentError: null,

  BulkApartmentToDelete: null,
  BulkApartmentDeleted: null,
  BulkDeletingApartment: false,
  BulkDeleteApartmentError: null,

  apartmentAssigned: null,
  assigningApartment: false,
  apartmentAssignmentError: null,

  apartmentUnassigned: false,
  unassigningApartment: false,
  apartmentUnassignmentError: null,
};

const apartmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.APARTMENT_UN_ASSIGN_START: {
      return {
        ...state,
        apartmentUnassigned: false,
        unassigningApartment: true,
        apartmentUnassignmentError: null,
      };
    }
    case types.APARTMENT_UN_ASSIGN_SUCCESS: {
      return {
        ...state,
        apartmentUnassigned: true,
        unassigningApartment: false,
        apartmentUnassignmentError: null,
      };
    }
    case types.APARTMENT_UN_ASSIGN_ERROR: {
      return {
        ...state,
        apartmentUnassigned: false,
        unassigningApartment: false,
        apartmentUnassignmentError: action.payload,
      };
    }
    case types.APARTMENT_UN_ASSIGN_RESET: {
      return {
        ...state,
        apartmentUnassigned: false,
        unassigningApartment: false,
        apartmentUnassignmentError: null,
      };
    }
    case types.APARTMENT_ASSIGN_RESET: {
      return {
        ...state,
        apartmentAssigned: null,
        assigningApartment: false,
        apartmentAssignmentError: null,
      };
    }
    case types.APARTMENT_ASSIGN_START: {
      return {
        ...state,
        apartmentAssigned: null,
        assigningApartment: true,
        apartmentAssignmentError: null,
      };
    }
    case types.APARTMENT_ASSIGN_SUCCESS: {
      return {
        ...state,
        apartmentAssigned: action.payload,
        assigningApartment: false,
        apartmentAssignmentError: null,
      };
    }
    case types.APARTMENT_ASSIGN_ERROR: {
      return {
        ...state,
        apartmentAssigned: null,
        assigningApartment: false,
        apartmentAssignmentError: action.payload,
      };
    }
    case types.RESET_UPDATE_APARTMENT_STATE: {
      return {
        ...state,
        apartmentUnderEdit: null,
        updatingApartment: false,
        updateApartmentError: null,
        apartmentUpdateSuccess: null,
      };
    }
    case types.UPDATE_APARTMENT_START: {
      return {
        ...state,
        apartmentUpdateSuccess: null,
        updatingApartment: true,
        updateApartmentError: null,
      };
    }
    case types.UPDATE_APARTMENT_SUCCESS: {
      return {
        ...state,
        apartmentUpdateSuccess: action.payload,
        updatingApartment: false,
      };
    }
    case types.UPDATE_APARTMENT_ERROR: {
      return {
        ...state,
        updatingApartment: false,
        updateApartmentError: action.payload,
      };
    }
    case types.DELETE_APARTMENT_START: {
      return {
        ...state,
        apartmentDeleteSuccess: false,
        deletingApartment: true,
        deleteApartmentError: null,
      };
    }
    case types.DELETE_APARTMENT_SUCCESS: {
      return {
        ...state,
        apartmentDeleteSuccess: action.payload,
        deletingApartment: false,
        deleteApartmentError: null,
      };
    }
    case types.DELETE_APARTMENT_ERROR: {
      return {
        ...state,
        apartmentDeleteSuccess: false,
        deletingApartment: false,
        deleteApartmentError: action.payload,
      };
    }
    case types.RESET_APARTMENT_DELETE_STATE: {
      return {
        ...state,
        apartmentDeleteSuccess: false,
        deletingApartment: false,
        deleteApartmentError: null,
      };
    }

    //bulk delete
    case types.SET_APARTMENT_FOR_BULK_DELETE: {
      return {
        ...state,
        BulkApartmentToDelete: action.payload
      };
    }
    case types.BULK_DELETE_APARTMENT_START: {
      return {
        ...state,
        BulkApartmentToDelete:null,
        BulkApartmentDeleteSuccess: false,
        BulkDeletingApartment: true,
        BulkDeleteApartmentError: null,
      };
    }
    case types.BULK_DELETE_APARTMENT_SUCCESS: {
      return {
        ...state,
        BulkApartmentToDelete:action.payload,
        BulkApartmentDeleteSuccess: action.payload,
        BulkDeletingApartment: false,
        BulkDeleteApartmentError: null,
      };
    }
    case types.BULK_DELETE_APARTMENT_ERROR: {
      return {
        ...state,
        BulkApartmentDeleteSuccess: false,
        BulkDeletingApartment: false,
        BulkDeleteApartmentError: action.payload,
      };
    }
    case types.RESET_APARTMENT_BULK_DELETE_STATE: {
      return {
        ...state,
        BulkApartmentToDelete:null,
        BulkApartmentDeleteSuccess: false,
        BulkDeletingApartment: false,
        BulkDeleteApartmentError: null,
      };
    }

  
    case types.RESET_CREATE_APARTMENT_STATE: {
      return {
        ...state,
        newApartment: null,
        creatingApartment: false,
        createApartmentError: null,
      };
    }
    case types.CREATE_APARTMENT_START: {
      return {
        ...state,
        newApartment: null,
        creatingApartment: true,
        createApartmentError: null,
      };
    }
    case types.CREATE_APARTMENT_SUCCESS: {
      return {
        ...state,

        newApartment: action.payload,
        creatingApartment: false,
        createApartmentError: null,
      };
    }
    case types.CREATE_APARTMENT_ERROR: {
      return {
        ...state,
        newApartment: null,
        creatingApartment: false,
        createApartmentError: action.payload,
      };
    }
    case types.SET_APARTMENT_FOR_DELETE: {
      return {
        ...state,
        apartmentToDelete: action.payload,
      };
    }
    case types.SET_APARTMENT_FOR_EDIT: {
      return {
        ...state,
        apartmentUnderEdit: action.payload,
      };
    }
    case types.GET_APARTMENT_LIST_START: {
      return {
        ...state,
        apartmentList: null,
        loadingApartments: true,
        apartmentListError: null,
      };
    }
    case types.GET_APARTMENT_LIST_SUCCESS: {
      return {
        ...state,
        apartmentList: action.payload,
        loadingApartments: false,
        apartmentListError: null,
      };
    }
    case types.GET_APARTMENT_LIST_ERROR: {
      return {
        ...state,
        apartmentList: null,
        loadingApartments: false,
        apartmentListError: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default apartmentReducer;
