import React, { useEffect, useState } from "react";
import style from "./css/style.module.css";

const ProfileThumbnail = ({ size, img }) => {
	const [picsize, setSize] = useState({ height: "", width: "" });

	useEffect(() => {
		switch (size) {
			case "sm":
				setSize({ height: "100px", width: "100px" });
				break;
			case "md":
				setSize({ height: "120px", width: "120px" });
				break;
			case "lg":
				setSize({ height: "150px", width: "150px" });
				break;
			default:
				setSize({ height: "80px", width: "80px" });
		}
	}, [size]);

	return (
		<div style={picsize} className={style.container}>
			<img src={img} className={style.img} alt='profile' />
		</div>
	);
};

export default ProfileThumbnail;
