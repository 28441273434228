import React from "react";
import styles from "./css/styles.module.css";

const Button = (props) => {
	return (
		<>
			<button
				disabled={props.disabled}
				type={props.type}
				className={`${styles.btn} ${props.className} ${
					props.variant === "dark" ? styles.darkbtn : styles.bluebtn
				}

                    `}
				style={props.style}
				onClick={props.onClick}>
				{props.value || props.children}
			</button>
		</>
	);
};

Button.defaultProps = {
	className: "",
	styles: "",
};

export default Button;
