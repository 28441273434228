import { call, put, takeEvery } from "redux-saga/effects";
import types from "./types";
import apiClientFactory from "../../backend/apiClient";
import endpoints from "../../backend/endpoints";


function* sendBulkNotificationsHandlerSaga(action){
    const apiClient = apiClientFactory();
    try{
        const { data } = yield call(
            apiClient.post,
            endpoints.sendNotification,
            action.payload
        );
        yield put({
            type: types.SEND_BULK_NOTIFICATIONS_SUCCESS,
            payload: data,
        });
    }catch(e){
        const payload = e?.response || e;
        yield put({
            type: types.SEND_BULK_NOTIFICATIONS_ERROR,
            payload,
        });
    }
}

export function* sendBulkNotificationsWatcherSaga(){
    yield takeEvery(
        types.SEND_BULK_NOTIFICATIONS_START,
        sendBulkNotificationsHandlerSaga,
    )
}