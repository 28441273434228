import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const PageNumber = styled.p`
  background-color: ${(props) => (props.active ? "rgb(204,153,0)" : "none")};
  color: ${(props) => (props.active ? "white" : "black")};
  padding: 0.3em 0.5em;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  user-select: none;
  &:hover : {
    transform: scale(1.2);
  }
  &:active : {
    transform: scale(0.9);
  }
`;

const ContentSummary = styled.div`
  width: fit-content;
  padding: 0.3em 0.5em;
  border: 1px solid rgb(0, 0, 0);
  font-size: 0.9em;
  border-radius: 10px;
  color: rgb(0, 0, 0);
  margin-right: 10px;
`;
const PageNumbersContainer = styled.div`
  display: flex;
  width: fit-content;
  max-width: 310px;
  overflow: hidden;
  scroll-behavior: smooth;
`;
const PanButton = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin: 0 0.4em;
  background-color: rgb(0, 0, 0);
  color: #ffff;
  cursor: pointer;
  border: none;
  transition: all 0.3s;
  &:hover {
    transform: translateY(-5px);
  }
  &:active {
    transform: translateY(5px);
  }
`;
const CurrentPage = styled.div`
  font-size: 0.9em;
  background-color: rgb(204, 153, 0);
  color: #ffff;
  padding: 0.3em 0.5em;
  border-radius: 9px;
`;
const Pagination = ({ total, page, showing, onPageChangeHandler, loading }) => {
  const [pagesArray, setPagesArray] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const pageNumbersContainerRef = useRef(null);

  const DEFAULT_PAGE_SIZE = process.env.REACT_APP_DEFAULT_PAGE_SIZE;

  useEffect(() => {
    const number_of_pages = Math.ceil(
      Number.parseInt(total) / Number.parseInt(DEFAULT_PAGE_SIZE)
    );

    let pages_temp_array = [];
    for (let i = 0; i < number_of_pages; i++) {
      pages_temp_array.push(i);
    }
    setPagesArray(pages_temp_array);

    // eslint-disable-next-line
  }, [total]);

  useEffect(() => {
    if (page) {
      setActivePage(Number.parseInt(page));
    }
    // eslint-disable-next-line
  }, [page]);

  function pageNumberClickHandler(e, page) {
    onPageChangeHandler(page, DEFAULT_PAGE_SIZE);
  }
  function panHandler(e) {
    switch (e.target.name) {
      case "right": {
        pageNumbersContainerRef.current.scrollBy(310, 0);
        break;
      }
      case "left": {
        pageNumbersContainerRef.current.scrollBy(-310, 0);
        break;
      }
    }
  }
  if (!total && !page && !showing) return "Invalid Props";
  return (
    <Container style={{ display: "flex", flexDirection:"row", justifyContent:"space-between", }}>
      <div style={{width:"50%",}}>
        <ContentSummary>
          Showing <b>{showing}</b> entries of <b>{total}</b>
        </ContentSummary>
      </div>
      <div style={{width:"50%", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"flex-end",}}> 
        <PanButton name="left" onClick={panHandler}>
          {"<"}
        </PanButton>
        <PageNumbersContainer ref={pageNumbersContainerRef}>
          {pagesArray.map((page) => (
            <PageNumber
              onClick={(e) => pageNumberClickHandler(e, page)}
              key={page}
              active={activePage === Number.parseInt(page) ? true : false}
            >
              {page + 1}
            </PageNumber>
          ))}
        </PageNumbersContainer>
        <PanButton name="right" onClick={panHandler}>
          {">"}
        </PanButton>
        <CurrentPage>
          Page {Number.parseInt(page) + 1} of {pagesArray?.length}
        </CurrentPage>
      </div>
    </Container>
  );
};

export default Pagination;
