const types = {
	GET_BUILDING_LIST_START: "GET_BUILDING_LIST_START",
	GET_BUILDING_LIST_SUCCESS: "GET_BUILDING_LIST_SUCCESS",
	GET_BUILDING_LIST_ERROR: "GET_BUILDING_LIST_ERROR",

	GET_BUILDING_DETAILS_START: "GET_BUILDING_DETAILS_START",
	GET_BUILDING_DETAILS_SUCCESS: "GET_BUILDING_DETAILS_SUCCESS",
	GET_BUILDING_DETAILS_ERROR: "GET_BUILDING_DETAILS_ERROR",

	CREATE_BUILDING_START: "CREATE_BUILDING_START",
	CREATE_BUILDING_SUCCESS: "CREATE_BUILDING_SUCCESS",
	CREATE_BUILDING_ERROR: "CREATE_BUILDING_ERROR",
	CREATE_BUILDING_STATE_RESET: "CREATE_BUILDING_STATE_RESET",

	SET_BUILDING_FOR_UPDATE: "SET_BUILDING_FOR_UPDATE",

	UPDATE_BUILDING_START: "UPDATE_BUILDING_START",
	UPDATE_BUILDING_SUCCESS: "UPDATE_BUILDING_SUCCESS",
	UPDATE_BUILDING_ERROR: "UPDATE_BUILDING_ERROR",
	BUILDING_UPDATE_RESET: "BUILDING_UPDATE_RESET",

	//Delete
	SET_BUILDING_FOR_DELETE: "SET_BUILDING_FOR_DELETE",
	DELETE_BUILDING_START: "DELETE_BUILDING_START",
	DELETE_BUILDING_SUCCESS: "DELETE_BUILDING_SUCCESS",
	DELETE_BUILDING_ERROR: "DELETE_BUILDING_ERROR",
	BUILDING_DELETE_STATE_RESET: "BUILDING_DELETE_STATE_RESET",

	//Bulk Delete
	SET_BUILDING_FOR_BULK_DELETE: "SET_BUILDING_FOR_BULK_DELETE",
	BULK_DELETE_BUILDING_START: "BULK_DELETE_BUILDING_START",
	BULK_DELETE_BUILDING_SUCCESS: "BULK_DELETE_BUILDING_SUCCESS",
	BULK_DELETE_BUILDING_RESET: "BULK_DELETE_BUILDING_RESET",
	BULK_DELETE_BUILDING_ERROR: "BULK_DELETE_BUILDING_ERROR",
	BUILDING_BULK_DELETE_STATE_RESET: "BUILDING_BULK_DELETE_STATE_RESET",

	SEARCH_BUILDING_START: "SEARCH_BUILDING_START",
	SEARCH_BUILDING_SUCCESS: "SEARCH_BUILDING_SUCCESS",
	SEARCH_BUILDING_ERROR: "SEARCH_BUILDING_ERROR",
	BUILDING_SEARCH_RESET: "BUILDING_SEARCH_RESET",
};

export default types;
