import React, { useEffect, useRef, useState } from "react";
import styles from "./css/styles.module.css";

const Dropdown = (props) => {
	const [dropdowm, setDropdown] = useState(false);
	const element = useRef();

	const handleClickOutside = (event) => {
		if (element.current && !element.current.contains(event.target)) {
			setDropdown(false);
		}
	};

	useEffect(() => {
		window.addEventListener("click", handleClickOutside);
		return () => {
			window.removeEventListener("click", handleClickOutside);
		};
	});

	return (
		<div ref={element} className={`${styles.container} ${props.className}`}>
			<a
				href={props.href}
				onClick={() => {
					setDropdown(!dropdowm);
				}}>
				{props.title}
			</a>
			<div
				style={{ display: dropdowm ? "block" : "none" }}
				className={`${styles.dropdown} ${
					props.center ? styles.dropdown_center : ""
				}${props.left ? styles.dropdown_left : ""}`}>
				{props.children}
			</div>
		</div>
	);
};

Dropdown.defaultsProps = {
	center: false,
	left: false,
	className: "",
};

Dropdown.Item = (props) => {
	return (
		<div className={`${styles.dropdown_Item} ${props.className}`}>
			{props.children}
		</div>
	);
};

export default Dropdown;
