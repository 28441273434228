import types from "./types";

const INITIAL_STATE = {
  update: null,
  updateError: null,
  updateLoading: false,

  password: null,
  passwordError: null,
  passwordLoading: false,
};

const offerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.UPDATE_ADMIN_START: {
      return {
        ...state,
        update: null,
        updateError: null,
        updateLoading: true,
      };
    }
    case types.UPDATE_ADMIN_SUCCESS: {
      return {
        ...state,
        update: action.payload,
        updateLoading: false,
      };
    }
    case types.UPDATE_ADMIN_ERROR: {
      return {
        ...state,
        updateError: action.payload,
        updateLoading: false,
      };
    }
    case types.RESET_UPDATE_ADMIN: {
      return {
        ...state,
        updateError: null,
        updateLoading: false,
      };
    }

    //create offer

    case types.PASSWORD_RESET_START: {
      return {
        ...state,

        password: null,
        passwordError: null,
        passwordLoading: true,
      };
    }
    case types.PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        password: action.payload,
        passwordLoading: true,
      };
    }
    case types.PASSWORD_RESET_ERROR: {
      return {
        ...state,
        password: null,
        passwordError: action.payload,
        passwordLoading: true,
      };
    }
    case types.RESET_PASSWORD_RESET: {
      return {
        ...state,
        password: null,
        passwordError: null,
        passwordLoading: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default offerReducer;
