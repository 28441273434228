import { type } from "@testing-library/user-event/dist/type";
import types from "./types";

export const getuserListStart = (payload) => ({
  type: types.GET_USER_LIST_START,
  payload,
});
export const createUserStart = (payload) => ({
  type: types.CREATE_USER_START,
  payload,
});
export const getUserDetailsStart = (payload) => ({
  type: types.GET_USER_DETAILS_START,
  payload,
});
export const resetUserState = () => ({
  type: types.RESET_STATE,
});
export const createUserResetState = () => ({
  type: types.CREATE_USER_STATE_RESET,
});
// USER DELETE
export const deleteUserStart = (payload) => ({
  type: types.USER_DELETE_START,
  payload,
});
export const setUserToDelete = (payload) => ({
  type: types.SET_USER_FOR_DELETE,
  payload,
});
export const userDeleteStateReset = () => ({
  type: types.USER_DELETE_STATE_RESET,
});

//BULK_USER_DELETE
export const setUserToBulkDelete = () =>({
  type: types.SET_USERS_FOR_BULK_DELETE
});
export const startUserBulkDelete = (payload) => ({
  type: types.USER_BULK_DELETE_START,
  payload,
});
export const UserBulkDeleteSucess = () =>({
  type: types.USER_BULK_DELETE_SUCCESS
});
export const usersDeleteError = () => ({
  type: types.USER_BULK_DELETE_ERROR,
});
export const userDeleteStateBulkReset = () => ({
  type: types.USER_DELETE_STATE_BULK_RESET,
});



//search
export const searchUserStart = (payload) => ({
  type: types.SEARCH_USER_START,
  payload,
});

export const searchUserReset = () => ({
  type: types.USER_SEARCH_RESET,
});

// USER UPDATE
export const updateUserStart = (payload) => ({
  type: types.USER_UPDATE_START,
  payload,
});
export const setUserToUpdate = (payload) => ({
  type: types.SET_USER_FOR_UPDATE,
  payload,
});
export const userUpdateStateReset = () => ({
  type: types.USER_UPDATE_STATE_RESET,
});

export const getUnassignedCustomersStart = payload => ({
  type: types.GET_UNASSIGNED_CUSTOMERS_START,
  payload,
});

export const assignManagerStart = (payload) => ({
  type: types.ASSIGN_MANAGER_START,
  payload,
});
export const assignManagerReset = () => ({
  type: types.ASSIGN_MANAGER_RESET,
});
export const assignSubAdminStart = payload => ({
  type: types.ASSIGN_SUB_ADMIN_START,
  payload,
})