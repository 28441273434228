import styled from "styled-components";

export const FormFieldError = styled.h5`
  background-color: #db2b39;
  padding: 1em 1.5em;
  color: #ffff;
  border-radius: 10px;
  margin: 0.3em 0;
  font-size: 0.6em;
  display: flex;
  flex-wrap: wrap;
  div{
    margin: 0 2.5em;
  }
  ul{
    padding:0
  }
  
`;
