import "./App.css";
import { Loading } from "./Components";
import {
  Login,
  MainLayout,
  Dashboard,
  MaintenanceRequests,
  //Offer,
  Users,
  Villa,
  VisitorPermitis,
  BuildingDetails,
  // Building,
  UserProfile,
  ForgotPasssword,
  MaintenanceRequestsDetails,
  UserDetail,
  VillaDetails,
  VisitorPermitiDetails,
  OfferDetail,
  ApartmentAssign,
  VillaAssign,
    Projects,
} from "./pages";
import Offer from "./pages/Offer/Offer";
import Building from "./pages/Building/Building";
import { Routes, Route, useNavigate, Switch } from "react-router-dom";
import { Suspense, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { LineWave } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from "react-hot-toast";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Support from "./pages/Support/Support";

// importing actions
import { generateGuestJWTStart } from "./store/Auth/actions";

// importing selectors
import {
  selectGuestTokenLoading,
  selectGuestTokenError,
  selectStaffuser,
  selectGuestToken,
} from "./store/Auth/selectors";
import Page404 from "./pages/Page404/Page404";

function App({
  // actions
  generateJWT,

  // selectors
  loadingGuestToken,
  guestTokenError,
  staffUser,
  guestToken,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("userToken")) generateJWT();
    if (!staffUser && (window.location.pathname !== '/privacy-policy' && window.location.pathname !== '/support')) navigate("/", { replace: true });
  }, []);
  // useEffect(() => {
  //   if (guestTokenError && guestTokenError.hasOwnProperty("message")) {
  //     const errorMessage =
  //       guestTokenError.message || JSON.stringify(guestTokenError);
  //     toast.error(errorMessage);
  //   }
  // }, [guestTokenError]);

  useEffect(() => {
    if (guestToken) toast.success("Welcome To ADAR Admin");
  }, [guestToken]);
  if (loadingGuestToken) return <Loading />;
  return (
    <>
      <Routes>

        <Route
          index
          element={
            <Suspense fallback={<>loading.....</>}>
              <Login />
            </Suspense>
          }
        ></Route>
        <Route
            path={"privacy-policy"}
            element={
              <PrivacyPolicy />
            }
        ></Route>
        <Route
            path={"support"}
            element={
              <Support />
            }
        ></Route>
      

        <Route element={<MainLayout />}>
          {
              staffUser?.type === 'admin'
              &&
              (
                  <Route
                      exact
                      path={"/projects"}
                      element={
                        <Suspense fallback={<>loading.....</>}>
                          <Projects />
                        </Suspense>
                      }
                  />
              )
          }
          <Route
            path="dashboard"
            element={
              <Suspense fallback={<>loading.....</>}>
                <Dashboard />
              </Suspense>
            }
          />
        <Route
            path="forgot-password"
            element={
              <Suspense fallback={<>loading.....</>}>
                <ForgotPasssword />
              </Suspense>
            }
          />
         
          {(staffUser?.type === "admin" || staffUser?.type === "sub_admin") && (
            <Route
              path="building"
              element={
                <Suspense fallback={<>loading.....</>}>
                  <Building />
                </Suspense>
              }
            />
          )}
          {(staffUser?.type === "admin" || staffUser?.type === "sub_admin") && (
            <Route
              path="building/:id"
              element={
                <Suspense fallback={<Loading />}>
                  <BuildingDetails />
                </Suspense>
              }
            />
          )}
          {(staffUser?.type === "admin" || staffUser?.type === "sub_admin") && (
            <Route
              path="villa"
              element={
                <Suspense fallback={<>loading.....</>}>
                  <Villa />
                </Suspense>
              }
            />
          )}
          {(staffUser?.type === "admin" || staffUser?.type === "sub_admin") && (
            <Route
              path="villa/:id"
              element={
                <Suspense fallback={<>loading.....</>}>
                  <VillaDetails />
                </Suspense>
              }
            />
          )}
          {(staffUser?.type === "admin" || staffUser?.type === "sub_admin") && (
            <Route
              path="users"
              element={
                <Suspense fallback={<>loading.....</>}>
                  <Users />
                </Suspense>
              }
            />
          )}
          {(staffUser?.type === "admin" || staffUser?.type === "sub_admin") && (
            <Route
              path="users/:id"
              element={
                <Suspense fallback={<>loading.....</>}>
                  <UserDetail />
                </Suspense>
              }
            />
          )}
          {(staffUser?.type === "admin" || staffUser?.type === "sub_admin") && (
            <Route
              path="offer"
              element={
                <Suspense fallback={<>loading.....</>}>
                  <Offer />
                </Suspense>
              }
            />
          )}
          {(staffUser?.type === "admin" || staffUser?.type === "sub_admin") && (
            <Route
              path="offer/:id"
              element={
                <Suspense fallback={<>loading.....</>}>
                  <OfferDetail />
                </Suspense>
              }
            />
          )}
          {(staffUser?.type === "admin" || staffUser?.type === "security" || staffUser?.type === "sub_admin") && (
            <Route
              path="visitorpermits"
              element={
                <Suspense fallback={<>loading.....</>}>
                  <VisitorPermitis />
                </Suspense>
              }
            />
          )}
          {(staffUser?.type === "admin" || staffUser?.type === "security" || staffUser?.type === "sub_admin") && (
            <Route
              path="visitorpermit/:id"
              element={
                <Suspense fallback={<>loading.....</>}>
                  <VisitorPermitiDetails />
                </Suspense>
              }
            />
          )}
          {(staffUser?.type === "admin" ||
            staffUser?.type === "maintenance_manager" || staffUser?.type === "sub_admin") && (
            <Route
              path="maintenancerequests"
              element={
                <Suspense fallback={<>loading.....</>}>
                  <MaintenanceRequests />
                </Suspense>
              }
            />
          )}
          {(staffUser?.type === "admin" ||
            staffUser?.type === "maintenance_manager" || staffUser?.type === "sub_admin") && (
            <Route
              path="maintenancedetail/:id"
              element={
                <Suspense fallback={<>loading.....</>}>
                  <MaintenanceRequestsDetails />
                </Suspense>
              }
            />
          )}
          <Route
            path="user-profile"
            element={
              <Suspense fallback={<>loading.....</>}>
                <UserProfile />
              </Suspense>
            }
          />
          {(staffUser?.type === "admin" || staffUser?.type === "sub_admin") && (
            <Route
              path="apartment/:id/assign"
              element={
                <Suspense fallback={<>loading.....</>}>
                  <ApartmentAssign />
                </Suspense>
              }
            />
          )}
          {(staffUser?.type === "admin" || staffUser?.type === "sub_admin") && (
            <Route
              path="villa/:id/assign"
              element={
                <Suspense fallback={<>loading.....</>}>
                  <VillaAssign />
                </Suspense>
              }
            />
          )}

          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
      <ToastContainer
        autoClose={3000}
        closeButton={true}
        closeOnClick={false}
        draggable={false}
      />
    </>
  );
}

const mapState = (state) => ({
  loadingGuestToken: selectGuestTokenLoading(state),
  guestTokenError: selectGuestTokenError(state),
  staffUser: selectStaffuser(state),
  guestToken: selectGuestToken(state),
});
const mapDispatch = (dispatch) => ({
  generateJWT: () => dispatch(generateGuestJWTStart()),
});

export default connect(mapState, mapDispatch)(App);
