import { lazy } from "react";

export { default as MainLayout } from "./MainLayout";
export { default as Login } from "./Login/Login";

export const UserListing = lazy(() => import("./UserListing/UserListing"));
export const UserListingDetails = lazy(() =>
  import("./UserListingDetail/UserListingDetail")
);

export const Rolepermission = lazy(() =>
  import("./Rolepermission/Rolepermission")
);

export const RoutineDetails = lazy(() =>
  import("./RoutineDetails/RoutineDetails")
);

export const Building = lazy(() => import("./Building/Building"));
export const BuildingDetails = lazy(() =>
  import("./BuildingDetails/BuildingDetails")
);

export const Dashboard = lazy(() => import("./Dashboard/Dashboard"));
export const Users = lazy(() => import("./User/User"));
export const Offer = lazy(() => import("./Offer/Offer"));
export const OfferDetail = lazy(() => import("./offerDetail/OfferDetail"));
export const Villa = lazy(() => import("./Villa/Villa"));
export const VillaDetails = lazy(() => import("./VillaDetails/VillaDetails"));
export const VisitorPermitis = lazy(() =>
  import("./VisitorPermit/VisitorPermits")
);
export const VisitorPermitiDetails = lazy(() =>
  import("./VisitorPermitDetails/VisitorPermitDetails")
);
export const MaintenanceRequests = lazy(() =>
  import("./MaintenanceRequest/MaintenanceRequest")
);
export const MaintenanceRequestsDetails = lazy(() =>
  import("./MaintenanceRequestDetails/MaintenanceRequestDetails")
);

export const MaintenanceRequestDetails = lazy(() =>
  import("./MaintenanceRequestDetails/MaintenanceRequestDetails")
);

export const UserProfile = lazy(() => import("./UserProfile/UserProfile"));

export const UserDetail = lazy(() => import("./UserDetail/UserDetail"));
export const ForgotPasssword = lazy(() =>
  import("./ForgotPassword/ForgotPassword")
);

export const Page404 = lazy(() => import("./Page404/Page404"));
export const ApartmentAssign = lazy(() =>
  import("./ApartmentAssign/ApartmentAssign")
);
export const VillaAssign = lazy(() => import("./VillaAssign/VillaAssign"));
export const Projects = lazy(() => import('./Projects/Projects'));
// export const PrivacyPolicy = lazy(() => import("./PrivacyPolicy/PrivacyPolicy"));
