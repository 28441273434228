const endpoints = {
  // LOGIN AND AUTH
  generateJWT: "jwt/generate",
  staffLogin: "user/login/staff",

  housingComplex: "housing/details/1",
  buildingList: (page, size , projectId) => {
    let url = 'building/list?';
    if (page) url += `page=${page}&`;
    if (size) url += `size=${size}&`;
    if (projectId) url += `projectId=${projectId}&`
    return url;
  },
  villaList: (page, size , projectId) => {
    let url = "villa/list?";
    if (page) url += `page=${page}&`;
    if (size) url += `size=${size}&`;
    if(projectId) url += `projectId=${projectId}`;
    return url;
  },
  singleVilla: (id) => `villa/detail?id=${id}`,
  //offer
  offerList: (page, size , projectId) => {
    let url = 'offer/list?';
    if(page) url += `page=${page}&`;
    if(size) url += `size=${size}&`;
    if(projectId) url += `projectId=${projectId}&`;
    return url;
  },
  createoffer: "offer/create",
  updateOffer: "offer",
  deleteOffer: (id) => `offer/?id=${id}`,
  BulkDeleteOffer:"offer/bulk-delete",
  offerSearch: ({ page, size, search , projectId }) =>{
    let url = 'offer/list?';
    if(page) url += `page=${page}&`;
    if(size) url += `size=${size}&`;
    if(search) url += `search=${search}&`;
    if(projectId) url += `projectId=${projectId}&`;
    return url;
  },
  //coupon
  createCoupon: "coupon/create",
  getCoupon: ({ page, size, offerid }) =>
    `coupon/list?page=${page}&size=${size}&offer=${offerid}`,
  updateCoupon: "coupon",
  deleteCoupon: (id) => `coupon?id=${id}`,

  //visitor

  createVisitorPermit: "visitor/create",
  updateVisitor: "visitor/update",
  updateStatus: "visitor/update/status",
  deleteVisitorPermit: (id) => `visitor/delete/request?id=${id}`,
  visitorPermitList: (page, size, search, status, userId , projectId) => {
    let url = "visitor/list?";
    if (page) url += `page=${page}&`;
    if (size) url += `size=${size}&`;
    if (search) url += `search=${search}&`;
    if (status) url += `status=${status}&`;
    if (userId) url += `userId=${userId}&`;
    if (projectId) url += `projectId=${projectId}&`;
    return url;
  },
  selectVisitorPermit: (id) => `visitor/detail?id=${id}`,
  visitorPermitSearch: ({ page, size, search, projectId }) =>{
    let url = "visitor/list?";
    if (page) url += `page=${page}&`;
    if (size) url += `size=${size}&`;
    if (search) url += `search=${search}&`;
    if (projectId) url += `projectId=${projectId}&`;
    return url;
  },
  // maintenance
  maintenanceRequestList: (page='1', size=10, status , userId, projectId, search, building, villa, category, startDate, endDate, reqId, apartment) => {
    let url = "maintenance/list?";
    if (page) url += `page=${page}&`;
    if (size) url += `size=${size}&`;
    if (status) url += `status=${status}&`;
    if (userId) url+= `userId=${userId}&`;
    if (projectId) url += `projectId=${projectId}&`;
    if (search) url += `search=${search}&`;
    if (building) url += `building=${building}&`;
    if (villa) url += `villa=${villa}&`;
    if (category) url += `category=${category}&`;
    if (startDate) url += `startDate=${startDate}&`;
    if (endDate) url += `endDate=${endDate}&`;
    if(reqId) url += `reqId=${reqId}&`;
    if(apartment) url += `apartment=${apartment}&`;

    return url;
  },
  maintenanceRequestDetails: (id) => `maintenance/details?id=${id}`,
  maintenanceCreate: "maintenance/create",
  maintenanceUpdate: "maintenance/update",
  deleteMaintenanceRequest: (id) => `maintenance/delete/request?id=${id}`,
  maintenanceRequestSearch: ({ page, size, search, projectId }) =>{
    let url = "maintenance/list?";
    if (page) url += `page=${page}&`;
    if (size) url += `size=${size}&`;
    if(search) url += `search=${search}&`
    if (projectId) url += `projectId=${projectId}&`;
    return url;
  },
  // building
  createBuilding: "building/create",
  building: "building",
  buildingDelete: (id) => `building?id=${id}`,
  buildingDetails: (id) => `building/detail?id=${id}`,
  bulkBuildingDelete: "building/bulk-delete",
  searchBuilding: ({ page, size, search, projectId }) =>{
    let url = 'building/list?';
    if(page) url += `page=${page}&`;
    if(size) url += `size=${size}&`;
    if(search) url += `search=${search}&`;
    if(projectId) url += `projectId=${projectId}`;
    return url;
  },
  // villa
  createVilla: "villa/create",
  updateVilla: "villa",
  deleteVilla: (id) => `villa/?id=${id}`,
  villaBulkDelete: "villa/bulk-delete",
  villaSearch: ({ page, size, search, projectId }) =>{
    let url = 'villa/list?';
    if(page) url += `page=${page}&`;
    if(size) url += `size=${size}&`;
    if(search) url += `search=${search}&`;
    if(projectId) url += `projectId=${projectId}`;
    return url;
  },
  villaResidentAssign: "villa/resident/assign",
  villaResidentUnAssign: (id) => `villa/resident/${id}/unassign`,

  // apartment
  allApartmentList: "apartment/list",
  apartmentList: (page, size, building) =>
    `apartment/list?building=${building}&page=${page}&size=${size}`,
  apartmentCreate: "apartment/create",
  apartment: "apartment",
  apartmentDelete: (id) => `apartment?id=${id}`,
  BulkAparmentDelete:"apartment/bulk-delete",
  //bulkapartmentDelete:"apartment/bulk-delete",
  apartmentAssign: "apartment/resident/assign",
  apartmentUnAssign: (id) => `apartment/resident/${id}/unassign`,

  // user
  unassignedCustomers: ({projectId}) => `user/unassigned/customers?projectId=${projectId}`,
  userList: ({ page, size, projectId }) => {
    let url = `user/list?`;
    if(page) url += `page=${page}&`;
    if(size) url += `size=${size}&`;
    if(projectId) url += `projectId=${projectId}`;
    return url;
  },
  createUser: "user/register",
  userDetails: (id) => `user/details?id=${id}`,
  userDelete: (id) => `user?userId=${id}`,
  bulkUserDelete:"user/bulk-delete",
  userUpdate: "user/updateByAdmin",
  searchUser: ({ page, size, search, projectId }) =>{
    let url = `user/list?`;
    if(page) url += `page=${page}&`;
    if(size) url += `size=${size}&`;
    if(search) url += `search=${search}&`
    if(projectId) url += `projectId=${projectId}`;
    return url;
  },
  assignManager: "maintenanceAssign/category/assign",

  //FamilyMember
  familyMember: ({ customer_Id, page, size }) =>
    `familyMember/list?customerId=${customer_Id}&page=${page}&size=${size}`,
  createFamilyMember: "familyMember/create/member",
  deleteFamilyMember: (id) => `familyMember?id=${id}`,

  //comment

  commentlist: ({ page, size, maintenanceRequest }) =>
    `comment/list?page=${page}&size=${size}&MaintenanceRequest=${maintenanceRequest}`,

  createComment: "comment/create",

  updateAdminProfile: "user/update",
  changeAdminPassword: "user/reset",

  // image upload
  singleImageUpload: "image/upload",

  //category
  categoryList: "/category/list",

  /**
   * API ENDPOINTS RELATED TO PROJECTS START
   * */
  projectList : (page,size) => {
    let url = "project/list?";
    if(page) url += `page=${page}&`;
    if(size) url += `size=${size}&`;
    return url;
  },
  projectCreate: "project/create",
  projectUpdate: "project",
  /**
   * API ENDPOINTS RELATED TO PROJECTS END
   * */

  assignSubAdmin : "user/subAdmin/assign",

  deleteProject: (id) => `/project/delete/${id}`,
  sendNotification: "/notification/send/bulk",

  findStaff:"user/staff/findbymobile",

  otpGenerate:"otp/generate",

};

export default endpoints;
